import { Button, Col, Form, Input, Modal, Row, Space } from "antd"
import React, { useEffect } from "react";

const AddEditDriverModal = ({ visible, onCancel, onSave, title, data, type }) => {
    const [form] = Form.useForm();
    const submit = (data) => {
        if (onSave) onSave(data);
    };

    useEffect(() => {
        if (type === 'Edit') {
            form.resetFields();
            form.setFieldsValue({
                fullname: data?.fullname ?? '',
                cmnd: data?.cmnd ?? '',
                ngaycap: data?.ngaycap ?? '',
                noicap: data?.noicap ?? '',
                phone: data?.phone ?? '',
                bsx: data?.bsx ?? '',
                diachi: data?.diachi ?? '',
            });

        } else {
            form.resetFields();
        }
    }, [data])

    return (
        <Modal
            title={title}
            open={visible}
            onCancel={onCancel}
            width={'60%'}
            footer={null}
        >
            <Form
                form={form}
                name='wood-info'
                onFinish={submit}
                layout={'vertical'}
            >
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item label="Tên lái xe" name="fullname" rules={[{ required: true, message: 'Vui lòng nhập tên lái xe' }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item label="CMND" name="cmnd" rules={[{ required: true, message: 'Vui lòng nhập cmnd' }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item label="Ngày cấp" name="ngaycap" rules={[{ required: true, message: 'Vui lòng nhập ngày cấp' }]}>
                            <Input placeholder="DD/MM/YYYY" />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Nơi cấp" name="noicap" rules={[{ required: true, message: 'Vui lòng nhập nơi cấp' }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={5}>
                        <Form.Item label="Số điện thoại" name="phone">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item label="Biển số xe" name="bsx" rules={[{ required: true, message: 'Vui lòng nhập bsx' }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={14}>
                        <Form.Item label="Địa chỉ" name="diachi">
                            <Input />
                        </Form.Item>
                    </Col>

                </Row>

                <Row>
                    <Col span={24} style={{ display: 'flex', justifyContent: 'right' }}>
                        <Space >
                            <Button onClick={onCancel}>
                                Hủy
                            </Button>
                            <Button type="primary" htmlType="submit">
                                Lưu thông tin
                            </Button>
                        </Space>
                    </Col>

                </Row>

            </Form>
        </Modal>
    )
}
export default AddEditDriverModal;