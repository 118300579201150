import React from "react";

const LimitContentCell = ({text, record, index}) => {

    return (
        <div style={{ maxWidth: 200, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {text}
        </div>
    )
}

export default React.memo(LimitContentCell);