import { Button, Col, Form, Input, InputNumber, Modal, Row, Space } from "antd"
import React, { useEffect } from "react";

const AddEditWoodsModal = ({ visible, onCancel, onSave, title, data, type }) => {
    console.log("data", data);
    const [form] = Form.useForm();
    const submit = (data) => {
        if (onSave) onSave(data);
    };
    
    useEffect(() => {
        if (type === 'Edit' && data) {
            form.resetFields();
            form.setFieldsValue({
                name: data.name ?? '',
                price: data.price ?? '',
            });

        }
    }, [data])

    return (
        <Modal
            title={title}
            open={visible}
            onCancel={onCancel}
            width={'60%'}
            footer={null}
        >
            <Form
                form={form}
                name='wood-info'
                onFinish={submit}
                layout={'vertical'}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item label="Tên loại hàng" name="name" rules={[{required: true, message: 'Vui lòng nhập tên loại hàng'}]}>
                            <Input  />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item label="Giá" name="price" rules={[{required: true, message: 'Vui lòng nhập giá'}]}>
                        <InputNumber
                                placeholder={'0'}
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                className="input-number-base"
                                min={1000}
                            />
                        </Form.Item>
                    </Col>
                    
                </Row>
                
                <Row>
                    <Col span={24} style={{ display: 'flex', justifyContent: 'right' }}>
                        <Space >
                            <Button onClick={onCancel}>
                                Hủy
                            </Button>
                            <Button type="primary" htmlType="submit">
                                Lưu thông tin
                            </Button>
                        </Space>
                    </Col>

                </Row>

            </Form>
        </Modal>
    )
}
export default AddEditWoodsModal;