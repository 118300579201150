import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import LoginPage from './screens/login/LoginPage';
import DashboardPage from './screens/dasboard/DashboardPage';
import PrivateRoute from './components/PrivateRoute';
import AddBookPage from './screens/add-book/AddBookPage';
import ListBookPage from './screens/list-book/ListBookPage';
import ImportExcelPage from './screens/import/ImportExcelPage';
import ReportBookPage from './screens/report/ReportBookPage';
import NotFound from './components/NotFound';
import DetailBookPage from './screens/detail/DetailBookPage';
import ApproveBookPage from './screens/approve/ApproveBookPage';
import ApproveBookDetail from './screens/approve/detail/ApproveBookDetail';
import ReportWarningBookPage from './screens/report/ReportWarningBookPage';
import ReportWeighingSlipPage from './screens/report/ReportWeighingSlipPage';
import ImportWeighingSlip from './screens/import/ImportWeighingSlip';
import DepartmentCategoryPage from './screens/category/DepartmentCategoryPage';
import BookTypeCategoryPage from './screens/category/BookTypeCategoryPage';
import PermissionPage from './screens/permission/PermissionPage';
import WeighingSlipPage from './screens/weighing/WeighingSlipPage';
import WoodsTypeCategoryPage from './screens/category/WoodsTypeCategoryPage';
import DriversInfoPage from './screens/category/DriversInfoPage';
import PrintBillPage from './screens/prints/PrintBillPage';
import PrintWeighingSlip from './screens/prints/PrintWeighingSlip';
import ReportBCDatCocPage from './screens/report/ReportBCDatCocPage';
import ReportBCPhieuChiPage from './screens/report/ReportBCPhieuChiPage';
import ReturnMoneyPage from './screens/return-money/ReturnMoneyPage';
import ChangePassPage from './screens/permission/ChangePassPage';
import ReportWeighingBookPage from './screens/report/ReportWeighingBookPage';
import BackUpData from './screens/backup/BackUpData';
import AddMutilWeighingSlipToBook from './screens/import/AddMutilWeighingSlipToBook';

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='*' element={<NotFound />} />
        <Route path="/dang-nhap" element={
          <LoginPage />
        } />
        <Route path="/" element={
          <PrivateRoute>
            <DashboardPage />
          </PrivateRoute>
        } />
        <Route path="/dashboard" element={
          <PrivateRoute>
            <DashboardPage />
          </PrivateRoute>
        } />
        <Route path="/them-moi" element={
          <PrivateRoute>
            <AddBookPage />
          </PrivateRoute>
        } />
        <Route path="/danh-sach" element={
          <PrivateRoute>
            <ListBookPage />
          </PrivateRoute>
        } />
        <Route path="/import-excel" element={
          <PrivateRoute>
            <ImportExcelPage />
          </PrivateRoute>
        } />
        <Route path="/import-phieu-can" element={
          <PrivateRoute>
            <ImportWeighingSlip />
          </PrivateRoute>
        } />
        <Route path="/bao-cao-so-do" element={
          <PrivateRoute>
            <ReportBookPage />
          </PrivateRoute>
        } />
        <Route path="/canh-bao-so-do" element={
          <PrivateRoute>
            <ReportWarningBookPage />
          </PrivateRoute>
        } />
        <Route path="/bao-cao-phieu-can" element={
          <PrivateRoute>
            <ReportWeighingSlipPage />
          </PrivateRoute>
        } />
        <Route path="/detail/:id" element={
          <PrivateRoute>
            <DetailBookPage />
          </PrivateRoute>
        } />
        <Route path="/duyet-so" element={
          <PrivateRoute>
            <ApproveBookPage />
          </PrivateRoute>
        } />
        <Route path="/chi-tiet-duyet-so/:id" element={
          <PrivateRoute>
            <ApproveBookDetail />
          </PrivateRoute>
        } />
        <Route path="/phong-ban" element={
          <PrivateRoute>
            <DepartmentCategoryPage />
          </PrivateRoute>
        } />
        <Route path="/loai-so" element={
          <PrivateRoute>
            <BookTypeCategoryPage />
          </PrivateRoute>
        } />
        <Route path="/phan-quyen" element={
          <PrivateRoute>
            <PermissionPage />
          </PrivateRoute>
        } />
        <Route path="/phieu-can" element={
          <PrivateRoute>
            <WeighingSlipPage />
          </PrivateRoute>
        } />
        <Route path="/loai-hang" element={
          <PrivateRoute>
            <WoodsTypeCategoryPage />
          </PrivateRoute>
        } />
        <Route path="/lai-xe" element={
          <PrivateRoute>
            <DriversInfoPage />
          </PrivateRoute>
        } />

        <Route path="/print-phieu-chi" element={
          <PrivateRoute>
            <PrintBillPage />
          </PrivateRoute>
        } />
        <Route path="/print-phieu-can" element={
          <PrivateRoute>
            <PrintWeighingSlip />
          </PrivateRoute>
        } />
        <Route path="/bao-cao-dat-coc" element={
          <PrivateRoute>
            <ReportBCDatCocPage />
          </PrivateRoute>
        } />
        <Route path="/bao-cao-phieu-chi" element={
          <PrivateRoute>
            <ReportBCPhieuChiPage />
          </PrivateRoute>
        } />
        <Route path="/tra-coc" element={
          <PrivateRoute>
            <ReturnMoneyPage />
          </PrivateRoute>
        } />
        <Route path="/doi-mat-khau" element={
          <PrivateRoute>
            <ChangePassPage />
          </PrivateRoute>
        } />
        <Route path="/bao-cao-weighing-book" element={
          <PrivateRoute>
            <ReportWeighingBookPage />
          </PrivateRoute>
        } />
        <Route path="/sao-luu-du-lieu" element={
          <PrivateRoute>
            <BackUpData />
          </PrivateRoute>
        } />
        <Route path="/link-nhieu-phieu" element={
          <PrivateRoute>
            <AddMutilWeighingSlipToBook />
          </PrivateRoute>
        } />
      </Routes>

    </BrowserRouter>
  );
};

export default AppRouter;