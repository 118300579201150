import React, { forwardRef } from "react";
import '../../css/print-bill-page.css';
import { Col, Row } from "antd";
import { Images } from "../../assets";
import { useSelector } from "react-redux";
import { capitalizeFirstLetter, formatNumber } from "../../utils/funcHelper";
import { default as VNnum2words } from 'vn-num2words';
import moment from "moment";

const RenderDataToPrintBill = ({ props }, ref) => {
    const printReducer = useSelector(state => state?.printReducer);
    const data = printReducer?.data;
    const caculatorMoney = () => {
        return (data?.khoiluong * data?.dongia)/1000 - ((data?.khoiluong * data?.dongia)/1000)*data?.giamgia/100;
    }
    return (
        <div ref={ref} style={{ background: '#FFF' }}>
            <Row>
                <Col span={4} style={{ justifyContent: 'center', display: 'flex' }}>
                    <img src={Images.img_logo_app} className="img-logo-bill" />
                </Col>
                <Col span={20}>
                    <p className="title-header-bill color-print">BIOMASS FUEL VIETNAM CO., LTD</p>
                    <p className="txt-address color-print">Address: No. 05, Road no.2, VSIP Nghe An Industrial Park, Hung Nguyen District, Nghe An</p>
                    <Row style={{marginTop: 5}}>
                        <Col span={3}>
                        </Col>
                        <Col span={11}>
                            <span className="txt-title color-print">PHIẾU CHI/ PAYMENT VOUCHER</span>
                        </Col>
                        <Col span={1}>
                        </Col>
                        <Col span={6}>
                            <span className="txt-address color-print">Quyển số/Book No: 4</span>
                        </Col>
                        <Col span={1}>
                        </Col>
                    </Row>
                </Col>

            </Row>

            <Row className="row-body">
                <Col span={8}>
                    <span className="txt-body color-print">Họ và tên người nhận tiền/ Receiver’s name:</span>
                </Col>
                <Col span={16} className="border-bottom">
                    <span className="txt-body color-print"><strong className="txt-body color-print">{data?.hoten ? data?.hoten?.toUpperCase() : ''}</strong></span>
                </Col>
            </Row>
            <Row className="row-body">
                <Col span={3}>
                    <span className="txt-body color-print">CMND/ID No:</span>
                </Col>
                <Col span={3} className="border-bottom">
                    <span className="txt-body color-print">{data?.cmnd ?? ''}</span>
                </Col>
                <Col span={3} className="center-col">
                    <span className="txt-body color-print">ngày/ dated</span>
                </Col>
                <Col span={5} className="border-bottom">
                    <span className="txt-body color-print">{`${data?.ngaycap ?? ''} ${data?.noicap ?? ''}`}</span>
                </Col>
                <Col span={4} className="center-col">
                    <span className="txt-body color-print">Huyện/ District</span>
                </Col>
                <Col span={6} className="border-bottom">
                    <span className="txt-body color-print">{data?.diachi ?? ''}</span>
                </Col>
            </Row>
            <Row className="row-body">
                <Col span={16}>
                    <span className="txt-body color-print">Loại hàng/Kind of material :&nbsp;&nbsp;<strong className="txt-body color-print">{data?.loaihang ?? ''}</strong></span>
                </Col>
                <Col span={8}>
                    <span className="txt-body color-print">Biển số xe/Truck no.:&nbsp;&nbsp;<strong className="txt-body color-print">{data?.biensoxe ?? ''}</strong></span>
                </Col>
            </Row>
            
            <Row className="row-body">
                <Col span={24}>
                    <table id="table-123" className="table-bill">
                        <tr>
                            <th className="title-table color-print">Số phiếu/ No. Weigh ticket</th>
                            <th className="title-table color-print">Khối lượng/ Quantity (Kg)</th>
                            <th className="title-table color-print">Đơn giá/ Price (VND/kg)</th>
                            <th className="title-table color-print">Giảm giá (%)</th>
                            <th className="title-table color-print">Thành tiền/ Amount (VND)</th>
                            <th className="title-table color-print">Đặt cọc/ Deposit (VND)</th>
                            <th className="title-table color-print">Trả cọc/ Pay deposit (VND)</th>
                            <th className="title-table-support color-print">Hỗ trợ</th>
                            <th className="title-table color-print">Thực nhận/ Paid amount (VND)</th>
                        </tr>
                        <tr>
                            <td><span className="content-td color-print">{data?.sophieucan ?? ''}</span></td>
                            <td><span className="content-td color-print">{data?.khoiluong > 0 ? formatNumber(parseInt(data?.khoiluong), 0) : "-"}</span></td>
                            <td><span className="content-td color-print">{data?.dongia > 0 ? formatNumber(data?.dongia, 0) : "-"}</span></td>
                            <td><span className="content-td color-print">{data?.giamgia ?? 0}%</span></td>
                            <td><span className="content-td color-print">{caculatorMoney() ? formatNumber(caculatorMoney(), 0) : 0}</span></td>
                            <td><span className="content-td color-print">{data?.tiencoc ? formatNumber(parseInt(data?.tiencoc), 0) : '-'}</span></td>
                            <td><span className="content-td color-print">{data?.tientracoc ? formatNumber(parseInt(data?.tientracoc), 0) : '-'}</span></td>
                            <td><span className="content-td color-print">{data?.tienhotro ? formatNumber(parseInt(data?.tienhotro), 0) : '-'}</span></td>
                            <td><span className="content-td color-print">{data?.thucnhan ? formatNumber(parseInt(data?.thucnhan), 0) : '-'}</span></td>
                        </tr>
                    </table>
                </Col>
            </Row>
            <Row className="row-body">
                <Col span={8}>
                    <span className="txt-body color-print">Số tiền bằng chữ/Amount in words:</span>
                </Col>
                <Col span={16}>
                    <span className="txt-body color-print">{data?.thucnhan ? capitalizeFirstLetter(VNnum2words(data?.thucnhan)) : '-'} đồng.</span>
                </Col>
            </Row>
            <Row className="row-body">
                <Col span={3}>
                    <span className="txt-body color-print">Ghi chú/ Note:</span>
                </Col>
                <Col span={21} className="border-bottom">
                    <span className="txt-body color-print">{data?.ghichu ?? ''}</span>
                </Col>
            </Row>
            <Row className="row-body">
                <Col span={12}>
                </Col>
                <Col span={12} className="center-col">
                    <span className="txt-body color-print">Ngày / Dated {moment().format("DD/MM/YYYY")}</span>
                </Col>
            </Row>
            <Row className="row-body">
                <Col span={12} className="center-col">
                    <span className="txt-body color-print">Nhân viên cầu cân/WB staff<br/>(Ký, họ tên/Sign with full name)</span>
                </Col>
                <Col span={12} className="center-col">
                    <span className="txt-body color-print">Người nhận tiền/ Receiver<br/>(Ký, họ tên/Sign with full name)</span>
                </Col>
            </Row>
        </div>
    )
}

export default forwardRef(RenderDataToPrintBill);