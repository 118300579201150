import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row, Space, theme, Table, message } from 'antd';
import LoadingBase from '../../components/LoadingBase';
import { changeUserPassword, getAllUsers, getListRoles, getRolesByUserId, updateUserPermissionById } from '../../services/apis';
import '../../css/listbook-page.css';
import PermissionModal from '../../components/PermissionModal';

const PermissionPage = () => {
    const { token } = theme.useToken();
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [itemSelected, setItemSelected] = useState();
    const [lstRoles, setLstRoles] = useState([]);
    const [userRoles, setUserRoles] = useState([]);
    const columns = [
        {
            title: 'STT',
            dataIndex: 'index',
            width: 45,
            key: 'index',
            align: 'center',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Tên đăng nhập',
            dataIndex: 'username',
            key: 'username',
            width: 200,
            align: 'center',
        },
        {
            title: 'Họ và tên',
            dataIndex: 'fullname'
        },
        {
            title: 'Số điện thoại',
            dataIndex: 'phone'
        },
        {
            title: 'Hành động',
            width: 100,
            render: (text, record, index) => (<Button type='link' onClick={() => handleOnClickItem(record)}>Xem</Button>)
        }
        
    ];
    const formStyle = {
        maxWidth: 'none',
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
    };

    const handleOnClickItem = (item) => {
        setItemSelected({...item});
        getUserRoles(item?.id);
    }

    const getDataRoles = async () => {
        const res = await getListRoles();
        if (res && res.status === "success") {
            setLstRoles(res?.data ? [...res.data?.map(role => ({
                ...role,
                value: role.role_id,
                label: role.role_name,
                disabled: role.role_id === "1" ? true : false
            }))] : []);
        } else {
            setLstRoles([]);
        }
    }

    const getUserRoles = async (id) => {
        try {
            const params = {
                userId: id
            };
            setLoading(true);
            const res = await getRolesByUserId(params);
            setLoading(false);
            if (res && res?.status === "success") {
                setUserRoles(res?.data ? [...res.data.map(item => item.role_id)] : []);
            } else {
                setUserRoles([]);
            }
            setShowModal(true);
        } catch (error) {
            setLoading(false);
            message.error("Có lỗi xảy ra. Vui lòng thử lại", 3);
        }
        
    }

    const handleUpdatePassword = async (id, password) => {
        const params = {
            user_id: parseInt(id),
            password: password
        }
        setLoading(true);
        const res = await changeUserPassword(params);
        setLoading(false);
        if (res && res.status === "success") {
            message.success("Cập nhật mật khẩu thành công");
        } else {
            message.error(res?.msg ?? "Cập nhật mật khẩu thất bại");
        }
    }

    const handleUpdateRoles = async (id, roles) => {
        const params = {
            user_id: parseInt(id),
            roles: roles
        };
        setLoading(true);
        const res = await updateUserPermissionById(params);
        setLoading(false);
        if (res && res.status === "success") {
            message.success("Cập nhật quyền thành công");
        } else {
            message.error(res?.msg ?? "Cập nhật quyền thất bại");
        }
    }

    const handleSaveUserInfo = async (data) => {
        console.log("data modal", data);
        if (!data) {
            setShowModal(false);
            return;
        }
        setShowModal(false);
        if (data?.password) {
            handleUpdatePassword(data?.userId, data.password);
        }
        if (data?.roles) {
            handleUpdateRoles(data?.userId, data?.roles.map(role => parseInt(role)))
        }
    }
    const handleCancel = () => {
        setShowModal(false);
    }
    const getDataUsers = async (keyword) => {
        const params = {
            fullname: keyword
        }
        setLoading(true);
        const res = await getAllUsers(params);
        setLoading(false);
        if (res && res?.status === 'success') {
            setData(res?.data ? [...res.data] : []);
        } else {
            setData([]);
        }
    }
    
    const onFinish = async (data) => {
        getDataUsers(data.name);
    };
    useEffect(() => {
        getDataRoles();
        getDataUsers();
    }, [])
    return (
        <div className='container-content'>
            <Form form={form} name="advanced_search" style={formStyle} onFinish={onFinish}>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            name={'name'}
                        >
                            <Input placeholder={'Tên loại sổ'} />
                        </Form.Item>
                    </Col>
                    
                    
                    <Col span={4}>
                        <Space size="small">
                            <Button type="primary" htmlType="submit">
                                Tìm kiếm
                            </Button>
                            {/* <Button
                                onClick={() => {
                                    setShowModal(true);
                                }}
                            >
                                Thêm mới
                            </Button> */}
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Table
                className='table-book'
                columns={columns}
                dataSource={data}
                pagination={{
                    pageSize: 20
                }}
            />
            <PermissionModal 
                visible={showModal} 
                onCancel={handleCancel} 
                onSave={handleSaveUserInfo} 
                data={itemSelected} 
                title={"Thông tin tài khoản"}
                dataRole={lstRoles}
                roleDefault={userRoles}
                />
            <LoadingBase loading={loading} />
        </div>

    );
};
export default PermissionPage;