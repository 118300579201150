import { useEffect, useState } from "react";

import {
    Card,
    Col,
    Row,
    Typography,
    Tooltip,
    Button,
    message,
} from "antd";
import {
    MenuUnfoldOutlined,
    MoreOutlined,
    RiseOutlined,
    FallOutlined,
} from "@ant-design/icons";
import Paragraph from "antd/lib/typography/Paragraph";

import Echart from "../../components/chart/EChart";
import { getBooksWarning, getBooksWeighingRecent, getDataForChart, getListWeighingSlip, getListWoodType, getReportBookVolumnUsed, getReportForDashboard, searchBookInfo, updateWeighingSlipById } from "../../services/apis";
import LoadingBase from "../../components/LoadingBase";
import { formatNumber } from "../../utils/funcHelper";
import { Images } from "../../assets";
import moment from "moment";
import { DATE_FORMAT } from "../../constants/constants";
import { useNavigate, useNavigation } from "react-router-dom";
import WeighingModal from "../../components/WeighingModal";
import { debounce } from "lodash";
import { useSelector } from "react-redux";
import CustomProgress from "../../components/CustomProgress";

const DATA_DEFAULT = [
    {
        today: "TỔNG KHỐI LƯỢNG",
        title: 0,
        persent: 0,
        icon: Images.img_truck,
        bnb: "bnb2",
        classBox: 'icon-box',
        toolTip: 'Tổng khối lượng cân trong ngày'
    },
    {
        today: "KHỐI LƯỢNG HÀNG",
        title: 0,
        persent: 0,
        icon: '',
        bnb: "bnb2",
        classBox: 'icon-box',
        toolTip: 'Khối lượng hàng cân trong ngày'
    },
    {
        today: "TỔNG SỐ PHIẾU",
        title: 0,
        persent: 0,
        icon: Images.img_bill,
        bnb: "bnb2",
        classBox: 'icon-box',
        toolTip: 'Tổng số phiếu trong ngày'
    },
    {
        today: "TỔNG SỔ ĐỎ",
        title: 0,
        persent: 0,
        icon: '',
        bnb: "redtext",
        classBox: 'icon-box',
        toolTip: 'Tổng sổ đỏ trong ngày'
    },

];
const DashboardPage = () => {
    const userReducer = useSelector(state => state?.userReducer);
    const { Title, Text } = Typography;
    const [reverse, setReverse] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dataTop, setDataTop] = useState(DATA_DEFAULT);
    const [dataChart, setDataChart] = useState([]);
    const [dataWeighing, setDataWeighing] = useState([]);
    const [booksWarning, setBooksWarning] = useState([]);
    const [totalBill, setTotalBill] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [itemSelected, setItemSelected] = useState();
    const [dataBook, setDataBook] = useState([]);
    const [listPrice, setListPrice] = useState([]);
    const [dataBookRecent, setDataBookRecent] = useState([]);

    const navigate = useNavigate();
    const getDataWeighingSlip = async () => {
        const params = {
            limit: 6,
            keyword: '',
            currentPage: 1
        };
        const res = await getListWeighingSlip(params);
        if (res && res?.data) {
            setDataWeighing([...res.data]);
        } else {
            setDataWeighing([]);
        }
    }

    const handleCallMultiApi = (apis) => {
        setLoading(true);
        Promise.all(apis)
            .then(() => { })
            .catch((err) => {
                setLoading(false);
            })
            .finally(() => setLoading(false))
    }
    const caculatorPersent = (today, yesterday) => {
        let persent;
        if (today > 0 && yesterday > 0) {
            persent = (today - yesterday) * 100 / yesterday;
        } else if (today > 0 && yesterday === 0) {
            persent = 100;
        } else {
            persent = 0;
        }
        return persent;
    }
    const getDataBooks = async (keyword) => {
        let params = {
            limit: 20,
            currentPage: 1,
            keyword: keyword ?? ''
        };
        const res = await searchBookInfo(params);
        if (res && res?.data) {
            setDataBook([...res.data?.map(item => ({
                ...item,
                value: item.book_code,
                label: renderLabel(item),
                child: `${item.book_code} - ${item.book_owner_name}`
            }))]);
        } else {
            setDataBook([]);
        }
    }
    const handleCancel = () => {
        setDataBook([]);
        setShowModal(false);
    }
    const getListPrice = async () => {
        const res = await getListWoodType();
        if (res && res.status === 'success') {
            setListPrice([...res?.data]);
        }
    }
    const debouncedSearch = debounce((keyword) => {
        getDataBooks(keyword);
    }, 500);

    const onSearchInfoBook = async (value) => {
        debouncedSearch(value);
    }
    const onSelectBook = (book) => {
    }
    const renderLabel = (item) => {
        return (
            <Row>
                <Col span={8}>
                    <p>{item.book_code}</p>
                </Col>
                <Col span={10}>
                    <p>{item.book_owner_name}</p>
                </Col>
                <Col span={6}>
                    <p>{item.cmnd_book_owner}</p>
                </Col>
            </Row>
        )
    }
    const handleOnClickItem = (item) => {
        const obj = listPrice.find(price => price.name === item.Goods);
        const temp = {
            ...item,
            Price: obj?.price ?? 0
        };
        setItemSelected(temp);
        setShowModal(true);
    }
    const getDataForTop = async () => {
        const res = await getReportForDashboard();
        if (res && res.status === true) {
            const newData = { ...res?.data };
            setTotalBill(newData?.total_bill ?? 0);
            const tempData = [
                {
                    title: "TỔNG KHỐI LƯỢNG",
                    today: newData?.total ? formatNumber(newData?.total / 1000, 2) : 0,
                    persent: caculatorPersent(newData?.total, newData?.total_yesterday),
                    icon: Images.img_truck,
                    bnb: newData?.total > newData?.total_yesterday ? "bnb2" : "redtext",
                    classBox: 'icon-box',
                    toolTip: 'Tổng khối lượng cân trong ngày'
                },
                {
                    title: "KHỐI LƯỢNG HÀNG",
                    today: newData?.total_net ? formatNumber(newData?.total_net / 1000, 2) : 0,
                    persent: caculatorPersent(newData?.total_net, newData?.total_net_yesterday),
                    icon: Images.img_wood,
                    bnb: newData?.total_net > newData?.total_net_yesterday ? "bnb2" : "redtext",
                    classBox: 'icon-box-wood',
                    toolTip: 'Khối lượng hàng cân trong ngày'
                },
                {
                    title: "SỐ LẦN CÂN(TỔNG/BÌ)",
                    today: `${newData?.total_bill ? formatNumber(newData?.total_bill, 0) : 0}/${newData?.total_tare ? formatNumber(newData?.total_tare, 0) : 0}`,
                    persent: caculatorPersent(newData?.total_bill, newData?.total_bill_yesterday),
                    icon: Images.img_bill,
                    bnb: newData?.total_bill > newData?.total_bill_yesterday ? "bnb2" : "redtext",
                    classBox: 'icon-box-bill',
                    toolTip: 'Tổng số lần cân trong ngày'
                },
                {
                    title: "TỔNG SỔ ĐỎ",
                    today: newData?.total_book ? formatNumber(newData?.total_book, 0) : 0,
                    persent: caculatorPersent(newData?.total_book, newData?.total_book_yesterday),
                    icon: Images.img_book,
                    bnb: newData?.total_book > newData?.total_book_yesterday ? "bnb2" : "redtext",
                    classBox: 'icon-box-book',
                    toolTip: 'Tổng sổ đỏ cân trong ngày'
                },

            ];
            setDataTop([...tempData]);
        } else {
            setDataTop([...DATA_DEFAULT]);
        }
    }
    const handleSaveWeighingSlip = async (data) => {
        setShowModal(false);
        const params = {
            id: data?.id,
            soHD: data?.SoHD ?? '',
            bookCode: data?.book_code ?? '',
            reason: 'Thêm mã sổ cho phiếu cân',
            userId: userReducer?.data?.id ?? '',
            status: 1,
            price: data?.Price,
            total: data?.Total
        };
        setTimeout(async () => {
            setLoading(true);
        }, 400);
        const res = await updateWeighingSlipById(params);
        setLoading(false);
        if (res && res?.status === "success") {
            message.success("Cập nhật thành công", 3);
            getDataWeighingSlip("", 1);
        } else {
            message.error("Cập nhật thành công", 3);
        }

    }
    const getDataReportBooks = async () => {
        const params = {
            limit: 7,
            book_code: '',
            currentPage: 1
        };
        const res = await getBooksWeighingRecent(params);
        if (res && res?.data) {
            const temp = res.data?.map(item => ({
                ...item,
                percent: (parseInt(item?.volume_dauky ?? 0) + parseInt(item?.total_net ?? 0)) * 100 / parseInt(item?.volume_total > 0 ? item?.volume_total : 1)
            }))
            setDataBookRecent([...temp]);
        } else {
            setDataBookRecent([]);
        }
    }
    const getListBookWarning = async () => {
        const res = await getBooksWarning();
        if (res && res.status === true) {
            setBooksWarning([...res?.data]);
        } else {
            setBooksWarning([]);
        }
    }

    const getDataChart = async () => {
        const res = await getDataForChart();
        if (res && res.status === true) {
            setDataChart([...res?.data]);
        } else {
            setDataChart([]);
        }
    }

    useEffect(() => {
        handleCallMultiApi([getDataForTop(), getDataReportBooks(), getDataChart(), getDataWeighingSlip(), getListBookWarning(), getListPrice()]);
    }, [])

    return (
        <>
            <div className="layout-content">
                <Row className="rowgap-vbox" gutter={[24, 0]}>
                    {dataTop.map((c, index) => (
                        <Col
                            key={index}
                            xs={24}
                            sm={24}
                            md={12}
                            lg={6}
                            xl={6}
                            className="mb-24"
                        >
                            <Card bordered={false} className="criclebox ">
                                <div className="number">
                                    <Row align="middle" gutter={[24, 0]}>
                                        <Col xs={18}>
                                            <span>{c.title}</span>

                                            <Tooltip placement="top" title={c.toolTip}>
                                                <Title level={3}>
                                                    {c.today} {index < 2 ? <small className="txt-unit">Tấn</small> : null}
                                                </Title>
                                            </Tooltip>


                                        </Col>
                                        <Col xs={6} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                            <Tooltip placement="top" title={'Tăng/giảm so với hôm qua'}>
                                                <div className="box-icon-chart">
                                                    {c.persent > 0 ? <RiseOutlined style={{ color: '#52c41a' }} /> : (c.persent < 0 ? <FallOutlined style={{ color: 'red' }} /> : null)}
                                                    <small className={c.bnb}>{`${formatNumber(c.persent, 0)}%`}</small>
                                                </div>

                                            </Tooltip>
                                            <div className={c.classBox}><img src={c.icon} className="img-db" /></div>
                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                        </Col>
                    ))}
                </Row>

                <Row gutter={[24, 0]}>
                    <Col xs={24} sm={12} md={212} lg={12} xl={12} className="mb-24">
                        <Card bordered={false} className="criclebox h-full">
                            <Echart options={{
                                xaxis: {
                                    categories: dataChart?.map(item => moment(item.date, DATE_FORMAT.DDMMYYYY_FORMAT).format(DATE_FORMAT.DDMM_FORMAT))
                                }
                            }} series={[
                                {
                                    name: "Khối lượng",
                                    data: dataChart?.map(item => item.total),
                                    color: "#fff",
                                },
                            ]} />
                        </Card>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12} className="mb-24">
                        <Card bordered={false} className="criclebox h-full">
                            <div className="project-ant">
                                <div>
                                    <Title level={5}>Danh sách sổ đỏ cân gần đây</Title>
                                </div>

                            </div>
                            <div className="ant-list-box table-responsive">
                                <table className="width-100">
                                    <thead>
                                        <tr>
                                            <th>Mã sổ</th>
                                            <th>Chủ rừng</th>
                                            <th>Khối lượng SD/Tổng</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataBookRecent.map((d, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <div style={{ justifyItems: 'center', alignItems: 'center', display: 'flex', flexDirection: 'row' }}>
                                                        <img className="tootip-img" src={Images.img_book} alt="" />
                                                        <Tooltip placement="top" title={d?.book_code}>
                                                            <a><p style={{ fontSize: 13, fontWeight: 'bold', paddingLeft: 12 }}>{d?.book_code?.length > 15 ? `${d?.book_code.substring(0,15)}..` : d?.book_code}</p></a>
                                                        </Tooltip>
                                                        
                                                    </div>
                                                </td>
                                                <td>{d?.book_owner_name ?? ''}</td>
                                                <td>
                                                    <CustomProgress 
                                                        percent={d?.percent ? Math.round(d?.percent) : 0} 
                                                        volumeUsed={`${formatNumber(parseInt(d?.total_net ?? 0) + parseInt(d?.volume_dauky ?? 0), 0)}`} 
                                                        volumeTotal={`${formatNumber(parseInt(d?.volume_total ?? 0), 0)}`} 
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </Card>

                    </Col>
                </Row>

                <Row gutter={[24, 0]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={16} className="mb-24">
                        <Card bordered={false} className="criclebox cardbody h-full">
                            <div className="project-ant">
                                <div>
                                    <Title level={5}>Danh sách phiếu cân mới nhất</Title>
                                    <Paragraph className="lastweek">
                                        Số phiếu cân hôm nay:<span className="blue" style={{ fontSize: 18 }}>{totalBill}</span>
                                    </Paragraph>
                                </div>

                            </div>
                            <div className="ant-list-box table-responsive">
                                <table className="width-100">
                                    <thead>
                                        <tr>
                                            <th>Người bán</th>
                                            <th>Mã phiếu</th>
                                            <th>Loại cân</th>
                                            <th>KL hàng(kg)</th>
                                            <th>Ngày</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataWeighing.map((d, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <div style={{ justifyItems: 'center', alignItems: 'center', display: 'flex', flexDirection: 'row' }}>
                                                        <img className="tootip-img" src={Images.img_user} alt="" />
                                                        <a onClick={() => handleOnClickItem(d)}><p style={{ fontSize: 13, fontWeight: 'bold', paddingLeft: 12 }}>{d?.Seller ?? ''}</p></a>
                                                    </div>
                                                </td>
                                                <td>{d?.SoHD ?? ''}</td>
                                                <td>
                                                    <span className="text-xs font-weight-bold">
                                                        {d.In_out}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="text-xs font-weight-bold">
                                                        {d?.Net ? formatNumber(parseInt(d?.Net), 0) : 0}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="text-xs font-weight-bold">
                                                        {d?.SumDate ? moment(d?.SumDate, DATE_FORMAT.DDMMYYYY_FORMAT).format(DATE_FORMAT.DDMM_FORMAT) : ''}
                                                    </span>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="uploadfile shadow-none">
                                <Button
                                    type="dashed"
                                    className="ant-full-box"
                                    icon={<MoreOutlined />}
                                    onClick={() => navigate("/phieu-can")}
                                >
                                    <span className="click">Xem thêm</span>
                                </Button>
                            </div>
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={8} className="mb-24">
                        <Card bordered={false} className="criclebox h-full">
                            <div className="timeline-box">
                                <Title level={5}>Sổ đã/gần hết hạn</Title>
                                <Paragraph className="lastweek" style={{ marginBottom: 24 }}>
                                </Paragraph>

                                {booksWarning.map((book, index) => (
                                    <Row style={{ marginBottom: 12 }}>
                                        <Col span={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <img src={Images.img_book} style={{ width: 40, height: 35 }} />
                                        </Col>
                                        <Col span={20}>
                                            <div style={{ flexDirection: 'column' }}>
                                                <Title level={5}>{`Mã sổ: ${book.book_code ?? ''}`}</Title>
                                                <p>{'Chủ sổ: '}{book.book_owner_name}</p>
                                                <p>{'Khối lượng còn lại: '}<strong style={{ color: 'red' }}>{book.remainingVolume ? formatNumber(parseInt(book.remainingVolume), 0) : 0} Kg</strong></p>
                                            </div>
                                        </Col>
                                    </Row>

                                ))}
                                <Button
                                    type="primary"
                                    className="width-100"
                                    onClick={() => setReverse(!reverse)}
                                >
                                    {<MenuUnfoldOutlined />} Xem thêm
                                </Button>
                            </div>
                        </Card>
                    </Col>
                </Row>

                <LoadingBase loading={loading} />
                <WeighingModal
                    visible={showModal}
                    data={itemSelected}
                    title={'Thông tin phiếu cân'}
                    onCancel={handleCancel}
                    onSearch={onSearchInfoBook}
                    onSelect={onSelectBook}
                    bookData={dataBook}
                    onSave={handleSaveWeighingSlip}
                />
            </div>
        </>
    );
}

export default DashboardPage;
