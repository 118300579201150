export const getBase64 = (file) =>
new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
});

export const formatNumber = (number, multiplier) => {
    let result;
    if (number === null || number === undefined) {
      result = ''
    }
    if (number === 0) {
      result = 0
    }
    try {
      result = number.toLocaleString('en-US', {
        minimumFractionDigits: multiplier ?? 0,
        maximumFractionDigits: multiplier ?? 0,
      });
    } catch (error) {
      result = ''
    }
    return result;
  }

export const capitalizeFirstLetter = (string) => {
  if (!string) {
    return '';
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};  