
import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import '../../css/print-page.css';
import { Button } from "antd";
import RenderDataToPrintWeight from "./RenderDataToPrintWeight";
import InPhieuCanPage from "./InPhieuCanPage";

const PrintWeighingSlip = () => {
    const printRef = useRef();
    
    return (
        <div style={{ width: 1000, height: '100%' }}>
            <div >
                <InPhieuCanPage ref={printRef} />
            </div>
            <ReactToPrint
                trigger={() => <Button type="primary">Print Phiếu cân</Button>}
                content={() => printRef.current}
                pageStyle={`@page { size: A5 landscape; }`}
            />
        </div>

    )
}
export default React.memo(PrintWeighingSlip);