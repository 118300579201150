
import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import '../../css/print-page.css';
import { Button } from "antd";
import RenderDataToPrintBill from "./RenderDataToPrintBill";

const PrintBillPage = () => {
    const printRef = useRef();
    
    return (
        <div style={{ width: 1000, height: 600 }}>
            {/* <iframe width="100%" height="600" frameborder="0" src={`https://docs.google.com/gview?url=${file_url}&embedded=true`}></iframe> */}
            <div >
                <RenderDataToPrintBill ref={printRef} />
            </div>
            <ReactToPrint
                trigger={() => <Button type="primary">In phiếu chi</Button>}
                content={() => printRef.current}
                pageStyle={`@page { size: A5 landscape; }`}
            />
        </div>

    )
}
export default React.memo(PrintBillPage);