import { Button, Col, Form, Input, Modal, Row, Space, Tabs, message } from "antd"
import React, { useEffect, useMemo, useState } from "react";
import { formatNumber } from "../utils/funcHelper";
import { getDataBillByCode, getInfoBookById, getVolumeUsedByBookCode, searchBookInfo, updatePhieuChi } from "../services/apis";
import LoadingBase from "./LoadingBase";
import { default as VNnum2words } from 'vn-num2words';
import TabBookInfo from "../screens/weighing/components/TabBookInfo";
import TabBillInfo from "../screens/weighing/components/TabBillInfo";
import ModalAddBill from "../screens/weighing/components/ModalAddBill";
import { useDispatch, useSelector } from "react-redux";
import { saveDataPrint } from "../redux/action";

const WeighingModal = ({ visible, onCancel, onSave, title, data }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [isShowModal, setIsShowModal] = useState(false);
    const [dataPhieuChi, setDataPhieuChi] = useState([]);
    const userReducer = useSelector(state => state?.userReducer);
    const dispatch = useDispatch();
    const [bookSelected, setBookSelected] = useState();

    const handleOnSelect = (item, option) => {
        setBookSelected(option);
        getVolumeUsed(option);
        form.setFieldsValue({
            bookCode: option?.book_code ?? '',
            bookOwner: option?.book_owner_name ?? '',
            cmnd: option?.cmnd_book_owner ?? '',
            volumnTotal: option?.volume_total ?? 0,
            volumnDauky: option?.volume_dauky ?? 0
        });

    }
    const handleCancelBill = async (data) => {
        const params = {
            userId: userReducer?.data?.id,
            id: data?.id
        }
        setLoading(true);
        const res = await updatePhieuChi(params);
        setLoading(false);
        if (res && res?.status === "success") {
            message.success("Huỷ phiếu chi thành công");
            getDataPhieuChi();
        } else {
            message.error(res?.message ?? "Huỷ phiếu chi thất bại", 5);
        }
    }
    const itemsTab = useMemo(() => {
        return [
            {
                key: '1',
                label: 'Thông tin sổ',
                children: <TabBookInfo onSelect={handleOnSelect} isShowSearch={true} />,
                forceRender: true
            },
            {
                key: '2',
                label: 'Thông tin phiếu chi',
                children: <TabBillInfo onClickAddBill={() => setIsShowModal(true)} data={dataPhieuChi} callbackCancelBill={() => handleCancelBill()} />
            }
        ]
    }, [dataPhieuChi]);

    const submit = (newData) => {
        const temp = {
            ...data,
            book_code: newData?.bookCode,
            book_id: bookSelected?.book_id,
            Total: data?.Net && data?.Price ? (data?.Net * data?.Price) / 1000 : 0
        };
        if (onSave) onSave(temp);
    };

    const getDataPhieuChi = async () => {
        const params = {
            code: data.SoHD ?? ''
        }
        setLoading(true);
        const res = await getDataBillByCode(params);
        setLoading(false);
        if (res && res?.status === "success") {
            setDataPhieuChi([...res?.data])
        } else {
            setDataPhieuChi([]);
        }
    }

    const handlePrintPhieuCan = async () => {
        try {
            const params = {
                loaican: data?.In_out ?? '',
                soPhieu: data?.SoHD ?? '',
                sumTime: `${data?.SumDate ?? ''} ${data?.SumTime ?? ''}`,
                tareTime: `${data?.TareDate ?? ''} ${data?.TareTime ?? ''}`,
                seller: data?.Seller ?? '',
                biensoxe: data?.BSX ?? '',
                container: data?.BsContainer ?? '',
                typeWood: data?.Goods ?? '',
                price: data?.Price ? formatNumber(parseInt(data?.Price), 0) : 0,
                total: data?.Net && data?.Price ? formatNumber(parseInt((data?.Net * data?.Price) / 1000), 0) : 0,
                weightSum: data?.Mass ? formatNumber(parseInt(data?.Mass), 0) : 0,
                weightTare: data?.Tare ? formatNumber(parseInt(data?.Tare), 0) : 0,
                weightNet: data?.Net ? formatNumber(parseInt(data?.Net), 0) : 0,
                sotienchu: VNnum2words(data?.Net && data?.Price ? (data?.Net * data?.Price) / 1000 : 0),
                note: data?.TextNote ?? ''
            }
            dispatch(saveDataPrint({ ...params }));
            window.open(`/print-phieu-can`, '_blank');
        } catch (error) {
        }
    }
    const getVolumeUsed = async (data) => {
        try {
            const params = {
                book_id: data.book_id
            }
            setLoading(true);
            const res = await getVolumeUsedByBookCode(params);
            setLoading(false);
            if (res && res?.status === true) {
                form.setFieldsValue({
                    volumnUsed: res?.total ?? 0,
                    weighAvailable: res?.total >= 0 ? (data?.volume_total - data?.volume_dauky  - res?.total) : data?.volume_total
                })
            }
        } catch (error) {
            setLoading(false);
        }

    }
    const handleFailed = (err) => {
        console.log("err", err);
        message.error("Chưa điền đủ thông tin. Vui lòng kiểm tra lại")
    }

    const getDataBooks = async (book_id) => {
        let params = {
            book_id: book_id,
        };
        const res = await getInfoBookById(params);
        if (res && res?.data) {
            form.setFieldsValue({
                bookCode: res?.data?.book_code ?? '',
                bookOwner: res?.data?.book_owner_name ?? '',
                cmnd: res?.data?.cmnd_book_owner ?? '',
                volumnTotal: res?.data?.volume_total ?? 0,
                volumnDauky: res?.data?.volume_dauky ?? 0,
            });
            getVolumeUsed(res?.data);
        }
    }

    const dissmis = () => {
        setIsShowModal(false);
        setTimeout(() => {
            getDataPhieuChi();
        }, 200);
    }
    useEffect(() => {
        setBookSelected(null);
        if (data) {
            getDataPhieuChi();
            form.resetFields();
            if (data?.status === '1') {
                getDataBooks(data.book_id);
            }
            form.setFieldsValue({
                SoHD: data.SoHD ?? '',
                Seller: data.Seller ?? '',
                BSX: data.BSX ?? '',
                status: data?.status === '1' ? 'Hoàn thành' : 'Chưa hoàn thành',
                Mass: data.Mass ? formatNumber(parseInt(data.Mass)) : 0,
                Tare: data.Tare ? formatNumber(parseInt(data.Tare)) : 0,
                Net: data.Net ? formatNumber(parseInt(data.Net)) : 0,
                SumDate: `${data.SumDate} ${data.SumTime}`,
                TareDate: `${data.TareDate} ${data.TareTime}`,
                Goods: data.Goods ?? '',
                bookCode: data.book_code ?? '',
                Price: data?.Price ? formatNumber(parseInt(data?.Price, 0)) : 0,
                TotalMoney: data?.Net && data?.Price ? formatNumber((data.Net * data?.Price) / 1000, 0) : 0,
                TextNote: data?.TextNote
            });

        }
    }, [data])

    return (
        <Modal
            title={title}
            open={visible}
            onCancel={onCancel}
            width={'75%'}
            footer={null}
        >
            <Form
                form={form}
                name='weighing-info'
                onFinish={submit}
                onFinishFailed={handleFailed}
                layout={'vertical'}
            >
                <Row gutter={16}>
                    <Col span={6}>
                        <Form.Item label="Mã phiếu" name="SoHD">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={7}>
                        <Form.Item label="Người bán" name="Seller">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item label="Biển số" name="BSX">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Trạng thái" name="status">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={5}>
                        <Form.Item label="Trọng lượng tổng(kg)" name="Mass">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item label="Trọng lượng bì(kg)" name="Tare">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item label="Trọng lượng hàng(kg)" name="Net">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="Đơn giá(vnđ)" name="Price">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item label="Thành tiền(vnđ)" name="TotalMoney">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={5}>
                        <Form.Item label="Thời gian cân tổng" name="SumDate">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item label="Thời gian cân bì" name="TareDate">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Loại hàng" name="Goods">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Ghi chú" name="TextNote">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                </Row>
                <Tabs
                    defaultActiveKey="1"
                    type="card"
                    items={itemsTab}
                    style={{ minWidth: 500 }}
                />
                <Row>
                    <Col span={24} style={{ display: 'flex', justifyContent: 'right' }}>
                        <Space >
                            <Button type="primary" danger onClick={handlePrintPhieuCan}>
                                {'In phiếu cân'}
                            </Button>
                            <Button onClick={onCancel}>
                                {'Đóng'}
                            </Button>
                            {/* {
                                data?.status !== '1' ?
                                    <Button type="primary" htmlType="submit">
                                        Cập nhật
                                    </Button> : null
                            } */}
                            <Button type="primary" htmlType="submit">
                                Cập nhật
                            </Button>

                        </Space>
                    </Col>

                </Row>

            </Form>
            <LoadingBase loading={loading} />
            <ModalAddBill title={'Thêm phiếu chi'} visible={isShowModal} dismiss={dissmis} data={data} />
        </Modal>
    )
}
export default WeighingModal;