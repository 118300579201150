import { Button, Col, Popconfirm, Row, Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import { formatNumber } from "../../../utils/funcHelper";
import { useDispatch } from "react-redux";
import { saveDataPrint } from "../../../redux/action";


const TabBillInfo = ({ data, onClickAddBill, callbackCancelBill }) => {
    const [dataTable, setDataTable] = useState([]);
    const dispatch = useDispatch();
    const columns = [
        {
            title: 'STT',
            dataIndex: 'index',
            width: 45,
            key: 'index',
            align: 'center',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Tên lái xe',
            width: 200,
            dataIndex: 'hoten',
            key: 'hoten',
            fixed: 'left',
        },
        {
            title: 'CMND',
            dataIndex: 'cmnd',
            key: '1',
            align: 'center',
            width: 120,
        },
        {
            title: 'Ngày cấp',
            dataIndex: 'ngaycap',
            key: '2',
            width: 120,
        },
        {
            title: 'BSX',
            dataIndex: 'biensoxe',
            fixed: 'right',
            width: 100,
        },
        {
            title: 'Số tiền chi',
            dataIndex: 'thucnhan',
            key: '3',
            align: 'center',
            render: (text, record, index) => (<p className='value-column'>{formatNumber(parseInt(text))}</p>)
        },
        {
            title: 'Ngày chi',
            dataIndex: 'createtime',
            key: '6',
            width: 120,
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: '6',
            width: 60,
            render: (text, record, index) => <div>{text === "2" ? "Đã Huỷ" : "Đã chi"}</div>
        },
        {
            title: 'Hành động',
            dataIndex: 'status',
            key: 'operation',
            width: 60,
            fixed: 'right',
            render: (text, record, index) => (
                text === "1" ?
                    <Space size="middle">
                        <a onClick={() => handlePrint(record)}>In phiếu</a>
                        <Popconfirm
                            title="Huỷ phiếu chi"
                            description="Bạn có chắc chắn muốn huỷ phiếu chi này?"
                            onConfirm={() => handleCancelBill(record)}
                            okText="Có"
                            cancelText="Không"
                            okType="primary"
                        ><a style={{ color: 'red' }}>Huỷ phiếu</a></Popconfirm>

                    </Space> : null
            )
        },
    ];

    const handlePrint = (data) => {
        dispatch(saveDataPrint({ ...data }));
        window.open(`/print-phieu-chi`, '_blank');
    }

    const handleCancelBill = (data) => {
        callbackCancelBill(data);
    }

    const expandedRowRender = (record) => {
        const columnDetails = [
            {
                title: 'Loại',
                dataIndex: 'status',
                key: 'status',
                render: (text, rc) => <div>{rc.phieuchi_id === record?.id ? "Phiếu cọc" : "Phiếu trả cọc"}</div>,
            },
            {
                title: 'Tên LX',
                dataIndex: 'tenlaixe',
                key: 'tenlaixe',
            },
            {
                title: 'CMND',
                dataIndex: 'cmnd',
                key: 'cmnd'
            },
            {
                title: 'Số tiền',
                dataIndex: 'sotien',
                key: 'sotien',
                render: (text, record, index) => (<p className='value-column'>{formatNumber(parseInt(text))}</p>)
            },
            {
                title: 'Mã phiếu cọc',
                dataIndex: 'maphieucan',
                key: 'maphieucan'
            },
            {
                title: 'Mã phiếu trả cọc',
                dataIndex: 'maphieucan_tracoc',
                key: 'maphieucan'
            },
            {
                title: 'Trạng thái',
                dataIndex: 'status',
                key: 'status',
                render: (text) => <div style={text === "2" ? { color: 'blue' } : { color: 'red' }}>{text === "2" ? "Đã trả cọc" : (text === "1" ? "Đã thu cọc" : "Đã huỷ")}</div>,
            },
        ];

        return <Table columns={columnDetails} dataSource={record?.lstDetails ?? []} pagination={false} />;
    };
    useEffect(() => {
        setDataTable([...data]);
    }, [data])
    return (
        <>


            <Row>
                <Col span={24}>
                    <Button
                        style={{ height: 35, justifyContent: 'center', alignItems: 'center', display: 'flex', width: 100, color: 'blue' }}
                        onClick={() => onClickAddBill()}
                    >
                        Tạo phiếu chi
                    </Button>
                </Col>
            </Row>


            <Table
                columns={columns}
                expandable={{
                    expandedRowRender: expandedRowRender,
                    rowExpandable: (record) => record.name !== 'Not Expandable',
                }}
                dataSource={dataTable}
                pagination={false}
                locale={{ emptyText: 'Không có dữ liệu' }}
                rowKey={(record) => record?.id}
            />

        </>
    );
}

export default React.memo(TabBillInfo);