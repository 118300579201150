import React, { useEffect, useState } from 'react';
import { getReportWeighing, saveLogUser } from '../../services/apis';
import { Button, Col, DatePicker, Form, Row, Space, Table, theme } from 'antd';
import dayjs from 'dayjs';
import LoadingBase from '../../components/LoadingBase';
import { formatNumber } from '../../utils/funcHelper';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';


const ReportWeighingSlipPage = () => {
    const { token } = theme.useToken();
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const userReducer = useSelector(state => state?.userReducer);
    const { RangePicker } = DatePicker;

    const columns = [
        {
            title: 'Loại cân',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
            render: (text, record, index) => (<a onClick={() => handleClickLink(text)}>{text}</a>)
        },
        {
            title: 'KL tổng(kg)',
            dataIndex: 'totalMass',
            align: 'center',
            render: (text, record, index) => (<div>{formatNumber(parseInt(text), 0)}</div>)
        },
        {
            title: 'KL bì(kg)',
            dataIndex: 'totalTare',
            align: 'center',
            render: (text, record, index) => (<div>{formatNumber(parseInt(text), 0)}</div>)
        },
        {
            title: 'KL hàng(kg)',
            dataIndex: 'totalNet',
            align: 'center',
            render: (text, record, index) => (<div>{formatNumber(parseInt(text), 0)}</div>)
        },
        {
            title: 'Phiếu cân có sổ',
            dataIndex: 'totalBooks',
            align: 'center',
            render: (text, record, index) => (<div>{formatNumber(parseInt(text), 0)}</div>)
        },
        {
            title: 'Phiếu cân không sổ',
            dataIndex: 'totalBookCodeNull',
            align: 'center',
            render: (text, record, index) => (<div>{formatNumber(parseInt(text), 0)}</div>)
        }

    ];
    const formStyle = {
        maxWidth: 'none',
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 10
    };

    const handleClickLink = (type) => {
        navigate("/phieu-can", {state: {
            type: type,
            from: dayjs(form.getFieldValue("date")?.[0]).format("YYYY-MM-DD HH:mm:ss"),
            to: dayjs(form.getFieldValue("date")?.[1]).format("YYYY-MM-DD HH:mm:ss")
        }});
    }

    const getDataReport = async (fromDate, toDate) => {
        const params = {
            fromDate: fromDate,
            toDate: toDate
        }
        setLoading(true);
        const res = await getReportWeighing(params);
        setLoading(false);
        if (res && res?.status === "success" && res?.data) {
            setData([...convertArr(res?.data)]);
        } else {
            setData([]);
        }
    }
    const saveLog = async (action) => {
        const params = {
            action: action,
            username: userReducer?.data?.username,
            description: 'Click bao cao'
        };
        const res = await saveLogUser(params);
    }

    const onFinish = (data) => {
        const fromDate = dayjs(form.getFieldValue("date")?.[0]).format("DD/MM/YYYY HH:mm:ss");
        const toDate = dayjs(form.getFieldValue("date")?.[1]).format("DD/MM/YYYY HH:mm:ss");
        getDataReport(fromDate, toDate);
        saveLog("Lọc báo cáo phiếu cân");
    }
    const convertArr = (dataConvert) => {
        if (!dataConvert) {
            return []
        }
        const dataArray = [];
        for (const key in dataConvert) {
            const obj = dataConvert[key];
            obj["name"] = key; // Gán key là giá trị id của mỗi object
            dataArray.push(obj);
        }
        return dataArray;
    }

    useEffect(() => {
        if (form) {
            form.setFieldValue("date", [dayjs().startOf('month'), dayjs()])
        }
        const currentDate = dayjs().format('DD/MM/YYYY HH:mm:ss');
        const fromDate = dayjs().startOf('month').format('DD/MM/YYYY HH:mm:ss');
        getDataReport(fromDate, currentDate);
        saveLog("Lọc báo cáo phiếu cân");
    }, [])

    return (
        <div className='container-content'>
            <Form form={form} name="advanced_search" style={formStyle} onFinish={onFinish}>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            name={'date'}
                            label={"Chọn thời gian"}
                        >
                            <RangePicker
                                style={{ height: 40, width: '100%' }}
                                placeholder={['Bắt đầu', 'Kết thúc']}
                                allowClear={false}
                                showTime
                                format={"DD/MM/YYYY HH:mm:ss"}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={4}>
                        <Space size="small">
                            <Button type="primary" htmlType="submit">
                                Tìm kiếm
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Table 
                dataSource={data}
                columns={columns}
                pagination={false}
                rowKey={(record) => record?.name}
                style={{minHeight: 'calc(100vh - 220px)'}}
            />
            
            <LoadingBase loading={loading}/>
        </div>
    );
};
export default ReportWeighingSlipPage;