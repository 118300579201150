import { SearchOutlined } from "@ant-design/icons";
import { Col, Form, Input, InputNumber, Row, Select, Tooltip } from "antd";
import React, { useState } from "react";
import { searchBookInfo } from "../../../services/apis";
import { formatNumber } from "../../../utils/funcHelper";


const TabBookInfo = ({ onSelect, isShowSearch }) => {
    const [value, setValue] = useState();
    const [lstBook, setLstBook] = useState([]);

    const handleChangeSearch = (val) => {
        setValue(val);
    }
    const renderLabel = (item) => {
        return (
            <Row>
                <Col span={3}>
                    <Tooltip title={item.book_code}><p>{item.book_code?.length > 15 ? `${item.book_code?.substring(0, 13)}...` : item.book_code}</p></Tooltip>
                </Col>
                <Col span={4}>
                    <p>{item.book_owner_name}</p>
                </Col>
                <Col span={3}>
                    <p>{item.cmnd_book_owner}</p>
                </Col>
                <Col span={6}>
                    <p>{item?.book_license_plates ?? ''}</p>
                </Col>
                <Col span={2}>
                    <strong>{item?.khoiluong_conlai ? formatNumber(parseInt(item?.khoiluong_conlai), 0) : ''}</strong>
                </Col>
                <Col span={6}>
                    <Tooltip title={item?.book_address ?? ''}><p>{item?.book_address ?? ''}</p></Tooltip>
                </Col>
            </Row>
        )
    }
    const onSearch = async (keyword) => {
        let params = {
            limit: 10,
            currentPage: 1,
            keyword: keyword ?? ''
        };
        const res = await searchBookInfo(params);
        if (res && res?.data) {
            setLstBook([...res.data?.map(item => ({
                ...item,
                value: item.book_code,
                label: renderLabel(item),
                child: `${item.book_owner_name} - ${item.book_license_plates}`
            }))]);
        } else {
            setLstBook([]);
        }
    }
    const renderDropdown = (menu) => {

        return (
            <>
                <Row>
                    <Col span={3}>
                        <p className="text-title-col pl-10">Mã sổ</p>
                    </Col>
                    <Col span={4}>
                        <p className="text-title-col pl-10">Chủ sổ</p>
                    </Col>
                    <Col span={3}>
                        <p className="text-title-col">CMND</p>
                    </Col>
                    <Col span={6}>
                        <p className="text-title-col">BSX</p>
                    </Col>
                    <Col span={2}>
                        <p className="text-title-col">KL còn</p>
                    </Col>
                    <Col span={6}>
                        <p className="text-title-col">Địa chỉ sổ</p>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <div className="line-container"></div>
                    </Col>
                </Row>
                {menu}
            </>

        )
    }
    return (
        <>
            {
                isShowSearch ?
                    <Row>
                        <Col span={12}>
                            <Form.Item name="bookSearch">
                                <Select
                                    showSearch={true}
                                    value={value}
                                    placeholder={'Nhập mã sổ đỏ/ Tên chủ sổ/ CMND chủ/ BSX'}
                                    style={{ width: 500, height: 40 }}
                                    optionLabelProp="child"
                                    dropdownMatchSelectWidth={1100}
                                    defaultActiveFirstOption={false}
                                    dropdownRender={renderDropdown}
                                    suffixIcon={<SearchOutlined />}
                                    filterOption={false}
                                    onSearch={onSearch}
                                    onChange={handleChangeSearch}
                                    notFoundContent={'Không có dữ liệu'}
                                    options={lstBook}
                                    onSelect={onSelect}
                                />
                            </Form.Item>
                        </Col>
                    </Row> : null
            }


            <Row gutter={16}>
                <Col span={4}>
                    <Form.Item label="Mã sổ" name="bookCode" >
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item label="Chủ sổ" name="bookOwner">
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item label="CMND" name="cmnd">
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={3}>
                    <Form.Item label="KL tổng" name="volumnTotal">
                        <InputNumber
                            placeholder={'0'}
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            className="input-number-base"
                            min={0}
                            disabled
                        />
                    </Form.Item>
                </Col>
                <Col span={3}>
                    <Form.Item label="KL đầu kỳ" name="volumnDauky">
                        <InputNumber
                            placeholder={'0'}
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            className="input-number-base"
                            min={0}
                            disabled
                        />
                    </Form.Item>
                </Col>
                <Col span={3}>
                    <Form.Item label="KL sử dụng" name="volumnUsed">
                        <InputNumber
                            placeholder={'0'}
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            className="input-number-base"
                            min={0}
                            disabled
                        />
                    </Form.Item>
                </Col>
                <Col span={3}>
                    <Form.Item label="KL còn" name="weighAvailable">
                        <InputNumber
                            placeholder={'0'}
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            className="input-number-base"
                            min={0}
                            disabled
                        />
                    </Form.Item>
                </Col>
            </Row>


        </>
    );
}

export default React.memo(TabBookInfo);