import { Button, Col, Form, Input, Modal, Row, Space } from "antd"
import React, { useEffect } from "react";
import { formatNumber } from "../utils/funcHelper";
import moment from "moment";
import { DATE_FORMAT } from "../constants/constants";

const BookInfoModal = ({ visible, onCancel, data, title }) => {
    console.log("data modal", data);
    const [form] = Form.useForm();
    
    useEffect(() => {
        if (data) {
            form.resetFields();
            form.setFieldsValue({
                book_code: data.book_code ?? '',
                book_owner_name: data.book_owner_name ?? '',
                cmnd_book_owner: data.cmnd_book_owner ?? '',
                cmnd_date_owner: data.cmnd_date_owner ? moment(data.cmnd_date_owner).format(DATE_FORMAT.DDMMYYYY_FORMAT) : '',
                book_address: data.book_address ?? '',
                book_owner_address: data?.book_owner_address ?? '',
                bienso: data?.book_license_plates ?? '',
                book_acreage: data?.book_acreage ? formatNumber(parseInt(data.book_acreage)) : 0,
                wood_acreage: data?.wood_acreage ? formatNumber(parseInt(data.wood_acreage)) : 0,
                ratio: data?.ratio ?? 0,
                status: data?.status === '2' ? 'Đã duyệt' : 'Chưa duyệt',
                volume_total: data?.volume_total ? formatNumber(parseInt(data.volume_total)) : 0,
                volume_dauky: data.volume_dauky ? formatNumber(parseInt(data.volume_dauky)) : 0,
                volumnUsed: data.total_net ? formatNumber(parseInt(data.total_net)) : 0,
                weighAvailable: formatNumber(parseInt(data.volume_total ?? 0) - parseInt(data.volume_dauky ?? 0) - parseInt(data.total_net ?? 0), 0),
            });

        }
    }, [data])

    return (
        <Modal
            title={title}
            open={visible}
            onCancel={onCancel}
            width={'70%'}
            footer={null}
        >
            <Form
                form={form}
                name='weighing-info'
                layout={'vertical'}
            >
                <Row gutter={16}>
                    <Col span={6}>
                        <Form.Item label="Mã sổ" name="book_code">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={7}>
                        <Form.Item label="Chủ sổ" name="book_owner_name">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item label="CMND" name="cmnd_book_owner">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Ngày cấp" name="cmnd_date_owner">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={10}>
                        <Form.Item label="Địa chỉ sổ" name="book_address">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={10}>
                        <Form.Item label="Địa chỉ chủ sổ" name="book_owner_address">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="Biển số" name="bienso">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    
                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        <Form.Item label="Tổng diện tích(m2)" name="book_acreage">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Diện tích trồn rừng(m2)" name="wood_acreage">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Hệ số quy đổi(m2->Kg)" name="ratio">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Trạng thái sổ" name="status">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                </Row>
                

                <Row gutter={16}>
                    <Col span={6}>
                        <Form.Item label="KL tổng(Kg)" name="volume_total">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="KL đầu kỳ(Kg)" name="volume_dauky">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="KL đã sử dụng(Kg)" name="volumnUsed">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="KL còn(Kg)" name="weighAvailable">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24} style={{ display: 'flex', justifyContent: 'right' }}>
                        <Space >
                            <Button onClick={onCancel} type="primary">
                                {'Đóng'}
                            </Button>
                        
                        </Space>
                    </Col>

                </Row>

            </Form>
        </Modal>
    )
}
export default BookInfoModal;