
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row, Select, Space, theme, DatePicker, Table, message } from 'antd';
import LoadingBase from '../../components/LoadingBase';
import { getAllUsers, getListBooks, getListStatus } from '../../services/apis';
import '../../css/listbook-page.css';
import { formatNumber } from '../../utils/funcHelper';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import FooterTable from '../../components/FooterTable';

const ApproveBookPage = () => {
    const { token } = theme.useToken();
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalPage, setTotalPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [lstStatus, setLstStatus] = useState([]);
    const userReducer = useSelector(state => state?.userReducer);
    const columns = [
        {
            title: 'STT',
            dataIndex: 'index',
            width: 45,
            key: 'index',
            align: 'center',
            render: (text, record, index) => index + 1,
            fixed: 'left',
          },
        {
            title: 'Tên chủ sổ',
            width: 150,
            dataIndex: 'book_owner_name',
            key: 'book_owner_name',
            fixed: 'left',
            render: (text, record, index) => (<div style={{textAlign: 'left', paddingLeft: 16, paddingBottom: 5, paddingTop: 5}}>{text}</div>)
        },
        {
            title: 'CMND',
            dataIndex: 'cmnd_book_owner',
            key: '1',
            width: 100,
            align: 'center',
        },
        {
            title: 'BSX',
            dataIndex: 'book_license_plates',
            key: '1',
            width: 100,
            align: 'center',
        },
        {
            title: 'Diện tích sổ(m2)',
            dataIndex: 'book_acreage',
            key: '2',
            width: 110,
            align: 'center',
            render: (text, record, index) => (<div className='value-column'>{formatNumber(parseInt(text))}</div>)
        },
        {
            title: 'Diện tích trồng',
            dataIndex: 'wood_acreage',
            key: '3',
            width: 110,
            align: 'center',
            render: (text, record, index) => (<div className='value-column'>{formatNumber(parseInt(text))}</div>)
        },
        {
            title: 'KL tổng(kg)',
            dataIndex: 'volume_total',
            key: '4',
            width: 110,
            align: 'center',
            render: (text, record, index) => (<div className='value-column'>{formatNumber(parseInt(text))}</div>)
        },
        {
            title: 'KL đầu kỳ',
            dataIndex: 'volume_dauky',
            key: '5',
            width: 110,
            align: 'center',
            render: (text, record, index) => (<div className='value-column'>{formatNumber(parseInt(text))}</div>)
        },
        {
            title: 'Địa chỉ sổ',
            dataIndex: 'book_address',
            key: '6',
            render: (text, record, index) => (<div style={{textAlign: 'left', paddingLeft: 16}}>{text}</div>)
        },
        {
            title: 'Trạng thái',
            dataIndex: 'statusId',
            fixed: 'right',
            width: 100,
            align: 'center',
            render: (text, record, index) => (<div style={text === '1' ? { color: 'red' } : { color: 'blue' }}>{text === '1' ? 'Chờ duyệt' : 'Đã duyệt'}</div>)
        },
        {
            title: 'Hành động',
            key: 'operation',
            fixed: 'right',
            width: 100,
            align: 'center',
            render: (text, record, index) => <a onClick={() => window.open(`/chi-tiet-duyet-so/${record?.book_id}`, "_blank")}>{record?.statusId === "1" ? "Duyệt" : "Xem"}</a>,
        },
    ];
    const formStyle = {
        maxWidth: 'none',
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 10
    };
    
    const handleNext = () => {
        if (loading) {
            return;
        }
        const page = currentPage;
        setCurrentPage(page + 1);
        getDataBooks(page + 1);
    }
    const handleOnSelect = (value) => {
        setCurrentPage(value);
        getDataBooks(value)
    }
    const handlePrev = () => {
        if (loading) {
            return;
        }
        const page = currentPage;
        setCurrentPage(page - 1);
        getDataBooks(page - 1);
    }
    const getDataStatus = async () => {
        setLoading(true);
        const res = await getListStatus();
        if (res && res?.status === "success") {
            if (res?.data) {
                form.setFieldValue("status", res?.data?.[0]?.id);
                setLstStatus([...res.data]);
                getDataBooks(1);
            }
        } else {
            setLstStatus([]);
            message.error('Lấy dữ liệu thất bại')
        }
        setLoading(true);
    }

    const getDataBooks = async (currentPage) => {
        let params = {
            limit: 50,
            currentPage: currentPage,
            bookCode: form.getFieldValue("bookCode") ?? '',
            ownerName: form.getFieldValue("ownerName") ?? '',
            ownerCNMD: form.getFieldValue("ownerCNMD") ?? '',
            statusId: form.getFieldValue("status") ?? 1,
            approvedById: userReducer?.data?.id ? userReducer?.data?.id : ''
        };
        setLoading(true);
        const res = await getListBooks(params);
        setLoading(false);
        if (res && res?.data) {
            setData([...res.data]);
            setTotalPage(res?.totalPages);
        } else {
            setData([]);
            setTotalPage(0);
        }
    }
    
    const onFinish = async (data) => {
        setCurrentPage(1);
        getDataBooks(1);
    };
    useEffect(() => {
        getDataStatus();
    }, [])
    return (
        <div className='container-content'>
            <Form form={form} name="advanced_search" style={formStyle} onFinish={onFinish}>
                <Row gutter={24}>
                    <Col span={5}>
                        <Form.Item
                            name={'bookCode'}
                        >
                            <Input placeholder={'Mã sổ'} />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item
                            name={'ownerName'}
                        >
                            <Input placeholder={'Tên chủ rừng'} />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item
                            name={'ownerCNMD'}
                        >
                            <Input placeholder={'CMND chủ rừng'} />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item
                            name={'status'}
                        >
                            <Select 
                                showSearch
                                placeholder={'Trạng thái'}
                                className='custom-select'
                                optionFilterProp="children"
                                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                options={lstStatus.map(status => ({
                                    ...status,
                                    value: status.id,
                                    label: status.status_name
                                }))}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Space size="small">
                            <Button type="primary" htmlType="submit">
                                Tìm kiếm
                            </Button>
                            <Button
                                onClick={() => {
                                    form.resetFields();
                                }}
                            >
                                Clear
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Table
                className='table-book'
                columns={columns}
                dataSource={data}
                pagination={false}
                scroll={{
                    x: 1300
                }}
                rowKey={(record) => record?.id}
                rowClassName={'row-table-book'}
            />
            <FooterTable
                totalRecord={data?.length}
                totalPage={totalPage}
                currentPage={currentPage}
                handleNext={handleNext}
                handlePrev={handlePrev}
                onSelect={handleOnSelect}
            >
            </FooterTable>
            <LoadingBase loading={loading} />
        </div>

    );
};
export default ApproveBookPage;