import React, { forwardRef } from "react";
import '../../css/print-page.css';
import { Col, Row } from "antd";
import QRCode from "react-qr-code";
import { useSelector } from "react-redux";
import moment from "moment";
import { capitalizeFirstLetter } from "../../utils/funcHelper";

const RenderDataToPrintWeight = ({type}, ref) => {
    const printReducer = useSelector(state => state?.printReducer);
    const userReducer = useSelector(state => state?.userReducer);
    console.log("printReducer",printReducer)
    const data = printReducer?.data;
    return (
        <div ref={ref} style={{marginLeft: 20, marginRight: 20, background: '#FFF'}}>
            <Row>
                <Col span={18} style={{ display: "flex", flexDirection: 'column', alignItems: 'center' }}>
                    <p className="title-header-bill color-print">CÔNG TY TNHH BIOMASS FUEL VIỆT NAM</p>
                    <p className="txt-address color-print">Địa chỉ: số 05, đường số 2, KCN VSIP Nghệ An,</p>
                    <p className="txt-address color-print">xã Hưng Tây, huyện Hưng Nguyên, tỉnh Nghệ An</p>
                    <p className="title-name-bill color-print">PHIẾU CÂN HÀNG</p>
                    <p className="txt-address color-print">Cân nhập</p>
                    <p className="txt-address color-print txt-type">({type ?? ''})</p>
                </Col>
                <Col span={6}>
                    <QRCode
                        size={256}
                        style={{ height: 130, maxWidth: "100%", width: "100%" }}
                        value={`${data?.soPhieu ?? ''}-${moment().format("DD/MM/YYYY - HH:mm:ss")}-${userReducer?.data?.id ?? ''}`}
                        viewBox={`0 0 256 256`}
                        />
                </Col>
            </Row>
            <Row>
                <Col span={18}>
                </Col>
                <Col span={6}>
                    <span className="txt-address color-print"><strong className="strong-bill color-print">Số phiếu:</strong> {data?.soPhieu ?? ''}</span>
                </Col>
            </Row>
            <Row className="row-body">
                <Col span={12}>
                    <span className="txt-body color-print"><strong className="txt-body color-print">Thời gian cân tổng:</strong> {data?.sumTime ?? ''}</span>
                </Col>
                <Col span={12}>
                    <span className="txt-body color-print"><strong className="txt-body color-print">Thời gian cân bì:</strong> {data?.tareTime ?? ''}</span>
                </Col>
            </Row>
            <Row className="row-body">
                <Col span={24}>
                    <span className="txt-body color-print"><strong className="txt-body color-print">Bên bán:</strong> {data?.seller ? data?.seller?.toUpperCase() : ''}</span>
                </Col>
            </Row>
            <Row className="row-body">
                <Col span={24}>
                    <span className="txt-body color-print"><strong className="txt-body color-print">Bên mua:</strong> Công ty TNHH Biomass Fuel VN</span>
                </Col>
            </Row>
            <Row className="row-body">
                <Col span={8}>
                    <span className="txt-body color-print"><strong className="txt-body color-print">Biển số:</strong> {data?.biensoxe ?? ''}</span>
                </Col>
                <Col span={16}>
                    <span className="txt-body color-print"><strong className="txt-body color-print">Số Container:</strong> {data?.container ?? ''}</span>
                </Col>
            </Row>
            <Row className="row-body">
                <Col span={10}>
                    <span className="txt-body color-print"><strong className="txt-body color-print">Loại hàng:</strong> {data?.typeWood ?? ''}</span>
                </Col>
                <Col span={7}>
                    <span className="txt-body color-print"><strong className="txt-body color-print">Đơn giá:&nbsp;</strong> {'0'} VNĐ/T</span>
                </Col>
                <Col span={7}>
                    <span className="txt-body color-print"><strong className="txt-body color-print">Tiền cân:&nbsp;</strong> {'0'} VNĐ</span>
                </Col>
            </Row>
            <Row className="row-body">
                <Col span={24}>
                    <table className="table-bill">
                        <tr>
                            <th className="title-table color-print">Trọng lượng tổng</th>
                            <th className="title-table color-print">Trọng lượng bì</th>
                            <th className="title-table color-print">Trọng lượng hàng</th>
                        </tr>
                        <tr>
                            <td><span className="content-td color-print">{data?.weightSum ?? '0'}&nbsp;<strong className="unit color-print">Kg</strong></span></td>
                            <td><span className="content-td color-print">{data?.weightTare ?? '0'}&nbsp;<strong className="unit color-print">Kg</strong></span></td>
                            <td><span className="content-td color-print">{data?.weightNet ?? '0'}&nbsp;<strong className="unit color-print">Kg</strong></span></td>
                        </tr>
                    </table>
                </Col>
            </Row>
            <Row className="row-body">
                <Col span={24}>
                    <span className="txt-body color-print"><span className="txt-body color-print">Số tiền bằng chữ:</span> {'Không đồng'}./.</span>
                </Col>
            </Row>
            <Row className="row-body">
                <Col span={24}>
                    <span className="txt-body color-print"><span className="txt-body color-print">Ghi chú:</span> {data?.note ?? ''}</span>
                </Col>
            </Row>
            <Row className="row-body">
                <Col span={8} className="center-col">
                    <span className="txt-body color-print">Người cân</span>
                </Col>
                <Col span={8} className="center-col">
                    <span className="txt-body color-print">Người giao</span>
                </Col>
                <Col span={8} className="center-col">
                    <span className="txt-body color-print">Người nhận</span>
                </Col>
            </Row>
        </div>
    )
}

export default forwardRef(RenderDataToPrintWeight);