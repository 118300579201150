import { ExclamationCircleOutlined, ImportOutlined, InfoCircleOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Modal, Row, Table, Upload } from "antd";
import React, { useState } from "react";
import * as XLSX from 'xlsx';
import '../../css/importexcel-page.css';
import { importFromExcel } from "../../services/apis";
import LoadingBase from "../../components/LoadingBase";
import LimitContentCell from "../../components/LimitContentCell";
import { formatNumber } from "../../utils/funcHelper";

const ImportExcelPage = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [isShowButton, setIsShowButton] = useState(false);
    const [modal, contextHolder] = Modal.useModal();
    const [loadingImport, setLoadingImport] = useState(false);
    const [message, setMessage] = useState('');
    const [totalSuccess, setTotalSuccess] = useState();
    const [isShowModalMessage, setIsShowModalMessage] = useState(false);
    const [listFailed, setListFailed] = useState([]);
    const columns = [
        {
            title: 'STT',
            key: 'index',
            width: 45,
            render: (text, record) => data.indexOf(record) + 1,
            fixed: 'left',
        },
        {
            title: 'Mã Sổ',
            dataIndex: 0,
            width: 100,
            key: 'book_code',
            fixed: 'left',
            render: (text, record, index) => <LimitContentCell text={text}/>
        },
        {
            title: 'Chủ sổ',
            dataIndex: 1,
            key: 'owner_book',
            width: 150,
        },
        {
            title: 'Địa chỉ sổ',
            dataIndex: 2,
            key: '1',
            width: 150,
            render: (text, record) => <LimitContentCell text={text}/>
        },
        {
            title: 'Diện tích sổ',
            dataIndex: 3,
            key: '2',
            width: 100,
        },
        {
            title: 'Diện tích rừng',
            dataIndex: 4,
            key: '3',
            width: 100,
        },
        {
            title: 'Loại sổ',
            dataIndex: 5,
            key: '4',
            width: 100,
        },
        {
            title: 'Ngày cấp sổ',
            dataIndex: 6,
            key: '5',
            width: 100,
        },
        {
            title: 'Bộ phận QL sổ',
            dataIndex: 7,
            key: '6',
            width: 150,
            render: (text, record) => <LimitContentCell text={text}/>
        },
        {
            title: 'CMND chủ sổ',
            dataIndex: 8,
            key: '7',
            width: 100,
        },
        {
            title: 'Ngày CMND chủ',
            dataIndex: 9,
            key: '8',
            width: 100,
        },
        {
            title: 'NC CMND chủ',
            dataIndex: 10,
            key: '9',
            width: 100,
        },
        {
            title: 'Địa chỉ chủ sổ',
            dataIndex: 11,
            key: '10',
            width: 150,
            render: (text, record) => <LimitContentCell text={text}/>
        },
        {
            title: 'Tên ĐSH',
            dataIndex: 12,
            key: '11',
            width: 150,
        },
        {
            title: 'CMND ĐSH',
            dataIndex: 13,
            key: '12',
            width: 100,
        },
        {
            title: 'Ngày CMND ĐSH',
            dataIndex: 14,
            key: '13',
            width: 100,
        },
        {
            title: 'NC CMND ĐSH',
            dataIndex: 15,
            key: '14',
            width: 100,
            render: (text, record) => <LimitContentCell text={text}/>
        },
        {
            title: 'Mục đích',
            dataIndex: 16,
            key: '15',
            width: 100,
            render: (text, record) => <LimitContentCell text={text}/>
        },
        {
            title: 'Ngày hết hạn',
            dataIndex: 17,
            key: '16',
            width: 100,
        },
        {
            title: 'Ghi chú',
            dataIndex: 18,
            key: '17',
            width: 100,
            render: (text, record) => <LimitContentCell text={text}/>
        },
        {
            title: 'Tỉ lệ',
            dataIndex: 19,
            key: '18',
            width: 100,
        },
        {
            title: 'KL tổng',
            dataIndex: 20,
            key: '19',
            width: 100,
            render: (text, record) => (<div>{formatNumber(record[20], 2)}</div>)
        },
        {
            title: 'KL đầu kỳ',
            dataIndex: 21,
            key: '20',
            width: 100,
            render: (text, record) => (<div>{formatNumber(record[21], 2)}</div>)
        },
        {
            title: 'Biển số',
            dataIndex: 22,
            key: '21',
            width: 100,
        },
        {
            title: 'Người tạo',
            dataIndex: 23,
            key: '22',
            width: 150,
        },
        {
            title: 'Ngày tạo',
            dataIndex: 24,
            key: '23',
            width: 100,
        },
        {
            title: 'Trạng thái',
            dataIndex: 25,
            key: '24',
            width: 100,
        },
        {
            title: 'Người duyệt',
            dataIndex: 26,
            key: '25',
            width: 150,
        },
        {
            title: 'Ngày duyệt',
            dataIndex: 27,
            key: '26',
            with: 100
        }
    ]
    const confirm = () => {
        modal.confirm({
            title: 'Xác nhận',
            icon: <ExclamationCircleOutlined />,
            content: 'Bạn có muốn lưu thông tin sổ không?',
            okText: 'Có',
            cancelText: 'Không',
            onOk: () => handleSaveInfo()
        });
    };
    const handleSaveInfo = async () => {
        setLoading(true);
        const res = await importFromExcel(data);
        setLoading(false);
        console.log("ressss", res);
        if (res) {
            setTotalSuccess(res?.totalSuccess ?? 0);
            if (res?.code === 1) {
                setMessage(res.msg ?? 'Cập nhật dữ liệu thành công');
                setIsShowModalMessage(true);
                setData([]);
                setIsShowButton(false);
            } else if (res?.code === 2) {
                setMessage(res.msg ?? 'Cập nhật dữ liệu thành công');
                setListFailed(res.failedArr && res.failedArr.length > 0 ? [...res.failedArr] : []);
                setIsShowModalMessage(true);
                setData([]);
                setIsShowButton(false);
            } else if (res?.code === 0) {
                setMessage(res.msg ?? 'Cập nhật dữ liệu thất bại');
                setIsShowModalMessage(true);
            } 
            
        } else {
            setMessage('Có lỗi xảy ra. vui lòng thử lại');
            setIsShowModalMessage(true);
        }
    }
    const props = {
        name: 'file',
        accept: '.xlsx,.xls',
        beforeUpload: (file) => {
            console.log('beforeUpload', file)
            setLoadingImport(true);
            let reader = new FileReader();
            reader.onload = function (e) {
                let data = e.target.result;
                let readedData = XLSX.read(data, { type: 'binary' });
                const wsname = readedData.SheetNames[0];
                const ws = readedData.Sheets[wsname];

                /* Convert array to json*/
                const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
                console.log("dataParse", dataParse)
                if (dataParse && dataParse.length > 0) {
                    dataParse.shift();
                    setData([...dataParse]);
                    setIsShowButton(true);
                }
                setLoadingImport(false);
            };
            reader.readAsBinaryString(file);
            return false;
        },
    };
    return (

        <div>
            {
                contextHolder
            }
            <Row className="row-button">
                <Upload {...props} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', alignContent: 'center' }}>
                    <Button type="default" size="large" block danger icon={<ImportOutlined />} disabled={loadingImport} onClick={() => { }}
                        style={{ width: 150 }}
                    >
                        Import Excel
                    </Button>
                </Upload>
                {
                    isShowButton ?
                        <Button type="primary" size="large" defaultBg="#ff0000" block icon={<SaveOutlined />} disabled={loading} onClick={confirm}
                            style={{ width: 150 }}
                        >
                            Lưu thông tin
                        </Button> : null
                }

            </Row>
            {
                data.length > 0 ?
                    <p>Tổng: {data.length} sổ đỏ</p> : null
            }

            <Table className="table-book-import" columns={columns} dataSource={data} style={{ marginTop: 20 }} pagination={{pageSize: 10}}
                scroll={{
                    x: 1300
                }}/>
            <LoadingBase loading={loading} />
            <Modal
                open={isShowModalMessage}
                centered
                footer={[
                    <Button key="submit" type="primary" onClick={() => setIsShowModalMessage(false)}>
                        Đóng
                    </Button>
                ]}
                closable={false}
            >
                <div>
                    <p className="title-notify">
                        <InfoCircleOutlined style={{
                        color: 'blue',
                    }} /> Thông báo</p>

                    <p>{`${message}: ${totalSuccess}`}</p>
                    {
                        listFailed && listFailed.length > 0 ? 
                        <p>Các sổ chưa lưu được: 
                            {listFailed.map((item, index) => (
                            <p key={index}>{item}</p>
                        ))}</p> : null
                    }
                </div>
            </Modal>
        </div>
    )
}

export default React.memo(ImportExcelPage);
