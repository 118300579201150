import { Progress, Row, Tooltip } from "antd";
import React, { useEffect, useState } from "react";

const CustomProgress = ({ percent, volumeUsed, volumeTotal }) => {
    const getStrokeColor = (percent) => {
        if (percent < 70) {
            return '#2696db'; // Màu xanh
        } else if (percent < 90) {
            return {
                '0%': '#2696db',
                '70%': '#70c08a',
                '95%': '#ffde9f'
            };
        } else {
            return {
                '0%': '#2696db',
                '50%': '#70c08a',
                '70%': '#ffde9f',
                '90%': '#f24646',
                '100%': 'red',
            };
        }
    };
    return (
        <Tooltip placement="top" title={`${volumeUsed}/${volumeTotal}`}>
            <Progress
                percent={percent}
                strokeColor={getStrokeColor(percent)}
                format={(per, success) => percent + '%'} status={'active'}
            />
        </Tooltip>
    );
};

export default React.memo(CustomProgress);