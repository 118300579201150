import { ExclamationCircleOutlined, ImportOutlined, InfoCircleOutlined, SaveOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Row, Select, Table, Tooltip, Upload, message } from "antd";
import React, { useState } from "react";
import * as XLSX from 'xlsx';
import '../../css/importexcel-page.css';
import { getListWeighingBySoHD, importPhieuCanFromExcel, searchBookInfo, updateMutilWeighingSlip } from "../../services/apis";
import LoadingBase from "../../components/LoadingBase";
import LimitContentCell from "../../components/LimitContentCell";
import { formatNumber } from "../../utils/funcHelper";
import { useSelector } from "react-redux";

const AddMutilWeighingSlipToBook = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [isShowButton, setIsShowButton] = useState(false);
    const [modal, contextHolder] = Modal.useModal();
    const [loadingImport, setLoadingImport] = useState(false);
    const [mess, setMessage] = useState('');
    const [totalSuccess, setTotalSuccess] = useState();
    const [isShowModalMessage, setIsShowModalMessage] = useState(false);
    const [listFailed, setListFailed] = useState([]);
    const [lstBook, setLstBook] = useState([]);
    const [value, setValue] = useState();
    const [bookSelected, setBookSelected] = useState();
    const userReducer = useSelector(state => state?.userReducer);
    const columns = [
        {
            title: 'STT',
            key: 'index',
            width: 25,
            render: (text, record) => data.indexOf(record) + 1,
        },
        {
            title: 'Mã phiếu',
            dataIndex: 'SoHD',
            width: 150,
            key: 'SoHD',
            render: (text, record, index) => <LimitContentCell text={text} />
        },
        {
            title: 'BSX',
            dataIndex: 'BSX',
            key: 'BSX',
        },
        {
            title: 'Lái xe',
            dataIndex: 'Seller',
            key: 'Seller',
        },
        {
            title: 'KL Hàng',
            dataIndex: 'Net',
            key: '7',
        },
        {
            title: 'Ngày cân',
            dataIndex: 'SumDate',
            key: '8',
        },
        {
            title: 'Mã sổ',
            dataIndex: 'book_code',
            key: '9',
        }
        ,
        {
            title: 'Hành động',
            render: (txt, record, index) => <Button type="link" danger onClick={() => handleDelete(index)}>Xoá</Button>
        }
    ]

    const handleDelete = (index) => {
        console.log("data", data);
        const temp = [...data];
        temp.splice(index, 1);
        setData([...temp]);
    }
    const handleChangeSearch = (val) => {
        setValue(val);
    }
    const confirm = () => {
        if (!bookSelected) {
            message.warning("Bạn chưa chọn sổ để link");
            return;
        }
        if (data?.length <= 0) {
            message.warning("Không có phiếu cân nào để link");
            return;
        }
        modal.confirm({
            title: 'Xác nhận',
            icon: <ExclamationCircleOutlined />,
            content: 'Bạn có muốn link sổ cho các phiếu cân này không?',
            okText: 'Có',
            cancelText: 'Không',
            onOk: () => handleSaveInfo()
        });
    };
    const handleOnSelectBook = (item, option) => {
        console.log("option", option);
        setBookSelected(option);
    }
    const handleSaveInfo = async () => {
        const params = {
            idList: data.map(item => item.id),
            book_code: bookSelected?.book_code,
            book_id: bookSelected?.book_id,
            user_id: userReducer?.data?.id ? userReducer?.data?.id : '',
            reason_edit: 'Cập nhập mã sổ từ file excel'
        }
        setLoading(true);
        const res = await updateMutilWeighingSlip(params);
        setLoading(false);
        if (res && res?.status === 'success') {
            message.success("Cập nhật dữ liệu thành công");
            getListWeighing(data.map(item => item.SoHD));
        } else {
            message.error(res?.message ?? "Có lỗi xảy ra");
        }
    }
    const getListWeighing = async (arrSoHD) => {
        try {
            const params = {
                sohdList: arrSoHD
            }
            setLoading(true);
            const res = await getListWeighingBySoHD(params);
            setLoading(false);
            console.log("getListWeighingBySoHD", res);
            if (res && res?.status === 'success' && res?.data?.length > 0) {
                setIsShowButton(true);
                setData([...res?.data]);
                if (res?.notFoundSoHD?.length > 0) {
                    setMessage("Số phiếu cân tìm thấy");
                    setTotalSuccess(res?.data?.length);
                    setListFailed([...res?.notFoundSoHD]);
                    setIsShowModalMessage(true);
                }
            } else {
                setData([]);
                setIsShowButton(false);
                message.warning("Không tìm thấy phiếu cân trên hệ thống");
                if (res?.notFoundSoHD?.length > 0) {
                    setMessage("Số phiếu cân tìm thấy");
                    setTotalSuccess(res?.data?.length ?? 0);
                    setListFailed([...res?.notFoundSoHD]);
                    setIsShowModalMessage(true);
                }
            }
        } catch (error) {
            setLoading(false);
            setData([]);
        }

    }
    const props = {
        name: 'file',
        accept: '.xlsx,.xls',
        beforeUpload: (file) => {
            console.log('beforeUpload', file)
            setLoadingImport(true);
            let reader = new FileReader();
            reader.onload = function (e) {
                let data = e.target.result;
                let readedData = XLSX.read(data, { type: 'binary' });
                const wsname = readedData.SheetNames[0];
                const ws = readedData.Sheets[wsname];

                /* Convert array to json*/
                const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
                if (dataParse.length > 100) {
                    message.error("Danh sách import vượt quá 100 mã phiếu")
                    setLoadingImport(false);
                    return;
                } else if (dataParse.length > 0) {
                    getListWeighing(dataParse?.flat());
                } else {
                    message.error("File không có mã phiếu nào")
                }

                setLoadingImport(false);
            };
            reader.readAsBinaryString(file);
            return false;
        },
    };
    const onSearch = async (keyword) => {
        let params = {
            limit: 10,
            currentPage: 1,
            keyword: keyword ?? ''
        };
        const res = await searchBookInfo(params);
        if (res && res?.data) {
            setLstBook([...res.data?.map(item => ({
                ...item,
                value: item.book_code,
                label: renderLabel(item),
                child: `${item.book_owner_name} - ${item.book_license_plates}`
            }))]);
        } else {
            setLstBook([]);
        }
    }
    const renderLabel = (item) => {
        return (
            <Row>
                <Col span={3}>
                    <Tooltip title={item.book_code}><p>{item.book_code?.length > 15 ? `${item.book_code?.substring(0, 13)}...` : item.book_code}</p></Tooltip>
                </Col>
                <Col span={4}>
                    <p>{item.book_owner_name}</p>
                </Col>
                <Col span={3}>
                    <p>{item.cmnd_book_owner}</p>
                </Col>
                <Col span={6}>
                    <p>{item?.book_license_plates ?? ''}</p>
                </Col>
                <Col span={2}>
                    <strong>{item?.khoiluong_conlai ? formatNumber(parseInt(item?.khoiluong_conlai), 0) : ''}</strong>
                </Col>
                <Col span={6}>
                    <Tooltip title={item?.book_address ?? ''}><p>{item?.book_address ?? ''}</p></Tooltip>
                </Col>
            </Row>
        )
    }
    const renderDropdown = (menu) => {

        return (
            <>
                <Row>
                    <Col span={3}>
                        <p className="text-title-col pl-10">Mã sổ</p>
                    </Col>
                    <Col span={4}>
                        <p className="text-title-col pl-10">Chủ sổ</p>
                    </Col>
                    <Col span={3}>
                        <p className="text-title-col">CMND</p>
                    </Col>
                    <Col span={6}>
                        <p className="text-title-col">BSX</p>
                    </Col>
                    <Col span={2}>
                        <p className="text-title-col">KL còn</p>
                    </Col>
                    <Col span={6}>
                        <p className="text-title-col">Địa chỉ sổ</p>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <div className="line-container"></div>
                    </Col>
                </Row>
                {menu}
            </>

        )
    }
    return (

        <div>
            {
                contextHolder
            }
            <Row className="row-button">
                <Upload {...props} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', alignContent: 'center' }}>
                    <Button type="default" size="large" block danger icon={<ImportOutlined />} disabled={loadingImport} onClick={() => { }}
                        style={{ width: 150 }}
                    >
                        Import Excel
                    </Button>
                </Upload>

                {
                    isShowButton ?
                        <>
                            <Select
                                showSearch={true}
                                value={value}
                                placeholder={'Nhập mã sổ đỏ/ Tên chủ sổ/ CMND chủ/ BSX'}
                                style={{ width: 700, height: 40 }}
                                optionLabelProp="child"
                                popupMatchSelectWidth={900}
                                defaultActiveFirstOption={false}
                                dropdownRender={renderDropdown}
                                suffixIcon={<SearchOutlined />}
                                filterOption={false}
                                onSearch={onSearch}
                                onChange={handleChangeSearch}
                                notFoundContent={'Không có dữ liệu'}
                                options={lstBook}
                                onSelect={handleOnSelectBook}
                            />
                            <Button 
                                type="primary" 
                                size="large"
                                block icon={<SaveOutlined />} 
                                disabled={loading} 
                                onClick={confirm}
                                style={{ width: 150 }}
                            >
                                Lưu thông tin
                            </Button>
                        </>
                        : null
                }

            </Row>
            {
                data.length > 0 ?
                    <div>Tổng: {data.length} mã phiếu</div> : null
            }

            <Table
                className="table-book-import"
                columns={columns}
                dataSource={data}
                style={{ marginTop: 20 }}
                pagination={false} 
                rowKey={(item) => item.id?.toString()}
            />
            <LoadingBase loading={loading} />
            <Modal
                open={isShowModalMessage}
                centered
                footer={[
                    <Button key="submit" type="primary" onClick={() => setIsShowModalMessage(false)}>
                        Đóng
                    </Button>
                ]}
                closable={false}
            >
                <div>
                    <p className="title-notify">
                        <InfoCircleOutlined style={{
                            color: 'blue',
                        }} /> Thông báo</p>

                    <span>{`${mess}: ${totalSuccess}`}</span>
                    {
                        listFailed && listFailed.length > 0 ?
                        <>
                        <p>Các mã phiếu không tìm thấy:</p>
                                {listFailed.map((item, index) => (
                                    <p key={index}>{item}</p>
                                ))}
                        </>
                             : null
                    }
                </div>
            </Modal>
        </div>
    )
}

export default React.memo(AddMutilWeighingSlipToBook);
