import React, { forwardRef } from "react";
import '../../css/print-page.css';
import { Col, Row } from "antd";
import QRCode from "react-qr-code";
import { useSelector } from "react-redux";
import moment from "moment";
import { capitalizeFirstLetter } from "../../utils/funcHelper";
import RenderDataToPrintWeight from "./RenderDataToPrintWeight";

const InPhieuCanPage = ({props}, ref) => {
    const printReducer = useSelector(state => state?.printReducer);
    const userReducer = useSelector(state => state?.userReducer);
    console.log("printReducer",printReducer)
    const data = printReducer?.data;
    return (
        <div ref={ref}>
            <RenderDataToPrintWeight type={"Liên 1: Lưu"}/>
            <RenderDataToPrintWeight type={"Liên 2: Lái xe giữ"}/>
            <RenderDataToPrintWeight type={"Liên 3: Ngân hàng giữ"}/>
        </div>
    )
}

export default forwardRef(InPhieuCanPage);