import {
    UnorderedListOutlined,
    LogoutOutlined,
    DashboardOutlined,
    PlusCircleOutlined,
    MenuFoldOutlined,
    FileDoneOutlined,
    FileSearchOutlined,
    ExceptionOutlined,
    FileTextOutlined,
    ImportOutlined,
    TeamOutlined,
    BarChartOutlined,
    TagOutlined,
    UserOutlined,
    RollbackOutlined,
    BookOutlined,
    ProjectOutlined,
    RestOutlined,
    SaveOutlined
} from '@ant-design/icons';

export const DATE_FORMAT = {
    DDMMYYYY_FORMAT: "DD/MM/YYYY",
    YYYYMMDD_FORMAT: "YYYY-MM-DD",
    DDMM_FORMAT: "DD/MM"
}

export const LIST_MENU_SIDE_BAR = [
    {
        key: 'dashboard',
        path: "/dashboard",
        label: 'Dashboard',
        icon: <BarChartOutlined />,
        permission: [1, 2, 3, 4]
    },
    {
        key: 'phieu-can',
        path: "/phieu-can",
        label: 'Phiếu cân',
        icon: <DashboardOutlined />,
        permission: [1, 2, 3, 4]
    },
    {
        label: 'Quản lý sổ đỏ',
        icon: <BookOutlined />,
        children: [
            {
                key: 'them-moi',
                path: "/them-moi",
                label: 'Thêm mới sổ',
                icon: <PlusCircleOutlined />,
                permission: [1, 2, 3, 4]
            },
            {
                key: 'import-excel',
                path: "/import-excel",
                label: 'Import sổ',
                icon: <ImportOutlined />,
                permission: [1, 2, 3]
            },
            {
                key: 'danh-sach',
                path: "/danh-sach",
                label: 'Danh sách sổ',
                icon: <UnorderedListOutlined />,
                permission: [1, 2, 3, 4]
            },
            {
                key: 'duyet-so',
                path: "/duyet-so",
                label: 'Duyệt sổ',
                icon: <FileDoneOutlined />,
                permission: [1, 2]
            },
            // { 
            //     key: 'import-phieu-can',
            //     path: "/import-phieu-can",
            //     label: 'Import phiếu cân',
            //     icon: <ImportOutlined />
            // },
        ]
    },
    // {
    //     key: 'tra-coc',
    //     path: "/tra-coc",
    //     label: 'Trả cọc',
    //     icon: <RollbackOutlined />,
    //     permission: [1, 4]
    // },
    {
        key: 'link-nhieu-phieu',
        path: "/link-nhieu-phieu",
        label: 'Link nhiều phiếu',
        icon: <RollbackOutlined />,
        permission: [1, 4]
    },
    {
        label: 'Báo cáo',
        icon: <ProjectOutlined />,
        children: [
            {
                key: 'bao-cao-so-do',
                path: "/bao-cao-so-do",
                label: 'Báo cáo sổ đỏ',
                icon: <FileSearchOutlined />,
                permission: [1, 2, 3, 4]
            },
            {
                key: 'canh-bao-so-do',
                path: "/canh-bao-so-do",
                label: 'Cảnh báo sổ đỏ',
                icon: <ExceptionOutlined />,
                permission: [1, 2, 3, 4]
            },
            {
                key: 'bao-cao-weighing-book',
                path: "/bao-cao-weighing-book",
                label: 'BC phiếu cân theo sổ',
                icon: <ExceptionOutlined />,
                permission: [1, 2, 3, 4]
            },
            {
                key: 'bao-cao-phieu-can',
                path: "/bao-cao-phieu-can",
                label: 'BC phiếu cân',
                icon: <FileTextOutlined />,
                permission: [1, 2, 3, 4]
            },
            {
                key: 'bao-cao-dat-coc',
                path: "/bao-cao-dat-coc",
                label: 'BC phiếu cọc',
                icon: <FileTextOutlined />,
                permission: [1, 2, 3, 4]
            },
            {
                key: 'bao-cao-phieu-chi',
                path: "/bao-cao-phieu-chi",
                label: 'BC phiếu chi',
                icon: <FileTextOutlined />,
                permission: [1, 2, 3, 4]
            }
        ]
    },
    {
        label: 'Danh mục',
        icon: <MenuFoldOutlined />,
        children: [
            {
                key: 'phong-ban',
                path: "/phong-ban",
                label: 'Phòng ban',
                icon: <FileSearchOutlined />,
                permission: [1]
            },
            {
                key: 'loai-so',
                path: "/loai-so",
                label: 'Loại sổ',
                icon: <ExceptionOutlined />,
                permission: [1]
            },
            {
                key: 'loai-hang',
                path: "/loai-hang",
                label: 'Loại Hàng',
                icon: <TagOutlined />,
                permission: [1]
            }
            ,
            {
                key: 'lai-xe',
                path: "/lai-xe",
                label: 'Lái xe',
                icon: <UserOutlined />,
                permission: [1, 4]
            }
        ]
    },
    {
        key: 'phan-quyen',
        path: "/phan-quyen",
        label: 'Phân quyền',
        icon: <TeamOutlined />,
        permission: [1]
    },
    {
        key: 'doi-mat-khau',
        path: "/doi-mat-khau",
        label: 'Đổi mật khẩu',
        icon: <RestOutlined />,
        permission: [1, 2, 3, 4]
    },
    {
        key: 'sao-luu-du-lieu',
        path: "/sao-luu-du-lieu",
        label: 'Sao lưu dữ liệu',
        icon: <SaveOutlined />,
        permission: [1]
    },
    {
        key: 'dang-xuat',
        path: "/dang-xuat",
        label: 'Đăng xuất',
        icon: <LogoutOutlined />,
        permission: [1, 2, 3, 4]
    },

];