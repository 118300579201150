import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row, Space, theme, Table, message, Upload, Modal } from 'antd';
import LoadingBase from '../../components/LoadingBase';
import { addNewDriverInfo, addWoodType, getDriversInfo, getListWoodType, importDriverFromExcel, importWoodsTypeFromExcel, updateDriverInfoById, updateWoodType } from '../../services/apis';
import '../../css/listbook-page.css';
import AddEditWoodsModal from '../../components/AddEditWoodsModal';
import { formatNumber } from '../../utils/funcHelper';
import { parseInt } from 'lodash';
import { ExclamationCircleOutlined, ImportOutlined, InfoCircleOutlined, SaveOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';
import AddEditDriverModal from '../../components/AddEditDriverModal';
import { useSelector } from 'react-redux';
import FooterTable from '../../components/FooterTable';

const DriversInfoPage = () => {
    const useReducer = useSelector(state => state?.userReducer);
    console.log("userReducer",useReducer)
    const { token } = theme.useToken();
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [type, setType] = useState();
    const [itemSelected, setItemSelected] = useState();
    const [messageImport, setMessageImport] = useState('');
    const [totalSuccess, setTotalSuccess] = useState();
    const [isShowModalMessage, setIsShowModalMessage] = useState(false);
    const [listFailed, setListFailed] = useState([]);
    const [isShowButton, setIsShowButton] = useState(false);
    const [modal, contextHolder] = Modal.useModal();
    const [loadingImport, setLoadingImport] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const columns = [
        {
            title: 'Mã',
            dataIndex: 'id',
            key: 'id',
            width: 150,
            align: 'center',
        },
        {
            title: 'Chủ xe',
            dataIndex: 'fullname_owner'
        },
        {
            title: 'Tên lái xe',
            dataIndex: 'fullname'
        },
        {
            title: 'CMND',
            dataIndex: 'cmnd'
        },
        {
            title: 'Ngày cấp',
            dataIndex: 'ngaycap'
        },
        {
            title: 'Nơi cấp',
            dataIndex: 'noicap'
        },
        {
            title: 'Địa chỉ',
            dataIndex: 'diachi'
        },
        {
            title: 'Số điện thoại',
            dataIndex: 'phone'
        },
        {
            title: 'BSX',
            dataIndex: 'bsx'
        },
        {
            title: 'Hành động',
            render: (text, record, index) => <Button type='link' onClick={() => handleEdit(record)}>Sửa</Button>
        },
    ];
    const formStyle = {
        maxWidth: 'none',
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
    };
    const confirm = () => {
        modal.confirm({
            title: 'Xác nhận',
            icon: <ExclamationCircleOutlined />,
            content: 'Bạn có muốn lưu thông tin lái xe không?',
            okText: 'Có',
            cancelText: 'Không',
            onOk: () => handleSaveInfo()
        });
    };
    const handleSaveInfo = async () => {
        setLoading(true);
        debugger
        const res = await importDriverFromExcel(data);
        setLoading(false);
        console.log("ressss", res);
        if (res) {
            setTotalSuccess(res?.totalSuccess ?? 0);
            if (res?.code === 1) {
                setMessageImport(res.msg ?? 'Cập nhật dữ liệu thành công');
                setIsShowModalMessage(true);
                setData([]);
                setIsShowButton(false);
            } else if (res?.code === 2) {
                setMessageImport(res.msg ?? 'Cập nhật dữ liệu thành công');
                setListFailed(res.failedArr && res.failedArr.length > 0 ? [...res.failedArr] : []);
                setIsShowModalMessage(true);
                setData([]);
                setIsShowButton(false);
            } else if (res?.code === 0) {
                setMessageImport(res.msg ?? 'Cập nhật dữ liệu thất bại');
                setIsShowModalMessage(true);
            } 
            
        } else {
            setMessageImport('Có lỗi xảy ra. vui lòng thử lại');
            setIsShowModalMessage(true);
        }
    }
    const props = {
        name: 'file',
        accept: '.xlsx,.xls',
        beforeUpload: (file) => {
            console.log('beforeUpload', file)
            setLoadingImport(true);
            let reader = new FileReader();
            reader.onload = function (e) {
                let data = e.target.result;
                let readedData = XLSX.read(data, { type: 'binary' });
                const wsname = readedData.SheetNames[0];
                const ws = readedData.Sheets[wsname];

                /* Convert array to json*/
                const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
                console.log("dataParse", dataParse)
                if (dataParse && dataParse.length > 0) {
                    dataParse.shift();
                    setData([...dataParse]);
                    setIsShowButton(true);
                }
                setLoadingImport(false);
            };
            reader.readAsBinaryString(file);
            return false;
        },
    };
    const handleNext = () => {
        if (loading) {
            return;
        }
        const page = currentPage;
        setCurrentPage(page + 1);
        getDataDriverInfo(page + 1);
    }

    const handlePrev = () => {
        if (loading) {
            return;
        }
        const page = currentPage;
        setCurrentPage(page - 1);
        getDataDriverInfo(page - 1);
    }
    const handleOnSelect = (value) => {
        setCurrentPage(value);
        getDataDriverInfo(value)
    }
    const handleEdit = (data) => {
        setItemSelected(data);
        setType('Edit');
        setShowModal(true);
    }

    const handleAddEditData = async (data) => {
        if (!data) {
            return;
        }
        if (type === 'Add') {
            setShowModal(false);
            const params = {
                fullname: data?.fullname,
                cmnd: data?.cmnd,
                ngaycap: data?.ngaycap,
                noicap: data?.noicap,
                diachi: data?.diachi ?? '',
                phone: data?.phone ?? '',
                bsx: data?.bsx ?? '',
                userId: useReducer?.data?.id ?? ''
            };
            setLoading(true);
            const res = await addNewDriverInfo(params);
            setLoading(false);
            if (res && res.status === 1) {
                message.success("Thêm mới thành công", 5);
                getDataDriverInfo();
            } else {
                message.error(res?.msg ?? 'Có lỗi xảy ra', 5);
            }
        } else {
            setShowModal(false);
            const params = {
                fullname: data?.fullname,
                cmnd: data?.cmnd,
                ngaycap: data?.ngaycap,
                noicap: data?.noicap,
                diachi: data?.diachi ?? '',
                phone: data?.phone ?? '',
                bsx: data?.bsx ?? '',
                userId: useReducer?.data?.id ?? '',
                id: itemSelected?.id
            };
            setLoading(true);
            const res = await updateDriverInfoById(params);
            setLoading(false);
            if (res && res.status === "success") {
                message.success("Chỉnh sửa thành công", 5);
                getDataDriverInfo();
            } else {
                message.error(res?.msg ?? 'Có lỗi xảy ra', 5);
            }
        }
    }
    const handleCancel = () => {
        setShowModal(false);
    }
    const getDataDriverInfo = async (currentPage) => {
        const params = {
            keyword: form.getFieldValue("keyword") ?? '',
            limit: 50,
            currentPage: currentPage ?? 1
        }
        setLoading(true);
        const res = await getDriversInfo(params);
        setLoading(false);
        if (res && res?.status === 'success') {
            setData(res?.data ? [...res.data] : []);
            setTotalPage(res?.totalPages);
        } else {
            setData([]);
            setTotalPage(0);
        }
    }
    
    const onFinish = async (data) => {
        getDataDriverInfo(1);
    };
    useEffect(() => {
        getDataDriverInfo(1);
    }, [])
    return (
        <div className='container-content'>
            {
                contextHolder
            }
            {/* <Row className="row-button">
                <Upload {...props} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', alignContent: 'center' }}>
                    <Button type="default" size="large" block danger icon={<ImportOutlined />} disabled={loadingImport} onClick={() => { }}
                        style={{ width: 150 }}
                    >
                        Import Excel
                    </Button>
                </Upload>
                {
                    isShowButton ?
                        <Button type="primary" size="large" defaultBg="#ff0000" block icon={<SaveOutlined />} disabled={loading} onClick={confirm}
                            style={{ width: 150 }}
                        >
                            Lưu thông tin
                        </Button> : null
                }

            </Row> */}
            <Form form={form} name="advanced_search" style={formStyle} onFinish={onFinish}>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            name={'keyword'}
                        >
                            <Input placeholder={'Tên lái xe/ CMND/ SĐT'} />
                        </Form.Item>
                    </Col>
                    
                    
                    <Col span={4}>
                        <Space size="small">
                            <Button type="primary" htmlType="submit">
                                Tìm kiếm
                            </Button>
                            <Button
                                onClick={() => {
                                    setItemSelected(null);
                                    setType('Add');
                                    setShowModal(true);
                                    
                                }}
                            >
                                Thêm mới
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Table
                className='table-book'
                columns={columns}
                dataSource={data}
                pagination={false}
            />
            <FooterTable
                totalRecord={data?.length}
                totalPage={totalPage}
                currentPage={currentPage}
                handleNext={handleNext}
                handlePrev={handlePrev}
                onSelect={handleOnSelect}
            >
                
            </FooterTable>
            <AddEditDriverModal visible={showModal} onCancel={handleCancel} onSave={handleAddEditData} title={"Thêm mới lái xe"} data={itemSelected} type={type}/>
            <Modal
                open={isShowModalMessage}
                centered
                footer={[
                    <Button key="submit" type="primary" onClick={() => setIsShowModalMessage(false)}>
                        Đóng
                    </Button>
                ]}
                closable={false}
            >
                <div>
                    <p className="title-notify">
                        <InfoCircleOutlined style={{
                        color: 'blue',
                    }} /> Thông báo</p>

                    <p>{`${messageImport}: ${totalSuccess}`}</p>
                    {
                        listFailed && listFailed.length > 0 ? 
                        <p>Các sổ chưa lưu được: 
                            {listFailed.map((item, index) => (
                            <p key={index}>{item}</p>
                        ))}</p> : null
                    }
                </div>
            </Modal>
            <LoadingBase loading={loading} />
        </div>

    );
};
export default DriversInfoPage;