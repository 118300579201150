import React, { useEffect, useState } from 'react';
import '../../../css/addbook-page.css';
import { Button, Checkbox, Col, DatePicker, Divider, Form, Image, Input, InputNumber, Modal, Row, Select, Space, Upload, message } from 'antd';
import { UploadOutlined, ArrowLeftOutlined, DeleteOutlined } from '@ant-design/icons';
import { DATE_FORMAT } from '../../../constants/constants';
import { BASE_URL } from '../../../settings/config';
import { getBase64 } from '../../../utils/funcHelper';
import { approveBookById, deleteFileUpload, getAllUsers, getBookDetail, getBookTypes, getListDepartment, getListStatus, getListUserManager, updateBookById } from '../../../services/apis';
import moment from 'moment';
import LoadingBase from '../../../components/LoadingBase';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';

const ApproveBookDetail = () => {
    const userReducer = useSelector(state => state?.userReducer);
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [listFile, setListFile] = useState([]);
    const [lstBookType, setLstBookType] = useState([]);
    const [lstDepartment, setLstDepartment] = useState([]);
    const [lstUser, setLstUser] = useState([]);
    const [lstStatus, setLstStatus] = useState([]);
    const [dataBook, setDataBook] = useState();
    const [lstUsers, setLstUsers] = useState([]);
    const [lstAttachOld, setLstAttachOld] = useState([]);
    const [disableField, setDisableField] = useState(false);
    const { id } = useParams();
    const getDataDetailBook = async () => {
        const params = {
            bookId: id
        };
        const res = await getBookDetail(params);
        console.log("getBookDetail", res);
        if (res && res?.status === "success") {
            message.success("Lấy dữ liệu thành công", 3);
            if (res?.data && res?.data?.length > 0) {

                setDataBook(res.data[0]);
            } else {
                setDataBook(null);
            }
        } else {
            message.error(res?.msg ?? 'Lấy dữ liệu thất bại', 3);
        }
    }
    const handleCallMultiAPIs = () => {
        setLoading(true);
        const arrAPI = [getListBookType(), getListDepart(), getUserManager(), getDataStatus(), getLstUsers()];
        Promise.all(arrAPI)
            .then(res => { getDataDetailBook(); })
            .finally(() => setLoading(false))
            .catch(err => setLoading(false));
    }
    const getLstUsers = async () => {
        const res = await getAllUsers();
        if (res && res?.status === 'success') {
            setLstUsers(res?.data ? [...res.data] : []);
        }
    }
    const getListBookType = async () => {
        const res = await getBookTypes();
        if (res && res?.status === 'success') {
            setLstBookType(res?.data ? [...res.data] : []);
        }
    }
    const getListDepart = async () => {
        const res = await getListDepartment();
        if (res && res?.status === 'success') {
            console.log("setLstDepartment",res.data);
            setLstDepartment(res?.data ? [...res.data] : []);
        }
    }
    const getUserManager = async () => {
        const res = await getListUserManager();
        if (res && res?.status === 'success') {
            setLstUser(res?.data ? [...res.data] : []);
        }
    }
    
    const getDataStatus = async () => {
        const res = await getListStatus();
        if (res && res?.status === 'success') {
            setLstStatus(res?.data ? [...res.data] : []);
        }
    }
    const deleteFileAttach = async (name) => {
        const params = {
            filename: name
        };
        await deleteFileUpload(params);
    }
    const submit = async (data) => {
        try {
            const params = {...data};
            params.isCheck = data.isCheck ? 1 : 0;
            params.bookId = parseInt(dataBook?.book_id);
            params.bookDate = data.bookDate ? moment(data.bookDate).format(DATE_FORMAT.YYYYMMDD_FORMAT) : null;
            params.cmndDateCoOwner = data.cmndDateCoOwner ? moment(data.cmndDateCoOwner).format(DATE_FORMAT.YYYYMMDD_FORMAT) : null;
            params.cmndDateOwner = data.cmndDateOwner ? moment(data.cmndDateOwner).format(DATE_FORMAT.YYYYMMDD_FORMAT) : null;
            params.landExpireDate = data.landExpireDate ? moment(data.landExpireDate).format(DATE_FORMAT.YYYYMMDD_FORMAT) : null;
            params.bookStatusCode = 2;
            const attachNew = listFile.filter(file => file?.response?.status === 1).map(item => ({
                file_path: item.response.image_path
            }));
            params.attachments = [...attachNew, ...lstAttachOld];
            setLoading(true);
            const res = await approveBookById(params);
            setLoading(false);
            if (res && res.status === 1) {
                message.success('Duyệt sổ thành công', 4);
            } else {
                message.error(res?.msg ?? 'Có lỗi xảy ra. Vui lòng thử lại', 5);
            }
            setDisableField(true);

        } catch (error) {
            setLoading(false);
            console.log("error", error);
            setDisableField(true);
        }

    }
    const beforeUpload = (file) => {
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
            message.error('Ảnh phải có kích thước nhỏ hơn 5mb!');
            return false;
        }
        return isLt5M;
    };
    const handleCancel = () => setPreviewOpen(false);
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };
    const props = {
        action: BASE_URL + 'api/upload',
        onChange(info) {
            const temp = [...listFile];
            const file = info?.file;
            const checkIndex = temp.findIndex(obj => obj.uid === file?.uid);
            if (file?.status) {
                if (file?.status !== 'removed') {
                    if (checkIndex > -1) {
                        if (file.status !== 'done') {
                            temp[checkIndex] = file;
                            setListFile([...temp]);
                        } else {
                            if (file?.response && file.response?.status === 1) {
                                temp[checkIndex] = file;
                                setListFile([...temp]);
                                message.success(`${info.file.name} đã được upload thành công`);
                            } else {
                                temp[checkIndex].status = 'error';
                                setListFile([...temp]);
                                message.error(file?.response?.msg ?? 'Upload ảnh thất bại');
                            }
                        }

                    } else {
                        if (file?.status) {
                            const newList = [...listFile];
                            newList.push(file);
                            setListFile([...newList]);
                        }
                    }
                } else {
                    if (checkIndex > -1) {
                        temp.splice(checkIndex, 1);
                        setListFile([...temp]);
                        deleteFileAttach(file?.response?.image_path);
                    }
                }
            }

            if (info.file.status === 'error') {
                message.error(`${info.file.name} Upload ảnh thất bại`);
            }
        },

    };
    const deleteLstAttachOld = (index) => {
        const temp = lstAttachOld;
        temp.splice(index, 1);
        setLstAttachOld([...temp]);
    }
    const onResetFeilds = () => {
        setListFile([]);
    }
    const handleFailed = (err) => {
        console.log("errr", err);
        message.error("Vui lòng không để trống thông tin");
    }
    const handleChangeKLDauKy = (value) => {
        const rs = form.getFieldValue('volumeTotal') ? form.getFieldValue('volumeTotal') - value : -value;
        form.setFieldsValue({
            remainingWeight: rs
        })
    }
    const handleOnChangeDienTichTrong = (value) => {
        const result = value * form.getFieldValue('ratio');
        form.setFieldsValue({
            volumeTotal: result,
        });

    }
    const handleOnChangeRatio = (value) => {
        const result = value * form.getFieldValue('woodAcreage');
        form.setFieldsValue({
            volumeTotal: result,
        });
    }
    
    useEffect(() => {
        handleCallMultiAPIs();
    }, [])

    useEffect(() => {
        console.log("dataBook",dataBook)
        if (dataBook) {
            form.setFieldsValue({
                fullnameOwner: dataBook?.book_owner_name ?? '',
                cmndOwner: dataBook?.cmnd_book_owner ?? '',
                cmndDateOwner: dataBook?.cmnd_date_owner ? dayjs(dataBook?.cmnd_date_owner) : null,
                cmndPlaceOwner: dataBook?.cmnd_place_owner ?? '',
                addressOwner: dataBook?.book_owner_address ?? '',
                fullnameOwner2: dataBook?.book_owner_name2 ?? '',
                cmndOwner2: dataBook?.cmnd_book_owner2 ?? '',
                cmndDateOwner2: dataBook?.cmnd_date_owner2 ? dayjs(dataBook?.cmnd_date_owner2) : null,
                cmndPlaceOwner2: dataBook?.cmnd_place_owner2 ?? '',
                addressOwner2: dataBook?.book_owner_address2 ?? '',
                bookCode: dataBook?.book_code ?? '',
                bookTypeId: dataBook?.book_typeId ? parseInt(dataBook?.book_typeId) : '',
                bookDate: dataBook?.book_date ? dayjs(dataBook?.book_date) : null,
                landExpireDate: dataBook?.expiry_date ? dayjs(dataBook?.expiry_date) : null,
                useLand: dataBook?.use_land ?? '',
                bookAcreage: dataBook?.book_acreage ? parseInt(dataBook?.book_acreage) : null,
                woodAcreage: dataBook?.wood_acreage ? parseInt(dataBook?.wood_acreage) : null,
                ratio: dataBook?.ratio ? parseFloat(dataBook?.ratio) : null,
                volumeTotal: dataBook?.volume_total ? parseInt(dataBook?.volume_total) : null,
                volumeDauky: dataBook?.volume_dauky ? parseInt(dataBook.volume_dauky) : null,
                remainingWeight: (dataBook?.volume_total ? parseInt(dataBook?.volume_total) : 0) - (dataBook?.volume_dauky ? parseInt(dataBook.volume_dauky) : 0),
                bookDepartmentId: dataBook?.departmentId ?? null,
                bookLicensePlates: dataBook?.book_license_plates ?? '',
                bookAddress: dataBook?.book_address ?? '',
                note: dataBook?.note ?? '',
                fullnameCoOwner: dataBook?.co_owner_name ?? '',
                cmndCoOwner: dataBook?.cmnd_co_owner ?? '',
                cmndDateCoOwner: dataBook?.cmnd_date_co_owner ? dayjs(dataBook?.cmnd_date_co_owner) : null,
                cmndPlaceCoOwner: dataBook?.cmnd_place_co_owner ?? '',
                bookStatusCode: dataBook?.statusId ?? '',
                createById: dataBook?.created_by_user ?? null,
                approveById: dataBook?.approved_by_user ? dataBook?.approved_by_user : null,
                isCheck: dataBook.ischeck === "1" ? true : false
            });
            setLstAttachOld(dataBook?.attachments ? [...dataBook?.attachments?.map(file => ({
                file_path: file.file_path
            }))] : []);
            setDisableField(dataBook?.statusId === '1' ? false : true);
        }
    }, [dataBook])

    return (
        <div className='container-content'>
            <Button type='link' style={{ fontSize: 18 }} onClick={() => { navigate(-1) }}> <ArrowLeftOutlined /> Quay lại </Button>
            <LoadingBase loading={loading} />
            <Form
                form={form}
                name='add-book'
                onFinish={submit}
                layout={'vertical'}
                onReset={onResetFeilds}
                disabled={disableField}
                onFinishFailed={handleFailed}
            >
                <Divider orientation="left" style={{marginTop: 0}}><span className='title-divider'>Thông tin chủ rừng</span></Divider>
                <Row gutter={16}>
                    <Col span={5}>
                        <Form.Item label="Họ tên thứ nhất" name="fullnameOwner" rules={[{ required: true, message: 'Vui lòng nhập tên!' }]}>
                            <Input placeholder='Họ tên thứ nhất' />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="CMND thứ nhất" name="cmndOwner">
                            <Input placeholder='CMND thứ nhất' />
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item label="Ngày cấp 1" name="cmndDateOwner">
                            <DatePicker placeholder='DD/MM/YYYY' className='date-picker' format={DATE_FORMAT.DDMMYYYY_FORMAT} />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="Nơi cấp 1" name="cmndPlaceOwner">
                            <Input placeholder='Nơi cấp 1' />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Địa chỉ 1" name="addressOwner" rules={[{ required: true, message: 'Vui lòng nhập địa chỉ!' }]}>
                            <Input placeholder='Địa chỉ 1' />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={5}>
                        <Form.Item label="Họ tên thứ 2" name="fullnameOwner2">
                            <Input placeholder='Họ tên thứ 2' />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="CMND thứ 2" name="cmndOwner2">
                            <Input placeholder='CMND thứ 2' />
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item label="Ngày cấp 2" name="cmndDateOwner2">
                            <DatePicker placeholder='DD/MM/YYYY' className='date-picker' format={DATE_FORMAT.DDMMYYYY_FORMAT} />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="Nơi cấp 2" name="cmndPlaceOwner2">
                            <Input placeholder='Nơi cấp 2' />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Địa chỉ 2" name="addressOwner2">
                            <Input placeholder='Địa chỉ 2' />
                        </Form.Item>
                    </Col>
                </Row>
                <Divider orientation="left" style={{marginTop: 0}}><span className='title-divider'>Thông tin sổ đỏ</span></Divider>
                <Row gutter={16}>
                    <Col span={4}>
                        <Form.Item label="Mã sổ" name="bookCode" rules={[{ required: true, message: 'Vui lòng nhập mã sổ' }]}>
                            <Input placeholder='Nhập mã sổ' />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item label="Loại sổ" name="bookTypeId">
                            <Select
                                showSearch
                                className='custom-select'
                                placeholder="Chọn loại sổ"
                                optionFilterProp="children"
                                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                options={lstBookType.map(type => ({
                                    ...type,
                                    value: parseInt(type?.id),
                                    label: type.type_name
                                }))}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item label="Ngày cấp" name="bookDate">
                            <DatePicker placeholder='DD/MM/YYYY' className='date-picker' format={DATE_FORMAT.DDMMYYYY_FORMAT} />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item label="Thời hạn sử dụng đất" name="landExpireDate">
                            <DatePicker placeholder='DD/MM/YYYY' className='date-picker' format={DATE_FORMAT.DDMMYYYY_FORMAT} />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item label="Mục đích sử dụng" name="useLand">
                            <Input placeholder='Mục đích sử dụng đất' />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={4}>
                        <Form.Item label="Diện tích sổ (m2)" name="bookAcreage" rules={[{ required: true, message: 'Vui lòng nhập diện tích sổ' }]}>
                            <InputNumber
                                placeholder={'0'}
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                className="input-number-base"
                                min={0}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="Diện tích trồng (m2)" name="woodAcreage" rules={[{ required: true, message: 'Vui lòng nhập diện tích' }]}>
                            <InputNumber
                                placeholder={'0'}
                                onChange={(value) => handleOnChangeDienTichTrong(value)}
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                className="input-number-base"
                                min={0}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="Quy đổi(m2->Tấn)" name="ratio" rules={[{ required: true, message: 'Vui lòng nhập quy đổi' }]}>
                            <InputNumber
                                placeholder={'0'}
                                onChange={(value) => handleOnChangeRatio(value)}
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                className="input-number-base"
                                min={0}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="KL được khai thác(Tấn)" name="volumeTotal" rules={[{ required: true, message: 'Vui lòng nhập tổng khai thác' }]}>
                            <InputNumber
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                className="input-number-base"
                                disabled
                                min={0}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="KL đầu kỳ (Tấn)" name="volumeDauky" rules={[{ required: true, message: 'Vui lòng số sử dụng đầu kỳ' }]}>
                            <InputNumber
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                className="input-number-base"
                                onChange={handleChangeKLDauKy}
                                min={0}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="KL còn lại (Tấn)" name="remainingWeight">
                            <InputNumber
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                className="input-number-base"
                                disabled
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={7}>
                        <Form.Item label="Đơn vị quản lý" name="bookDepartmentId">
                            <Select
                                showSearch
                                className='custom-select'
                                placeholder="Chọn đơn vị"
                                optionFilterProp="children"
                                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                options={lstDepartment.map(item => ({
                                    ...item,
                                    value: item?.id,
                                    label: item.name
                                }))}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item label="Biển số xe" name="bookLicensePlates">
                            <Input placeholder='...' />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Địa chỉ" name="bookAddress">
                            <Input placeholder='Địa chỉ sổ' />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Ghi chú" name="note">
                            <Input placeholder='Ghi chú' />
                        </Form.Item>
                    </Col>
                </Row>
                <Divider orientation="left" style={{marginTop: 0}}><span className='title-divider'>Thông tin đồng sở hữu</span></Divider>
                <Row gutter={16}>
                    <Col span={6}>
                        <Form.Item label="Họ tên thứ hai" name="fullnameCoOwner">
                            <Input placeholder='Nhập họ tên đồng sở hữu' />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Số CMND thứ 2" name="cmndCoOwner">
                            <Input placeholder='CMND chủ rừng' />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Ngày cấp" name="cmndDateCoOwner">
                            <DatePicker placeholder='DD/MM/YYYY' className='date-picker' format={DATE_FORMAT.DDMMYYYY_FORMAT} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Nơi cấp" name="cmndPlaceCoOwner">
                            <Input placeholder='Nơi cấp' />
                        </Form.Item>
                    </Col>
                </Row>
                <Divider orientation="left" style={{marginTop: 0}}><span className='title-divider'>Trạng thái hồ sơ</span></Divider>
                <Row gutter={16}>
                    <Col span={6}>
                        <Form.Item label="Trạng thái sổ" name="bookStatusCode" rules={[{ required: true, message: 'Vui lòng chọn trạng thái' }]}>
                            <Select
                                className='custom-select'
                                disabled
                                options={lstStatus.map(item => ({
                                    ...item,
                                    value: item?.id,
                                    label: item.status_name
                                }))}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Người tạo" name="createById">
                            <Select
                                disabled
                                className='custom-select'
                                optionFilterProp="children"
                                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                options={lstUsers.map(item => ({
                                    ...item,
                                    value: item.id,
                                    label: item.fullname
                                }))}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Người duyệt" name="approveById" rules={[{ required: true, message: 'Vui lòng chọn người duyệt' }]}>
                            <Select
                                disabled
                                showSearch
                                className='custom-select'
                                placeholder="Chọn người duyệt"
                                optionFilterProp="children"
                                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                options={lstUser.map(item => ({
                                    ...item,
                                    value: item?.id,
                                    label: item.fullname
                                }))}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name="isCheck" valuePropName="checked" label=" ">
                            <Checkbox>Đã kiểm tra thực tế</Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={6}>
                        <Upload {...props}
                            listType='picture'
                            maxCount={10}
                            fileList={listFile}
                            onPreview={handlePreview}
                            beforeUpload={beforeUpload}
                            accept='image/png,image/jpg,image/jpeg'
                            onRemove={(file) => {
                                const index = listFile.indexOf(file);
                                const newFileList = listFile.slice();
                                newFileList.splice(index, 1);
                                setListFile(newFileList);
                            }}
                        >
                            <Button icon={<UploadOutlined />}>{`Đính kèm file (10 files, 1 file <= 5Mb)`}</Button>
                        </Upload>
                        {
                            lstAttachOld ? lstAttachOld.map((item, index) => (
                                <Row justify={'center'} align={'middle'} className='row-attach' key={index.toString()}>
                                    <Col span={5}>
                                        <Image src={BASE_URL + "api/" + item?.file_path} width={50} height={50} />
                                    </Col>
                                    <Col span={15}>
                                        <p>file dinh kem</p>
                                    </Col>
                                    <Col span={4}>
                                        <Button size='small' onClick={() => deleteLstAttachOld(index)}><DeleteOutlined /></Button>
                                    </Col>
                                </Row>
                            )) : null
                        }
                    </Col>
                </Row>
                {
                    !disableField ?
                        <Row justify={'center'}>
                            <Col>
                                <Form.Item>
                                    <Space>
                                        <Button type="primary" htmlType="submit">
                                            Duyệt sổ
                                        </Button>

                                    </Space>
                                </Form.Item>

                            </Col>

                        </Row> : null
                }

            </Form>
            
            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img
                    alt="example"
                    style={{
                        width: '100%',
                    }}
                    src={previewImage}
                />
            </Modal>
        </div>
    );
};
export default ApproveBookDetail;