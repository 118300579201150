import { combineReducers } from 'redux';
import userReducer from './reducer';
import printReducer from './reducer/printReducer';
import uploadReducer from './reducer/uploadReducer';

const rootReducer = combineReducers({
    userReducer: userReducer,
    printReducer: printReducer,
    uploadReducer: uploadReducer
});

export default rootReducer;