import { Button, Row, message } from "antd"
import React, { useEffect, useState } from "react";
import { backupData, getTimeBackupData } from "../../services/apis";
import LoadingBase from "../../components/LoadingBase";
import { useSelector } from "react-redux";

const BackUpDataPage = () => {
    const userReducer = useSelector(state => state?.userReducer);
    console.log("userReducer",userReducer)
    const [loading, setLoading] = useState(false);
    const [time, setTime] = useState();

    const getTimeBackup = async () => {
        setLoading(true);
        const res = await getTimeBackupData();
        setLoading(false);
        if (res && res?.status === "success") {
            setTime(res?.last_backup_time);
        }
    }

    const handleBackUp = async () => {
        const params = {
            user_id: userReducer?.data?.id
        };
        setLoading(true);
        const res = await backupData(params);
        setLoading(false);
        if (res && res?.status === "success") {
            message.success("Sao lưu dữ liệu thành công");
            setTime(res?.time);
            window.open(res?.download_link, '_blank');
        } else {
            message.success(res?.message ?? "Sao lưu dữ liệu thất bại");
        }
    }
    useEffect(() => {
        getTimeBackup();
    }, [])
    return (
        <div className='container-content min-height'>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <Button style={{width: 200}} type="primary" onClick={handleBackUp}>Sao lưu dữ liệu</Button>
                <span style={{color: 'red', fontStyle: 'italic'}}>Thời gian sao lưu gần nhất: {time}</span>
            </div>
            
            <LoadingBase loading={loading}/>
        </div>
    )
}

export default React.memo(BackUpDataPage);