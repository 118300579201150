import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Input, Row, Space, theme, Table, message } from 'antd';
import LoadingBase from '../../components/LoadingBase';
import { exportReportBookUsed, getReportBookVolumnUsed, saveLogUser } from '../../services/apis';
import '../../css/listbook-page.css';
import { formatNumber } from '../../utils/funcHelper';
import BookInfoModal from '../../components/BookInfoModal';
import FooterTable from '../../components/FooterTable';
import { useSelector } from 'react-redux';

const ReportBookPage = () => {
    const { token } = theme.useToken();
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalPage, setTotalPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [itemSelected, setItemSelected] = useState();
    const [linkDownload, setLinkDownload] = useState('');
    const downloadLinkRef = useRef();
    const userReducer = useSelector(state => state?.userReducer);
    const columns = [
        {
            title: 'STT',
            dataIndex: 'index',
            key: 'index',
            align: 'center',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Mã sổ',
            dataIndex: 'book_code',
            key: 'book_code',
            ellipsis: true,
            width: 120,
            render: (text, record, index) => (<div style={{textAlign: 'left', color: 'blue', cursor: 'pointer', paddingRight: 6}} onClick={() => handleOnClickItem(record)}>{text}</div>)
        },
        {
            title: 'ID sổ',
            dataIndex: 'book_id',
            width: 80,
            align: 'center'
        },
        {
            title: 'Tên chủ sổ',
            dataIndex: 'book_owner_name',
            width: 200,
            render: (text, record, index) => (<div style={{textAlign: 'left', paddingLeft: 6}} onClick={() => handleOnClickItem(record)}>{text}</div>)
        },
        {
            title: 'BSX',
            dataIndex: 'book_license_plates',
            ellipsis: true,
            width: 150,
            render: (text, record, index) => (<div style={{textAlign: 'left'}}>{text}</div>)
        },
        {
          title: 'Địa chỉ',
          dataIndex: 'book_address',
          width: 200,
          render: (text, record, index) => (<div style={{textAlign: 'left'}}>{text?.length > 40 ? `${text.substring(0, 40)}...` : text}</div>)
      },
        {
            title: 'Tổng KL',
            dataIndex: 'volume_total',
            align: 'center',
            render: (text, record, index) => (<div style={{textAlign: 'right'}}>{formatNumber(parseInt(text ?? 0), 0)}</div>)
        },
        {
            title: 'KL đầu kỳ',
            dataIndex: 'volume_dauky',
            align: 'center',
            render: (text, record, index) => (<div style={{textAlign: 'right'}}>{formatNumber(parseInt(text ?? 0), 0)}</div>)
        },
        {
            title: 'KL sử dụng',
            dataIndex: 'total_net',
            align: 'center',
            render: (text, record, index) => (<div style={{textAlign: 'right'}}>{formatNumber(parseInt(text ?? 0), 0)}</div>)
        },
        {
          title: 'KL còn lại',
          align: 'center',
          render: (text, record, index) => (<div style={{textAlign: 'right', fontWeight: 'bold'}}>{formatNumber(parseInt(record?.volume_total ?? 0) - parseInt(record?.volume_dauky ?? 0) - parseInt(record?.total_net ?? 0), 0)}</div>)
        },
        {
            title: 'Hành động',
            render: (text, record, index) => (<Button type='link' onClick={() => handleOnClickItem(record)}>{'Xem'}</Button>)
        }
        
    ];
    const formStyle = {
        maxWidth: 'none',
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
    };
    
    const handleOnClickItem = (item) => {
        setItemSelected(item);
        setShowModal(true);
    }

    const handleNext = () => {
        if (loading) {
            return;
        }
        const page = currentPage;
        setCurrentPage(page + 1);
        getDataReportBooks(page + 1);
    }
  
    const saveLog = async (action) => {
        const params = {
            action: action,
            username: userReducer?.data?.username,
            description: 'Click bao cao'
        };
        const res = await saveLogUser(params);
    }
    const handlePrev = () => {
        if (loading) {
            return;
        }
        const page = currentPage;
        setCurrentPage(page - 1);
        getDataReportBooks(page - 1);
    }
    const handleOnSelect = (value) => {
        setCurrentPage(value);
        getDataReportBooks(value)
    }
    const handleCancel = () => {
        setShowModal(false);
    }
    const getDataReportBooks = async (currentPage) => {
        const params = {
            limit: 50,
            keyword: form.getFieldValue('keyword') ?? '',
            currentPage: currentPage
        };
        setLoading(true);
        const res = await getReportBookVolumnUsed(params);
        setLoading(false);
        if (res && res?.data) {
            setData([...res.data]);
            setTotalPage(res?.totalPages);
        } else {
            setData([]);
            setTotalPage(0);
        }
    }

    const onFinish = async (data) => {
        setCurrentPage(1);
        getDataReportBooks(1);
        saveLog("Lọc báo cáo sổ đỏ")
    };
    const handleExportToExcel = async () => {
        
        try {
            const params = {
                keyword: form.getFieldValue("keyword") ?? ''
            };
            setLoading(true);
            const res = await exportReportBookUsed(params);
            setLoading(false);
            if (res && res.status === true) {
                setLinkDownload(res?.download_link);
                window.open(res?.download_link, '_blank');
            } else {
                message.error(res?.msg ?? 'Xuất excel thất bại. Vui lòng thử lại sau');
            }

        } catch (error) {
            
        }
        
    }
    useEffect(() => {
        getDataReportBooks(1);
    }, [])
    return (
        <div className='container-content'>
            <Form form={form} name="advanced_search" style={formStyle} onFinish={onFinish}>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            name={'keyword'}
                        >
                            <Input placeholder={'Mã sổ/ BSX/ Tên chủ sổ'} />
                        </Form.Item>
                    </Col>
                    
                    <Col span={4}>
                        <Space size="small">
                            <Button type="primary" htmlType="submit">
                                Tìm kiếm
                            </Button>
                            <Button danger type="primary" onClick={handleExportToExcel}>
                                Xuất Excel
                            </Button>
                            <a href={linkDownload} ref={downloadLinkRef} download="Bao_cao_so_do.xlsx" style={{ display: 'none' }} />
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Table
                className='table-book'
                columns={columns}
                dataSource={data}
                pagination={false}
                size="small"
            />
            <FooterTable
                totalRecord={data?.length}
                totalPage={totalPage}
                currentPage={currentPage}
                handleNext={handleNext}
                handlePrev={handlePrev}
                onSelect={handleOnSelect}
            >
                
            </FooterTable>
            <BookInfoModal 
                visible={showModal} 
                data={itemSelected} 
                title={'Thông tin sổ đỏ'} 
                onCancel={handleCancel} 
            />
            <LoadingBase loading={loading} />
        </div>

    );
};
export default ReportBookPage;