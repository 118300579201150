import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Input, Row, Select, Space, theme, DatePicker, Table, message } from 'antd';
import LoadingBase from '../../components/LoadingBase';
import { exportListBooksToExcel, getAllUsers, getListBooks } from '../../services/apis';
import '../../css/listbook-page.css';
import { formatNumber } from '../../utils/funcHelper';
import FooterTable from '../../components/FooterTable';
import { CheckCircleOutlined, CheckCircleTwoTone, CheckOutlined } from '@ant-design/icons';
import moment from 'moment';

const ListBookPage = () => {
    const { token } = theme.useToken();
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalPage, setTotalPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [lstUsers, setLstUsers] = useState([]);
    const [linkDownload, setLinkDownload] = useState('');
    const downloadLinkRef = useRef();

    const columns = [
        {
            title: 'STT',
            dataIndex: 'index',
            width: 45,
            key: 'index',
            align: 'center',
            render: (text, record, index) => index + 1,
            fixed: 'left',
          },
        {
            title: 'Tên chủ sổ',
            width: 180,
            dataIndex: 'book_owner_name',
            key: 'book_owner_name',
            fixed: 'left',
            render: (text, record, index) => (<div style={{textAlign: 'left', paddingLeft: 16, paddingBottom: 5, paddingTop: 5}}>{text}{record?.ischeck === "1" ? <CheckCircleOutlined style={{color: '#52c41a', paddingLeft: 5, width: 24, height: 24}}/> : null}</div>)
        },
        {
            title: 'Mã sổ',
            dataIndex: 'book_code',
            key: '1',
            width: 120,
            ellipsis: true,
        },
        {
            title: 'ID sổ',
            dataIndex: 'book_id',
            key: '1ID',
            width: 80,
            align: 'center'
        },
        {
            title: 'BSX',
            dataIndex: 'book_license_plates',
            key: '2',
            width: 150,
            ellipsis: true,
        },
        {
            title: 'Diện tích sổ(m2)',
            dataIndex: 'book_acreage',
            key: '3',
            width: 110,
            align: 'center',
            render: (text, record, index) => (<div className='value-column'>{formatNumber(parseInt(text))}</div>)
        },
        {
            title: 'Diện tích trồng',
            dataIndex: 'wood_acreage',
            key: '4',
            width: 110,
            align: 'center',
            render: (text, record, index) => (<div className='value-column'>{formatNumber(parseInt(text))}</div>)
        },
        {
            title: 'KL tổng(kg)',
            dataIndex: 'volume_total',
            key: '5',
            width: 110,
            align: 'center',
            render: (text, record, index) => (<div className='value-column'>{formatNumber(parseInt(text))}</div>)
        },
        {
            title: 'KL đầu kỳ',
            dataIndex: 'volume_dauky',
            key: '6',
            width: 110,
            align: 'center',
            render: (text, record, index) => (<div className='value-column'>{formatNumber(parseInt(text))}</div>)
        },
        {
            title: 'Ngày tạo',
            dataIndex: 'created_time',
            key: '7',
            width: 160,
            align: 'center',
        },
        {
            title: 'Địa chỉ sổ',
            dataIndex: 'book_address',
            key: '8',
            ellipsis: true,
            render: (text, record, index) => (<div style={{textAlign: 'left', paddingLeft: 16}}>{text}</div>)
        },
        
        {
            title: 'Trạng thái',
            dataIndex: 'statusId',
            fixed: 'right',
            width: 100,
            align: 'center',
            render: (text, record, index) => (<div style={text === '2' ? {color: 'blue'} : {color: 'red'}}>{text === '1' ? 'Chờ duyệt' : 'Đã duyệt'}</div>)
        },
        {
            title: 'Hành động',
            key: 'operation',
            fixed: 'right',
            width: 100,
            align: 'center',
            render: (text, record, index) => <a onClick={() => window.open(`/detail/${record?.book_id}`, "_blank")}>Xem</a>,
        },
    ];
    const formStyle = {
        maxWidth: 'none',
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 10
    };

    const handleNext = () => {
        if (loading) {
            return;
        }
        const page = currentPage;
        setCurrentPage(page + 1);
        getDataBooks(page + 1);
    }

    const handlePrev = () => {
        if (loading) {
            return;
        }
        const page = currentPage;
        setCurrentPage(page - 1);
        getDataBooks(page - 1);
    }
    const handleOnSelect = (value) => {
        setCurrentPage(value);
        getDataBooks(value)
    }
    const getLstUsers = async () => {
        try {
            const res = await getAllUsers();
            if (res && res?.status === 'success') {
                setLstUsers(res?.data ? [...res.data] : []);
            }
        } catch (error) {
            
        }
        
    }

    const exportBooksToExcel = async () => {
        let params = {
            bookCode: form.getFieldValue("bookCode") ?? '',
            ownerName: form.getFieldValue("ownerName") ?? '',
            ownerCNMD: form.getFieldValue("ownerCNMD") ?? '',
            createById: form.getFieldValue("createById") ?? ''
        };
        setLoading(true);
        const res = await exportListBooksToExcel(params);
        setLoading(false);
        if (res && res.status === true) {
            setLinkDownload(res?.download_link);
            window.open(res?.download_link, '_blank');
        } else {
            message.error(res?.msg ?? 'Xuất excel thất bại. Vui lòng thử lại sau');
        }
    }

    const getDataBooks = async (currentPage, isShowLoading) => {
        let params = {
            limit: 50,
            currentPage: currentPage,
            bookCode: form.getFieldValue("bookCode") ?? '',
            ownerName: form.getFieldValue("ownerName") ?? '',
            ownerCNMD: form.getFieldValue("ownerCNMD") ?? '',
            createById: form.getFieldValue("createById") ?? ''
        };
        if (!isShowLoading) {
            setLoading(true);
        }
        const res = await getListBooks(params);
        if (!isShowLoading) {
            setLoading(false);
        }
        if (res && res?.data) {
            setData([...res.data]);
            setTotalPage(res?.totalPages);
        } else {
            setData([]);
            setTotalPage(0);
        }
    }
    const onFinish = async (data) => {
        setCurrentPage(1);
        getDataBooks(1);
    };
    useEffect(() => {
        setLoading(true);
        Promise.all([getLstUsers(),getDataBooks(1, true)])
            .then(() => {})
            .catch(err => console.log("Err", err))
            .finally(() => setLoading(false))
        
    }, [])
    return (
        <div className='container-content'>
            <Form form={form} name="advanced_search" style={formStyle} onFinish={onFinish}>
                <Row gutter={24}>
                    <Col span={5}>
                        <Form.Item
                            name={'bookCode'}
                        >
                            <Input placeholder={'Mã sổ'} />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item
                            name={'ownerName'}
                        >
                            <Input placeholder={'Tên chủ rừng'} />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item
                            name={'ownerCNMD'}
                        >
                            <Input placeholder={'CMND chủ rừng'} />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item
                            name={'createById'}
                        >
                            <Select 
                                showSearch
                                placeholder={'Người tạo'}
                                className='custom-select'
                                optionFilterProp="children"
                                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                options={lstUsers.map(user => ({
                                    ...user,
                                    value: user.id,
                                    label: user.fullname
                                }))}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Space size="small">
                            <Button type="primary" htmlType="submit">
                                Tìm kiếm
                            </Button>
                            <Button type="primary" danger onClick={exportBooksToExcel}>
                                Xuất Excel
                            </Button>
                            <a href={linkDownload} ref={downloadLinkRef} download="Bao_cao_so_do.xlsx" style={{ display: 'none' }} />
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Table
                className='table-book'
                columns={columns}
                dataSource={data}
                pagination={false}
                scroll={{
                    x: 1800,
                    y: 600
                }}
                rowKey={(record) => record?.book_id}
                rowClassName={'row-table-book'}
            />
            <FooterTable
                totalRecord={data?.length}
                totalPage={totalPage}
                currentPage={currentPage}
                handleNext={handleNext}
                handlePrev={handlePrev}
                onSelect={handleOnSelect}
            >
            </FooterTable>
            <LoadingBase loading={loading} />
        </div>

    );
};
export default ListBookPage;