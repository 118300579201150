import React, { useEffect, useState } from 'react';
import '../css/layout-content.css'
import { Breadcrumb, Col, Layout, Menu, Modal, Row, theme } from 'antd';
import { Images } from '../assets';
import { matchRoutes, useLocation, useNavigate } from 'react-router-dom';
import { saveUserInfo } from '../redux/action';
import { useDispatch, useSelector } from 'react-redux';
import { LIST_MENU_SIDE_BAR } from '../constants/constants';
import { saveLogUser } from '../services/apis';


const { Header, Content, Footer, Sider } = Layout;
const { confirm } = Modal;

const LayoutContent = ({ children }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [currentPathLabel, setCurrentPathLabel] = useState();
    const [collapsed, setCollapsed] = useState(false);
    const userReducer = useSelector(state => state?.userReducer);
    const [listMenu, setListMenu] = useState([]);
    const {
        token: { colorBgContainer },
    } = theme.useToken();


    const useCurrentRoute = () => {
        if (location?.pathname == '/') return {}
        if (matchRoutes(LIST_MENU_SIDE_BAR, location)) {
            const [{ route }] = matchRoutes(LIST_MENU_SIDE_BAR, location);
            let routeCustom;
            if (route && route?.children) {
                routeCustom = {
                    ...route,
                    labelChil: route?.children?.find(obj => obj.path === location.pathname)?.label
                }
            } else {
                routeCustom = route;
            }
            return routeCustom
        } else {
            return {}
        }
    }
    const handleGetBreadcrumb = () => {
        if (location?.pathname == '/') {
            setCurrentPathLabel('');
        }
        LIST_MENU_SIDE_BAR.forEach(menu => {
            if (menu.path === location.pathname) {
                setCurrentPathLabel(menu.label);
            } else {
                if (menu.children) {
                    const chil = menu.children.find(obj => obj.path === location.pathname);
                    if (chil) {
                        setCurrentPathLabel(`${menu.label}/${chil.label}`);
                    }
                }
            }
        })
    }
    const handlePermissionMenu = () => {
        const listPermission = userReducer?.data?.role_ids;
        console.log("listPermission", listPermission);
        if (!listPermission) {
            setListMenu([]);
        } else {
            setListMenu([...filterMenuForPermissions(LIST_MENU_SIDE_BAR, listPermission)]);
        }
    }

    const filterMenuForPermissions = (menuList, userPermissions) => {
        return menuList.filter(menuItem => {
            let hasPermission = false;
    
            // Kiểm tra xem menu có quyền của người dùng hay không
            if (menuItem.permission) {
                hasPermission = menuItem.permission.some(permission => userPermissions.includes(permission));
            }
    
            // Nếu menu có quyền của người dùng hoặc menu con có quyền của người dùng
            if (hasPermission || (menuItem.children && menuItem.children.some(child => filterMenuForPermissions([child], userPermissions).length > 0))) {
                // Lọc các menu con trước khi trả về menu cha
                if (menuItem.children) {
                    menuItem.children = filterMenuForPermissions(menuItem.children, userPermissions);
                }
                return true;
            }
            return false;
        });
    }

    const saveLog = async (action) => {
        const params = {
            action: action,
            username: userReducer?.data?.username,
            description: 'Click vào menu'
        };
        const res = await saveLogUser(params);
    }

    const handleCLickMenu = (item) => {
        if (item.key == 'dang-xuat') {
            confirm({
                title: <>Bạn có chắc chắn muốn đăng xuất?</>,
                okText: "OK",
                okType: "danger",
                cancelText: "Hủy",
                async onOk() {
                    try {
                        dispatch(saveUserInfo(null));
                    } finally {
                    }
                },
            });
        } else {
            navigate(`/${item.key}`, { replace: true });
        }
        saveLog(item?.key);
    }

    useEffect(() => {
        handleGetBreadcrumb();
    }, [location])
    useEffect(() => {
        if (userReducer) {
            handlePermissionMenu();
        }
    }, [userReducer])
    return (
        <Layout
            style={{ minHeight: '100vh' }}
        >
            <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)} theme='light'>
                <div className="logo-vertical" >
                    <img src={Images.img_logo_app} className='img-logo' />
                </div>
                <Menu theme="light" defaultSelectedKeys={useCurrentRoute()?.key} mode="inline" items={listMenu} onClick={handleCLickMenu} />
            </Sider>
            <Layout style={{ minHeight: '100vh' }}>
                <Header
                    className='color-primary header-padding'
                >
                    <Row>
                        <Col span={18}>
                            <p className='title-header'>Hệ thống quản lý thông tin sổ đỏ</p>
                        </Col>
                        <Col span={6}>
                            <p className='user-name-header'><span className='txt-hello'>Xin chào</span>, {userReducer?.data?.fullname}</p>
                        </Col>
                    </Row>
                    
                </Header>
                <Content
                    style={{
                        margin: '0 16px',
                    }}
                >
                    <Breadcrumb
                        style={{
                            margin: '16px 0',
                        }}
                    >
                        <Breadcrumb.Item>{currentPathLabel}</Breadcrumb.Item>
                    </Breadcrumb>
                    <div
                        style={{
                            padding: 24,
                            minHeight: `calc(100vh - 180px)`,
                            background: "#FFF",
                        }}
                        className='height-body'
                    >
                        {children}
                    </div>
                </Content>
                <Footer
                    style={{
                        textAlign: 'center',
                        background: 'transparent'
                    }}
                >
                    Bản quyền thuộc BIOMASS FUEL VIET NAM ©2024
                </Footer>
            </Layout>
        </Layout>
    );
};
export default LayoutContent;