import axiosClient from "./axios.client";

export const login = (params) => {
    return axiosClient.post('api/login', params);
}

export const importFromExcel = (params) => {
    return axiosClient.post('api/importDataFromExcel', params);
}

export const importPhieuCanFromExcel = (params) => {
    return axiosClient.post('api/importWeighingSlip', params);
}

export const getListBooks = (params) => {
    return axiosClient.get(`api/getListBook?ownerName=${params?.ownerName}&bookCode=${params?.bookCode}&ownerCNMD=${params?.ownerCNMD}&createById=${params?.createById ?? ''}&statusId=${params?.statusId ?? ''}&limit=${params.limit}&currentPage=${params.currentPage}`);
}

export const searchBookInfo = (params) => {
    return axiosClient.get(`api/getBookInfo?keyword=${params?.keyword}&limit=${params.limit}&currentPage=${params.currentPage}`);
}
export const getInfoBookById = (params) => {
    return axiosClient.get(`api/getBookInfoById?book_id=${params?.book_id}`);
}
export const deleteFileUpload = (params) => {
    return axiosClient.post('api/deleteFile', params);
}

export const addNewBook = (params) => {
    return axiosClient.post('api/addBook', params);
}

export const updateBookById = (params) => {
    return axiosClient.put('api/updateBookById', params);
}

export const approveBookById = (params) => {
    return axiosClient.put('api/approveBookById', params);
}

export const getBookTypes = (param) => {
    return axiosClient.get(`api/getListBookType?name=${param && param.name ? param?.name : ''}`);
}

export const addNewBookType = (param) => {
    return axiosClient.post('api/addNewBookType', param);
}

export const getListDepartment = (param) => {
    return axiosClient.get(`api/getListDepartment?name=${param && param.name ? param?.name : ''}`);
}

export const addNewDepartment = (param) => {
    return axiosClient.post('api/addNewDepartment', param);
}

export const getListUserManager = () => {
    return axiosClient.get('api/getListUserManager');
}
export const getAllUsers = (params) => {
    return axiosClient.get(`api/getAllUsers?fullname=${params && params?.fullname ? params.fullname : ''}`);
}
export const getListStatus = () => {
    return axiosClient.get('api/getListStatus');
}

export const getBookDetail = (params) => {
    return axiosClient.get(`api/getBookDetailById?bookId=${params?.bookId}`);
}

export const getListRoles = (params) => {
    return axiosClient.get(`api/getListRoles?name=${params && params?.name ? params.name : ''}`);
}

export const getRolesByUserId = (params) => {
    return axiosClient.get(`api/getUserRole?userId=${params?.userId}`);
}

export const updateUserPermissionById = (param) => {
    return axiosClient.post('api/updateUserPermission', param);
}

export const changeUserPassword = (param) => {
    return axiosClient.post('api/changeUserPassword', param);
}

export const getListWeighingSlip = (params) => {
    return axiosClient.post('api/getListWeighing', params);
    // return axiosClient.get(`api/getListWeighing?keyword=${params?.keyword}&limit=${params.limit}&currentPage=${params.currentPage}&status=${params.status}&fromDate=${params.fromDate}&toDate=${params.toDate}&bookCode=${params.bookCode}`);
}

export const getVolumeUsedByBookCode = (param) => {
    return axiosClient.post('api/getVolumeUsedByBookCode', param);
}

export const addWoodType = (param) => {
    return axiosClient.post('api/addWoodType', param);
}

export const updateWoodType = (param) => {
    return axiosClient.post('api/updateWoodType', param);
}

export const getListWoodType = (param) => {
    return axiosClient.get(`api/getListWoodType?name=${param && param.name ? param?.name : ''}`);
}

export const importWoodsTypeFromExcel = (params) => {
    return axiosClient.post('api/importWoods', params);
}

export const updateWeighingSlipById = (param) => {
    return axiosClient.post('api/updateWeighingSlip', param);
}

export const getReportForDashboard = () => {
    return axiosClient.get(`api/getReportForDashboard`);
}

export const getDataForChart = () => {
    return axiosClient.get(`api/getDataForChartDaily`);
}

export const getBooksWarning = () => {
    return axiosClient.get(`api/getBooksWarning`);
}

export const exportContractToWord = (param) => {
    return axiosClient.post('api/exportContract', param);
}

export const exportPhieuCan = (param) => {
    return axiosClient.post('api/exportWeighingSlip', param);
}

export const getReportBookVolumnUsed = (params) => {
    return axiosClient.get(`api/getReportBookVolumnUsed?keyword=${params?.keyword}&limit=${params?.limit}&currentPage=${params?.currentPage}`);
}

export const getBooksWeighingRecent = (params) => {
    return axiosClient.get(`api/getBooksWeighingRecent?book_code=${params?.book_code}&limit=${params?.limit}&currentPage=${params?.currentPage}`);
}

export const getDriversInfo = (params) => {
    return axiosClient.get(`api/getDriversInfo?keyword=${params?.keyword}&limit=${params?.limit}&currentPage=${params?.currentPage}`);
}

export const addNewDriverInfo = (params) => {
    return axiosClient.post(`api/addNewDriverInfo`, params);
}

export const updateDriverInfoById = (params) => {
    return axiosClient.post(`api/updateDriverInfoById`, params);
}

export const importDriverFromExcel = (params) => {
    return axiosClient.post(`api/importDriverFromExcel`, params);
}

export const saveBillInfo = (params) => {
    return axiosClient.post(`api/addPayment`, params);
}

export const searchPhieuDatCoc = (params) => {
    return axiosClient.get(`api/searchPhieuDatCoc?keyword=${params?.keyword}&limit=${params?.limit}&currentPage=${params?.currentPage}`);
}

export const getDataBillByCode = (params) => {
    return axiosClient.get(`api/getDataBillByCode?code=${params?.code}`);
}

export const getReportWeighing = (params) => {
    return axiosClient.post(`api/getReportWeighing`, params);
}

export const getReportPhieuCoc = (params) => {
    return axiosClient.post(`api/getReportPhieuCoc`, params);
}

export const getReportPhieuChi = (params) => {
    return axiosClient.post(`api/getReportPhieuChi`, params);
}

export const updatePhieuChi = (params) => {
    return axiosClient.post(`api/updatePhieuChi`, params);
}

export const getReportBookWarning = (params) => {
    return axiosClient.get(`api/getReportBookWarning?keyword=${params?.keyword}&limit=${params?.limit}&currentPage=${params?.currentPage}&volume_limit=${params?.volume_limit}`);
}
export const getInfoDriverByBSXAndName = (params) => {
    return axiosClient.get(`api/getInfoDriverByBSXAndName?name=${params?.name}&bsx=${params?.bsx}`);
}

export const checkBookInfoExist = (params) => {
    return axiosClient.post(`api/checkBookInfoExist`, params);
}

export const exportReportBookUsed = (params) => {
    return axiosClient.get(`api/exportReportBookUsedExcel?keyword=${params?.keyword}`);
}

export const exportReportWarningBooks = (params) => {
    return axiosClient.get(`api/exportReportWarningBook?keyword=${params?.keyword}&volume_limit=${params?.volume_limit}`);
}

export const exportWeighingToExcel = (params) => {
    return axiosClient.post(`api/exportReportWeighing`, params);
}

export const exportListBooksToExcel = (params) => {
    return axiosClient.get(`api/exportListBookToExcel?ownerName=${params?.ownerName}&bookCode=${params?.bookCode}&ownerCNMD=${params?.ownerCNMD}&createById=${params?.createById ?? ''}&statusId=${params?.statusId ?? ''}`);
}

export const getListWeighingWithBook = (params) => {
    return axiosClient.post('api/getListWeighingWithBook', params);
}

export const exportReportWeighingWithBook = (params) => {
    return axiosClient.post('api/exportReportWeighingWithBook', params);
}

export const backupData = (params) => {
    return axiosClient.post('api/apiBackUp',params);
}

export const getTimeBackupData = () => {
    return axiosClient.get('api/getTimeBackup');
}

export const saveLogUser = (params) => {
    return axiosClient.post('api/logUser',params);
} 

export const getListWeighingBySoHD = (params) => {
    return axiosClient.post('api/getListWeighingBySoHD', params);
}

export const updateMutilWeighingSlip = (params) => {
    return axiosClient.post('api/updateMutilWeighingSlip', params);
}