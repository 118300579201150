import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row, Space, theme, Table, message, Upload, Modal } from 'antd';
import LoadingBase from '../../components/LoadingBase';
import { addWoodType, getListWoodType, importWoodsTypeFromExcel, updateWoodType } from '../../services/apis';
import '../../css/listbook-page.css';
import AddEditWoodsModal from '../../components/AddEditWoodsModal';
import { formatNumber } from '../../utils/funcHelper';
import { parseInt } from 'lodash';
import { ExclamationCircleOutlined, ImportOutlined, InfoCircleOutlined, SaveOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';

const WoodsTypeCategoryPage = () => {
    const { token } = theme.useToken();
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [type, setType] = useState();
    const [itemSelected, setItemSelected] = useState();
    const [message, setMessage] = useState('');
    const [totalSuccess, setTotalSuccess] = useState();
    const [isShowModalMessage, setIsShowModalMessage] = useState(false);
    const [listFailed, setListFailed] = useState([]);
    const [isShowButton, setIsShowButton] = useState(false);
    const [modal, contextHolder] = Modal.useModal();
    const [loadingImport, setLoadingImport] = useState(false);
    const columns = [
        {
            title: 'Mã',
            dataIndex: 'id',
            key: 'id',
            width: 150,
            align: 'center',
        },
        {
            title: 'Tên loại hàng',
            dataIndex: 'name'
        },
        {
            title: 'Giá (VNĐ)',
            dataIndex: 'price',
            render: (text, record, index) => <div>{formatNumber(parseInt(text), 0)}</div>
        },
        {
            title: 'Hành động',
            render: (text, record, index) => <Button type='link' onClick={() => handleEdit(record)}>Sửa</Button>
        },
    ];
    const formStyle = {
        maxWidth: 'none',
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
    };
    // const confirm = () => {
    //     modal.confirm({
    //         title: 'Xác nhận',
    //         icon: <ExclamationCircleOutlined />,
    //         content: 'Bạn có muốn lưu thông tin loại hàng không?',
    //         okText: 'Có',
    //         cancelText: 'Không',
    //         onOk: () => handleSaveInfo()
    //     });
    // };
    // const handleSaveInfo = async () => {
    //     setLoading(true);
    //     debugger
    //     const res = await importWoodsTypeFromExcel(data);
    //     setLoading(false);
    //     console.log("ressss", res);
    //     if (res) {
    //         setTotalSuccess(res?.totalSuccess ?? 0);
    //         if (res?.code === 1) {
    //             setMessage(res.msg ?? 'Cập nhật dữ liệu thành công');
    //             setIsShowModalMessage(true);
    //             setData([]);
    //             setIsShowButton(false);
    //         } else if (res?.code === 2) {
    //             setMessage(res.msg ?? 'Cập nhật dữ liệu thành công');
    //             setListFailed(res.failedArr && res.failedArr.length > 0 ? [...res.failedArr] : []);
    //             setIsShowModalMessage(true);
    //             setData([]);
    //             setIsShowButton(false);
    //         } else if (res?.code === 0) {
    //             setMessage(res.msg ?? 'Cập nhật dữ liệu thất bại');
    //             setIsShowModalMessage(true);
    //         } 
            
    //     } else {
    //         setMessage('Có lỗi xảy ra. vui lòng thử lại');
    //         setIsShowModalMessage(true);
    //     }
    // }
    // const props = {
    //     name: 'file',
    //     accept: '.xlsx,.xls',
    //     beforeUpload: (file) => {
    //         console.log('beforeUpload', file)
    //         setLoadingImport(true);
    //         let reader = new FileReader();
    //         reader.onload = function (e) {
    //             let data = e.target.result;
    //             let readedData = XLSX.read(data, { type: 'binary' });
    //             const wsname = readedData.SheetNames[0];
    //             const ws = readedData.Sheets[wsname];

    //             /* Convert array to json*/
    //             const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
    //             console.log("dataParse", dataParse)
    //             if (dataParse && dataParse.length > 0) {
    //                 dataParse.shift();
    //                 setData([...dataParse]);
    //                 setIsShowButton(true);
    //             }
    //             setLoadingImport(false);
    //         };
    //         reader.readAsBinaryString(file);
    //         return false;
    //     },
    // };
    const handleEdit = (data) => {
        setItemSelected(data);
        setType('Edit');
        setShowModal(true);
    }

    const handleAddEditData = async (data) => {
        if (!data || data.price === 0) {
            return;
        }
        if (type === 'Add') {
            setShowModal(false);
            const params = {
                name: data?.name,
                price: data?.price
            };
            setLoading(true);
            const res = await addWoodType(params);
            setLoading(false);
            if (res && res.status === 1) {
                message.success("Thêm mới thành công", 5);
                getDataWoodsType();
            } else {
                message.error(res?.msg ?? 'Có lỗi xảy ra', 5);
            }
        } else {
            setShowModal(false);
            const params = {
                name: data?.name,
                price: data?.price,
                id: itemSelected?.id
            };
            setLoading(true);
            const res = await updateWoodType(params);
            setLoading(false);
            if (res && res.status === "success") {
                message.success("Chỉnh sửa thành công", 5);
                getDataWoodsType();
            } else {
                message.error(res?.msg ?? 'Có lỗi xảy ra', 5);
            }
        }
    }
    const handleCancel = () => {
        setShowModal(false);
    }
    const getDataWoodsType = async (keyword) => {
        const params = {
            name: keyword
        }
        setLoading(true);
        const res = await getListWoodType(params);
        setLoading(false);
        if (res && res?.status === 'success') {
            setData(res?.data ? [...res.data] : []);
        } else {
            setData([]);
        }
    }
    
    const onFinish = async (data) => {
        getDataWoodsType(data.name);
    };
    useEffect(() => {
        getDataWoodsType();
    }, [])
    return (
        <div className='container-content'>
            {/* {
                contextHolder
            } */}
            {/* <Row className="row-button">
                <Upload {...props} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', alignContent: 'center' }}>
                    <Button type="default" size="large" block danger icon={<ImportOutlined />} disabled={loadingImport} onClick={() => { }}
                        style={{ width: 150 }}
                    >
                        Import Excel
                    </Button>
                </Upload>
                {
                    isShowButton ?
                        <Button type="primary" size="large" defaultBg="#ff0000" block icon={<SaveOutlined />} disabled={loading} onClick={confirm}
                            style={{ width: 150 }}
                        >
                            Lưu thông tin
                        </Button> : null
                }

            </Row> */}
            <Form form={form} name="advanced_search" style={formStyle} onFinish={onFinish}>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            name={'name'}
                        >
                            <Input placeholder={'Tên loại hàng'} />
                        </Form.Item>
                    </Col>
                    
                    
                    <Col span={4}>
                        <Space size="small">
                            <Button type="primary" htmlType="submit">
                                Tìm kiếm
                            </Button>
                            <Button
                                onClick={() => {
                                    setShowModal(true);
                                    setType('Add');
                                }}
                            >
                                Thêm mới
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Table
                className='table-book'
                columns={columns}
                dataSource={data}
                pagination={{
                    pageSize: 50
                }}
            />
            <AddEditWoodsModal visible={showModal} onCancel={handleCancel} onSave={handleAddEditData} title={"Thêm mới loại hàng"} data={itemSelected} type={type}/>
            <Modal
                open={isShowModalMessage}
                centered
                footer={[
                    <Button key="submit" type="primary" onClick={() => setIsShowModalMessage(false)}>
                        Đóng
                    </Button>
                ]}
                closable={false}
            >
                <div>
                    <p className="title-notify">
                        <InfoCircleOutlined style={{
                        color: 'blue',
                    }} /> Thông báo</p>

                    <p>{`${message}: ${totalSuccess}`}</p>
                    {
                        listFailed && listFailed.length > 0 ? 
                        <p>Các sổ chưa lưu được: 
                            {listFailed.map((item, index) => (
                            <p key={index}>{item}</p>
                        ))}</p> : null
                    }
                </div>
            </Modal>
            <LoadingBase loading={loading} />
        </div>

    );
};
export default WoodsTypeCategoryPage;