import React from 'react';
import { Navigate } from "react-router-dom";
import LayoutContent from './LayoutContent'
import { useSelector } from 'react-redux';

const PrivateRoute = ({ children}) => {
    const user = useSelector((state) => state?.userReducer);
    const auth = user?.data?.access_token || false;
    return auth ? <LayoutContent children={children}/> : <Navigate to="/dang-nhap" />;
}


export default PrivateRoute;