import React, { useEffect, useState } from 'react';
import { getAllUsers, getReportPhieuChi, saveLogUser } from '../../services/apis';
import { Button, Col, DatePicker, Form, Input, Row, Select, Space, Table, theme } from 'antd';
import dayjs from 'dayjs';
import LoadingBase from '../../components/LoadingBase';
import { formatNumber } from '../../utils/funcHelper';
import FooterTable from '../../components/FooterTable';
import "../../css/listbook-page.css";
import { useSelector } from 'react-redux';


const ReportBCPhieuChiPage = () => {
    const { token } = theme.useToken();
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [dataUser, setDataUser] = useState([]);
    const { RangePicker } = DatePicker;
    const userReducer = useSelector(state => state?.userReducer);
    const columns = [
        {
            title: 'Lái xe',
            dataIndex: 'hoten',
            key: 'name',
            render: (text, record, index) => (<a>{text}</a>)
        },
        {
            title: 'CMND',
            dataIndex: 'cmnd',
            align: 'center',
        },
        {
            title: 'BSX',
            dataIndex: 'biensoxe',
            align: 'center',
        },
        {
            title: 'Số phiếu',
            dataIndex: 'sophieucan',
            align: 'center',
        },
        {
            title: 'Người chi',
            dataIndex: 'create_username',
            align: 'center',
        },
        {
            title: 'Tiền cọc',
            dataIndex: 'tiencoc',
            align: 'center',
            render: (text, record, index) => (<div className='format-number-table'>{formatNumber(parseInt(text), 0)}</div>)
        },
        {
            title: 'Trả cọc',
            dataIndex: 'tientracoc',
            align: 'center',
            render: (text, record, index) => (<div className='format-number-table'>{formatNumber(parseInt(text), 0)}</div>)
        },
        {
            title: 'Hỗ trợ',
            dataIndex: 'tienhotro',
            align: 'center',
            render: (text, record, index) => (<div className='format-number-table'>{formatNumber(parseInt(text), 0)}</div>)
        },
        {
            title: 'Giảm giá%',
            dataIndex: 'giamgia',
            align: 'center',
            render: (text, record, index) => (<div className='format-number-table'>{formatNumber(parseInt(text), 0)}</div>)
        },
        {
            title: 'Tổng chi',
            dataIndex: 'thucnhan',
            align: 'center',
            render: (text, record, index) => (<div className='format-number-table'>{formatNumber(parseInt(text), 0)}</div>)
        },
        {
            title: 'Ngày',
            dataIndex: 'createtime',
            align: 'center',
            render: (text, record, index) => (<div>{dayjs(text).format("DD/MM/YYYY")}</div>)
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            align: 'center',
            render: (text, record, index) => (<div style={text === "1" ? {color: 'blue'} : {color: 'red'}}>{text === "1" ? "Đã chi" : "Đã huỷ"}</div>)
        }

    ];
    const formStyle = {
        maxWidth: 'none',
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 10
    };

    const getDataUser = async () => {
        const res = await getAllUsers();
        if (res && res?.status === "success" && res?.data) {
            setDataUser(res?.data?.length > 0 ? [...res?.data?.map(item => ({
                value: item?.id,
                label: item?.fullname
            }))] : [])
        }
    }
    const getDataReport = async (currentPage, isShowLoading) => {
        const params = {
            fromDate: dayjs(form.getFieldValue("date")?.[0]).format("DD/MM/YYYY") ?? null,
            toDate: dayjs(form.getFieldValue("date")?.[1]).format("DD/MM/YYYY") ?? null,
            userId: form.getFieldValue("user") ?? null,
            keyword: form.getFieldValue("keyword") ?? null,
            limit: 50,
            currentPage: currentPage,
            userId: form.getFieldValue("user") ?? null
        }
        if (!isShowLoading) {
            setLoading(true);
        }
        const res = await getReportPhieuChi(params);
        if (!isShowLoading) {
            setLoading(false);
        }
        if (res && res?.status === "success" && res?.data) {
            setData([...res?.data]);
            setTotalPage(res?.totalPages ?? 0);
        } else {
            setData([]);
            setTotalPage(0);
        }
    }
    const handleNext = () => {
        if (loading) {
            return;
        }
        const page = currentPage;
        setCurrentPage(page + 1);
        getDataReport(page + 1);
    }
    const handlePrev = () => {
        if (loading) {
            return;
        }
        const page = currentPage;
        setCurrentPage(page - 1);
        getDataReport(page - 1);
    }
    const handleOnSelect = (value) => {
        setCurrentPage(value);
        getDataReport(value)
    }
    const saveLog = async (action) => {
        const params = {
            action: action,
            username: userReducer?.data?.username,
            description: 'Click bao cao'
        };
        const res = await saveLogUser(params);
    }
    const onFinish = (data) => {
        setCurrentPage(1);
        getDataReport(1);
        saveLog("Lọc báo cáo phiếu chi");
    }
    
    useEffect(() => {
        form.setFieldsValue({
            date: [dayjs().startOf('month'), dayjs()]
        })
        setLoading(true);
        Promise.all([getDataUser(), getDataReport(1, true)])
            .then(res => {})
            .catch(err => {
                console.log("errr", err)
            })
            .finally(() => setLoading(false));
    }, [])

    return (
        <div className='container-content'>
            <Form form={form} name="advanced_search" style={formStyle} onFinish={onFinish}>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            name={'keyword'}
                        >
                            <Input placeholder={'Tên lái xe/CMND'} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name={'date'}
                        >
                            <RangePicker
                                style={{ height: 40, width: '100%' }}
                                placeholder={['Bắt đầu', 'Kết thúc']}
                                format={"DD/MM/YYYY"}
                                allowClear={false}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            name={'user'}
                        >
                            <Select
                                placeholder="Tài khoản chi"
                                allowClear 
                                options={dataUser}
                                style={{height: 40}}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Space size="small">
                            <Button type="primary" htmlType="submit">
                                Tìm kiếm
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Table 
                className='table-book'
                dataSource={data}
                columns={columns}
                pagination={false}
                rowKey={(record) => record?.id}
                
            />
            <FooterTable
                totalRecord={data?.length}
                totalPage={totalPage}
                currentPage={currentPage}
                handleNext={handleNext}
                handlePrev={handlePrev}
                onSelect={handleOnSelect}
            >
                <Col span={5}>
                    <span className="txt-title-footer">Tiền hỗ trợ: {formatNumber(data?.filter(item => item?.status === "1")?.reduce((totalMoney, currentValue) => totalMoney + parseInt(currentValue.tienhotro), 0), 0)}đ</span>
                </Col>
                <Col span={5}>
                    <span className="txt-title-footer">Tổng chi: {formatNumber(data?.filter(item => item?.status === "1").reduce((totalMoney, currentValue) => totalMoney + parseInt(currentValue.thucnhan), 0), 0)}đ</span>
                </Col>
            </FooterTable>
            <LoadingBase loading={loading}/>
        </div>
    );
};
export default ReportBCPhieuChiPage;