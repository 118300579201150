import { SearchOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Form, Input, InputNumber, Modal, Row, Select, Space, Table, message } from "antd"
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { getDriversInfo, getInfoDriverByBSXAndName, saveBillInfo, searchPhieuDatCoc } from "../../../services/apis";
import LoadingBase from "../../../components/LoadingBase";
import { formatNumber } from "../../../utils/funcHelper";
import { useDispatch, useSelector } from "react-redux";
import { saveDataPrint } from "../../../redux/action";

const ModalAddBill = ({ title, visible, dismiss, data }) => {
    const userReducer = useSelector(state => state?.userReducer);
    const [form] = Form.useForm();
    const [dataDriver, setDataDriver] = useState([]);
    const [value, setValue] = useState();
    const [loading, setLoading] = useState(false);
    const [dataTable, setDataTable] = useState([]);
    const [valueMaPhieu, setValueMaPhieu] = useState();
    const [dataPhieu, setDataPhieu] = useState([]);
    const [isShowPrint, setIsShowPrint] = useState(false);
    const [isDisable, setIsDisable] = useState(false);
    const dispatch = useDispatch();
    const columns = [
        {
            title: 'STT',
            dataIndex: 'index',
            width: 45,
            key: 'index',
            align: 'center',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Tên lái xe',
            width: 180,
            dataIndex: 'tenlaixe',
            key: 'tenlaixe',
            fixed: 'left',
        },
        {
            title: 'CMND',
            dataIndex: 'cmnd',
            key: '1',
            align: 'center',
            width: 120,
        },
        {
            title: 'Ngày cấp',
            dataIndex: 'ngaycap',
            key: '2',
            width: 120,
        },
        {
            title: 'BSX',
            dataIndex: 'bsx',
            key: '3',
            align: 'center',
        },
        {
            title: 'Số tiền cọc',
            dataIndex: 'sotien',
            key: '4',
            align: 'center',
            render: (text, record, index) => (<p className='value-column'>{formatNumber(parseInt(text))}</p>)
        },
        {
            title: 'Ngày cọc',
            dataIndex: 'createtime',
            key: '6',
            width: 120,
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            fixed: 'right',
            width: 100,
            render: (text, record, index) => (<p>{text === '1' ? 'Đã thu cọc' : 'Đã trả cọc'}</p>)
        },
        {
            title: 'Hành động',
            key: 'operation',
            width: 60,
            render: (text, record, index) => !isDisable ? <a onClick={() => handleDeleteItem(record, index)}>Xoá</a> : null,
        },
    ];
    const handleFailed = (err) => {
        console.log("err", err);
        message.error("Chưa nhập đủ thông tin. Vui lòng kiểm tra lại");
    }
    const renderLabelDropdownDriver = (item) => {
        return (
            <Row>
                <Col span={6}>
                    <p>{item?.fullname ?? ''}</p>
                </Col>
                <Col span={4}>
                    <p>{item?.cmnd ?? ''}</p>
                </Col>
                <Col span={4}>
                    <p>{item?.phone ?? ''}</p>
                </Col>
                <Col span={3}>
                    <p>{item?.bsx ?? ''}</p>
                </Col>
                <Col span={7}>
                    <p>{item?.diachi ?? ''}</p>
                </Col>
            </Row>
        )
    }
    const renderLabelDropdownPhieu = (item) => {
        return (
            <Row>
                <Col span={3}>
                    <p>{item?.maphieucan ?? ''}</p>
                </Col>
                <Col span={6}>
                    <p>{item?.tenlaixe ?? ''}</p>
                </Col>
                <Col span={4}>
                    <p>{item?.cmnd ?? ''}</p>
                </Col>
                <Col span={3}>
                    <p>{item?.bsx ?? ''}</p>
                </Col>
                <Col span={4}>
                    <p>{item?.sotien ? formatNumber(parseInt(item?.sotien)) : ''}</p>
                </Col>
                <Col span={4}>
                    <p>{item?.createtime ?? ''}</p>
                </Col>
            </Row>
        )
    }
    const handleDeleteItem = (item, index) => {
        const tempArr = [...dataTable];
        if (tempArr.length > 0) {
            tempArr.splice(index, 1);
            const tempMoney = parseInt(form.getFieldValue("thanhtien")) - parseInt(item?.sotien ?? 0);
            form.setFieldValue("thanhtien", tempMoney);
        }
        setDataTable([...tempArr]);
        
    }
    const getDriverInfoFromBsxandName = async (name, bsx) => {
        const params = {
            name: name,
            bsx: bsx
        }
        setLoading(true);
        const res = await getInfoDriverByBSXAndName(params);
        setLoading(false);
        if (res && res?.status === "success" && res?.data && res?.data?.length > 0) {
            const option = res?.data[0]
            form.setFieldsValue({
                driverName: option?.fullname,
                cmnd: option?.cmnd ?? '',
                ngaycap: option?.ngaycap ?? '',
                noicap: option?.noicap ?? '',
                biensoxe: option?.bsx ?? '',
                diachi: option?.diachi ?? '',
                phone: option?.phone ?? '',
            })
        }
        console.log("ressss", res);
    }
    const debouncedSearchDriver = debounce((keyword) => {
        getDataDrivers(keyword);
    }, 500);

    const debouncedSearchPhieuCoc = debounce((keyword) => {
        getDataPhieuDatCoc(keyword);
    }, 500);

    const getDataPhieuDatCoc = async (keyword) => {
        const params = {
            limit: 10,
            currentPage: 1,
            keyword: keyword ?? ''
        };
        const res = await searchPhieuDatCoc(params);
        if (res && res?.data) {
            const temp = [...res.data?.map(item => ({
                ...item,
                value: item.id,
                label: renderLabelDropdownPhieu(item)
            }))];
            setDataPhieu([...temp]);
        } else {
            setDataPhieu([]);
        }
    }

    const getDataDrivers = async (keyword) => {
        const params = {
            limit: 10,
            currentPage: 1,
            keyword: keyword ?? ''
        };
        const res = await getDriversInfo(params);
        if (res && res?.data) {
            const temp = [...res.data?.map(item => ({
                ...item,
                value: item.id,
                label: renderLabelDropdownDriver(item)
            }))];
            setDataDriver([...temp]);
        } else {
            setDataDriver([]);
        }
    }
    const handleChangeSearchPhieuCoc = (value) => {
        setValueMaPhieu(value);
    }
    const handleSearchPhieuCoc = async (value) => {
        debouncedSearchPhieuCoc(value);
    }
    const handleSearchDriverInfo = async (value) => {
        debouncedSearchDriver(value);
    }
    const handleChangeSearch = (newValue) => {
        setValue(newValue);
    }

    const handleOnSelectPhieu = (value) => {
        const check = dataTable.find(obj => obj?.id === value?.id);
        if (!check) {
            const temp = value;
            delete temp?.label;
            const tempData = [...dataTable];
            tempData.push(temp);
            setDataTable([...tempData]);
            const tempMoney = parseInt(form.getFieldValue("thanhtien")) + parseInt(temp?.sotien ?? 0);
            form.setFieldValue("thanhtien", tempMoney);
        }
    }

    const handleChangeDiscount = (value) => {
        let total = (parseInt(data?.Net) * parseInt(data?.Price) / 1000) + caculTotalMoneyTraCoc() - (parseInt(data?.Net) * parseInt(data?.Price) / 1000) * parseFloat(value) / 100;
        if (form.getFieldValue("tiencoc")) {
            total = total + parseInt(form.getFieldValue("tiencoc"));
        }
        if (form.getFieldValue("hotro")) {
            total = total + parseInt(form.getFieldValue("hotro"));
        }
        form.setFieldValue("thanhtien", total);
    }

    const handleChangeTienCoc = (value) => {
        let total = (parseInt(data?.Net) * parseInt(data?.Price) / 1000) + caculTotalMoneyTraCoc() - parseInt(value);
        if (form.getFieldValue("giamgia")) {
            total = total - (parseInt(data?.Net) * parseInt(data?.Price) / 1000) * parseFloat(form.getFieldValue("giamgia")) / 100;;
        }
        if (form.getFieldValue("hotro")) {
            total = total + parseInt(form.getFieldValue("hotro"));
        }
        form.setFieldValue("thanhtien", total);
    }

    const handleChangeTienHoTro = (value) => {
        let total = (parseInt(data?.Net) * parseInt(data?.Price) / 1000) + caculTotalMoneyTraCoc() + parseInt(value);
        if (form.getFieldValue("giamgia")) {
            total = total - (parseInt(data?.Net) * parseInt(data?.Price) / 1000) * parseFloat(form.getFieldValue("giamgia")) / 100;;
        }
        if (form.getFieldValue("tiencoc")) {
            total = total - parseInt(form.getFieldValue("tiencoc"));
        }
        form.setFieldValue("thanhtien", total);
    }


    const handleOnSelectDriver = (item, option) => {
        form.setFieldsValue({
            driverName: option?.fullname,
            cmnd: option?.cmnd ?? '',
            ngaycap: option?.ngaycap ?? '',
            noicap: option?.noicap ?? '',
            biensoxe: data?.BSX ?? '',
            diachi: option?.diachi ?? '',
            phone: option?.phone ?? '',
            trangthaicoc: 'Đã nhận cọc'
        });

    }
    const renderDropdownSearchDriver = (menu) => {

        return (
            <>
                <Row>
                    <Col span={6}>
                        <p className="text-title-col pl-10">Tên LX</p>
                    </Col>
                    <Col span={4}>
                        <p className="text-title-col pl-10">CMND</p>
                    </Col>
                    <Col span={4}>
                        <p className="text-title-col">SĐT</p>
                    </Col>
                    <Col span={3}>
                        <p className="text-title-col">BSX</p>
                    </Col>
                    <Col span={7}>
                        <p className="text-title-col">Địa chỉ</p>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <div className="line-container"></div>
                    </Col>
                </Row>
                {menu}
            </>

        )
    }
    const renderDropdownSearchPhieuCoc = (menu) => {

        return (
            <>
                <Row>
                    <Col span={3}>
                        <p className="text-title-col pl-10">Số phiếu</p>
                    </Col>
                    <Col span={6}>
                        <p className="text-title-col pl-10">Tên LX</p>
                    </Col>
                    <Col span={4}>
                        <p className="text-title-col">CMND</p>
                    </Col>
                    <Col span={3}>
                        <p className="text-title-col">BSX</p>
                    </Col>
                    <Col span={4}>
                        <p className="text-title-col">Số tiền</p>
                    </Col>
                    <Col span={4}>
                        <p className="text-title-col">Ngày cọc</p>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <div className="line-container"></div>
                    </Col>
                </Row>
                {menu}
            </>

        )
    }
    const handlePrint = () => {
        window.open(`/print-phieu-chi`, '_blank');
    }
    const caculTotalMoneyTraCoc = () => {
        let totalMoney = 0;
        if (dataTable.length > 0) {
            totalMoney = dataTable.reduce((acc, obj) => acc + parseInt(obj.sotien), 0);
        }
        return totalMoney;
    }
    const submit = async (newData) => {
        let totalMoney = 0;
        let lstMaPhieuTra = [];
        if (dataTable.length > 0) {
            totalMoney = dataTable.reduce((acc, obj) => acc + parseInt(obj.sotien), 0);
            lstMaPhieuTra = dataTable.map(item => item.id)
        }
        const params = {
            hoten: newData?.driverName,
            cmnd: newData?.cmnd,
            ngaycap: newData?.ngaycap,
            noicap: newData?.noicap,
            diachi: newData?.diachi,
            biensoxe: newData?.biensoxe,
            loaihang: data?.Goods,
            sophieucan: data?.SoHD,
            khoiluong: newData?.khoiluong,
            dongia: newData?.dongia,
            giamgia: newData?.giamgia,
            thanhtien: newData?.thanhtien,
            tiencoc: newData?.tiencoc,
            tienhotro: newData?.hotro,
            thucnhan: newData?.thanhtien,
            ghichu: newData?.note,
            userid: userReducer?.data?.id,
            tientracoc: totalMoney,
            listMaPhieuTraCoc: lstMaPhieuTra
        };
        setLoading(true);
        const res = await saveBillInfo(params);
        setLoading(false);
        console.log("ress save", res);
        if (res && res?.status === 1) {
            message.success("Thêm phiếu chi thành công", 2);
            setIsShowPrint(true);
            dispatch(saveDataPrint({ ...params }));
            setIsDisable(true);
            const tempTable = [...dataTable];
            setDataTable([...tempTable.map(item => ({
                ...item,
                status: "2"
            }))])
        } else {
            message.error(res?.msg ?? "Thêm phiếu chi thất bại");
            setIsShowPrint(false);
            setIsDisable(false);
            dispatch(saveDataPrint(null));
        }

    };
    useEffect(() => {
        console.log("dataaaa", data);
        if (data) {
            form.resetFields();
            getDriverInfoFromBsxandName(data?.Seller, data?.BSX);
            setDataTable([]);
            setIsDisable(false);
            setIsShowPrint(false);
            setDataDriver([]);
            setValue();
            setValueMaPhieu();
            setDataPhieu([]);
            form.setFieldsValue({
                khoiluong: data.Net ? parseInt(data.Net) : 0,
                dongia: data.Price ? parseInt(data.Price) : 0,
                giamgia: 0,
                tiencoc: 0,
                hotro: 0,
                thanhtien: data?.Net && data?.Price ? (data.Net * data?.Price) / 1000 : 0
            });

        }
    }, [data])
    return (
        <Modal
            title={title}
            open={visible}
            onCancel={dismiss}
            width={'70%'}
            footer={null}

        >
            <Divider style={{marginTop: 0}}/>
            <Form
                form={form}
                name='add-bill'
                layout={'vertical'}
                onFinish={submit}
                onFinishFailed={handleFailed}
            >
                <Row>
                    <Col span={24}>
                        <Form.Item name={"searchDriver"}>
                            <Select
                                showSearch={true}
                                value={value}
                                placeholder={'Nhập Tên lái xe/CMND/SĐT/BSX'}
                                style={{ height: 40, width: 400 }}
                                popupMatchSelectWidth={700}
                                optionLabelProp="fullname"
                                defaultActiveFirstOption={false}
                                dropdownRender={renderDropdownSearchDriver}
                                suffixIcon={<SearchOutlined />}
                                filterOption={false}
                                onSearch={handleSearchDriverInfo}
                                onChange={handleChangeSearch}
                                notFoundContent={'Không có dữ liệu'}
                                options={dataDriver}
                                onSelect={handleOnSelectDriver}
                                disabled={isDisable}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Divider orientation="left" style={{marginTop: 0}}>
                    Thông tin lái xe
                </Divider>
                <Row gutter={16}>
                    <Col span={6}>
                        <Form.Item label="Họ tên lái xe" name="driverName" rules={[{ required: true, message: 'Vui lòng nhập họ tên' }]}>
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="CMND" name="cmnd" rules={[{ required: true, message: 'Vui lòng nhập cmnd' }]}>
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="Ngày cấp" name="ngaycap" rules={[{ required: true, message: 'Vui lòng nhập ngày cấp' }]}>
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Nơi cấp" name="noicap" rules={[{ required: true, message: 'Vui lòng nhập nơi cấp' }]}>
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="Biển số xe" name="biensoxe" rules={[{ required: true, message: 'Vui lòng nhập biển số xe' }]}>
                            <Input disabled />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item label="Địa chỉ" name="diachi" rules={[{ required: true, message: 'Vui lòng nhập địa chỉ' }]}>
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item label="SĐT" name="phone" >
                            <Input disabled />
                        </Form.Item>
                    </Col>

                    <Col span={11}>
                        <Form.Item label="Ghi chú" name="note">
                            <Input disabled={isDisable}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={4}>
                        <Form.Item label="Khối lượng" name="khoiluong">
                            <InputNumber
                                placeholder={'0'}
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                className="input-number-base"
                                min={0}
                                disabled
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="Đơn giá" name="dongia">
                            <InputNumber
                                placeholder={'0'}
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                className="input-number-base"
                                min={0}
                                disabled
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="Giảm giá %" name="giamgia">
                            <InputNumber
                                placeholder={'0'}
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                className="input-number-base"
                                min={0}
                                onChange={handleChangeDiscount}
                                disabled={isDisable}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="Tiền cọc" name="tiencoc">
                            <InputNumber
                                placeholder={'0'}
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                className="input-number-base"
                                min={0}
                                onChange={handleChangeTienCoc}
                                disabled={isDisable}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="Hỗ trợ" name="hotro">
                            <InputNumber
                                placeholder={'0'}
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                className="input-number-base"
                                min={0}
                                onChange={handleChangeTienHoTro}
                                disabled={isDisable}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="Thành tiền" name="thanhtien">
                            <InputNumber
                                placeholder={'0'}
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                className="input-number-base"
                                min={0}
                                disabled
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Divider orientation="left" style={{marginTop: 0}}>
                        Thông tin trả cọc
                    </Divider>
                    <Col span={8}>
                        <Form.Item name={'searchPhieu'}>
                            <Select
                                value={valueMaPhieu}
                                showSearch={true}
                                placeholder={'Nhập số phiếu'}
                                style={{ height: 40, width: '100%' }}
                                popupMatchSelectWidth={700}
                                optionLabelProp="tenlaixe"
                                defaultActiveFirstOption={false}
                                dropdownRender={renderDropdownSearchPhieuCoc}
                                suffixIcon={<SearchOutlined />}
                                filterOption={false}
                                onSearch={handleSearchPhieuCoc}
                                onChange={handleChangeSearchPhieuCoc}
                                notFoundContent={'Không có dữ liệu'}
                                options={dataPhieu}
                                onSelect={(value, option) => handleOnSelectPhieu(option)}
                                disabled={isDisable}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Table
                    columns={columns}
                    dataSource={dataTable}
                    pagination={false}
                    locale={{ emptyText: 'Không có dữ liệu' }}
                />

                <Row justify={"end"}>
                    <Space>
                        {
                            isShowPrint ?
                                <Button onClick={handlePrint}>
                                    In phiếu chi
                                </Button> : null

                        }

                        {
                            !isShowPrint ?
                                <Button type="primary" htmlType="submit">
                                    Tạo phiếu chi
                                </Button> : null
                        }

                    </Space>
                </Row>
            </Form>

            <LoadingBase loading={loading} />
        </Modal>
    )
}

export default React.memo(ModalAddBill);