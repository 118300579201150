import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import '../../css/addbook-page.css';
import { Button, Checkbox, Col, DatePicker, Divider, Form, Input, InputNumber, Modal, Row, Select, Space, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { DATE_FORMAT } from '../../constants/constants';
import { BASE_URL } from '../../settings/config';
import { getBase64 } from '../../utils/funcHelper';
import { addNewBook, checkBookInfoExist, deleteFileUpload, getBookTypes, getListDepartment, getListStatus, getListUserManager } from '../../services/apis';
import moment from 'moment';
import LoadingBase from '../../components/LoadingBase';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

const AddBookPage = () => {
    const userReducer = useSelector(state => state?.userReducer);
    const { state } = useLocation();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [listFile, setListFile] = useState([]);
    const [lstBookType, setLstBookType] = useState([]);
    const [lstDepartment, setLstDepartment] = useState([]);
    const [lstStatus, setLstStatus] = useState([]);
    const [isSetValue, setIsSetValue] = useState(true);
    const [showModalConfirm, setShowModalConfirm] = useState(false);
    const [warningInfo, setWarningInfo] = useState();
    const [dataSubmit, setDataSubmit] = useState();
    const uploadReducer = useSelector(state => state?.uploadReducer);
    const initialValues = {
        ratio: 0

    }
    const handleCallMultiAPIs = () => {
        setLoading(true);
        const arrAPI = [getListBookType(), getListDepart(), getDataStatus()];
        Promise.all(arrAPI)
            .then(res => { })
            .finally(() => setLoading(false))
            .catch(err => setLoading(false));
    }

    const getListBookType = async () => {
        const res = await getBookTypes();
        if (res && res?.status === 'success') {
            setLstBookType(res?.data ? [...res.data] : []);
        }
    }
    const getListDepart = async () => {
        const res = await getListDepartment();
        if (res && res?.status === 'success') {
            setLstDepartment(res?.data ? [...res.data] : []);
        }
    }
    
    const getDataStatus = async () => {
        const res = await getListStatus();
        if (res && res?.status === 'success') {
            setLstStatus(res?.data ? [...res.data] : []);
        }
    }
    const deleteFileAttach = async (name) => {
        const params = {
            filename: name
        };
        await deleteFileUpload(params);
    }
    const handleCheckExistInfo = async (data) => {
        const params = {
            fullnameOwner: data?.fullnameOwner,
            addressOwner: data?.addressOwner,
            fullnameOwner2: data?.fullnameOwner2 ?? null,
            addressOwner2: data?.addressOwner2 ?? null,
            bookAcreage: data?.bookAcreage
        };
        setLoading(true);
        const res = await checkBookInfoExist(params);
        setLoading(false);
        if (res && res?.status === 1) {
            setShowModalConfirm(true);
            setWarningInfo(res?.data?.[0]);
        } else {
            callApiSaveAddBook(data);
            setWarningInfo(null);
        }
    }

    const handleContinueSaveAddBook = () => {
        setShowModalConfirm(false);
        callApiSaveAddBook(dataSubmit);
    }

    const callApiSaveAddBook = async (data) => {
        try {
            const params = { ...data };
            params.isCheck = data.isCheck ? 1 : 0;
            params.bookDate = data.bookDate ? data.bookDate.format(DATE_FORMAT.YYYYMMDD_FORMAT) : null;
            params.cmndDateCoOwner = data.cmndDateCoOwner ? data.cmndDateCoOwner.format(DATE_FORMAT.YYYYMMDD_FORMAT) : null;
            params.cmndDateOwner = data.cmndDateOwner ? data.cmndDateOwner.format(DATE_FORMAT.YYYYMMDD_FORMAT) : null;
            params.cmndDateOwner2 = data.cmndDateOwner2 ? data.cmndDateOwner2.format(DATE_FORMAT.YYYYMMDD_FORMAT) : null;
            params.landExpireDate = data.landExpireDate ? data.landExpireDate.format(DATE_FORMAT.YYYYMMDD_FORMAT) : null;
            params.attachments = listFile.filter(file => file?.response?.status === 1).map(item => ({
                file_path: item.response.image_path
            }));
            setLoading(true);
            const res = await addNewBook(params);
            setLoading(false);
            setIsSetValue(false);
            if (res && res.status === 1) {
                message.success('Thêm mới sổ thành công', 4);
                form.resetFields();
                setListFile([]);
            } else {
                message.error(res?.msg ?? 'Có lỗi xảy ra. Vui lòng thử lại', 5);
            }

        } catch (error) {
            setLoading(false);
        }
    }
    const submit = async (data) => {
        setDataSubmit(data);
        handleCheckExistInfo(data);
    }
    const beforeUpload = (file, fileList) => {
        const isLt21M = file.size / 1024 / 1024 < 6;
        if (!isLt21M) {
            message.error('Ảnh phải có kích thước nhỏ hơn 5mb!');
            return false;
        }
        
        return true;
    };
    const handleCancel = () => setPreviewOpen(false);
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };
    const props = {
        action: BASE_URL + 'api/upload',
        onChange(info) {
            const { file, fileList } = info;

            setListFile([...fileList?.map(item => {
                let temp = item;
                if (!temp?.status) {
                    temp['status'] = 'error'
                }
                return temp;
            })]);
            // let temp = [...listFile];
            // fileList.forEach(item => {
            //     const check = temp.findIndex(obj => obj.uid === item?.uid);
            //     if (check > -1) {
            //         temp[check] = item;
            //         setListFile([...temp]);
            //     } else {
            //         const newList = [...listFile];
            //         newList.push(file);
            //         setListFile([...newList]);
            //     }
            // });
            // const validFileList = fileList.filter(file => file.status && file.status !== 'removed');
            
            // const temp = [...listFile];
            // for (let i = 0; i < validFileList.length; i++) {
            //     const file = validFileList[i];
            //     const checkIndex = temp.findIndex(obj => obj.uid === file?.uid);
            //     if (checkIndex < 0) {
            //         const newList = [...listFile];
            //         newList.push(file);
            //         setListFile([...newList]);
            //     }
            //     if (file?.status) {
            //         if (file?.status !== 'removed') {
            //             if (checkIndex > -1) {
            //                 if (file.status !== 'done') {
            //                     temp[checkIndex] = file;
            //                     setListFile([...temp]);
            //                 } else {
            //                     if (file?.response && file.response?.status === 1) {
            //                         temp[checkIndex] = file;
            //                         setListFile([...temp]);
            //                         message.success(`${file.name} đã được upload thành công`);
            //                     } else {
            //                         temp[checkIndex].status = 'error';
            //                         setListFile([...temp]);
            //                         message.error(file?.response?.msg ?? 'Upload file thất bại');
            //                     }
            //                 }

            //             } else {
            //                 if (file?.status) {
            //                     const newList = [...listFile];
            //                     newList.push(file);
            //                     setListFile([...newList]);
            //                 }
            //             }
            //         } else {
            //             if (checkIndex > -1) {
            //                 temp.splice(checkIndex, 1);
            //                 setListFile([...temp]);
            //                 deleteFileAttach(file?.response?.image_path);
            //             }
            //         }
            //     }

            //     if (info.file.status === 'error') {
            //         message.error(`${file.name} Upload file thất bại`);
            //     }
            // }
        },

    };
    const onResetFeilds = () => {
        setListFile([]);
    }
    const dissmissModal = () => {
        setShowModalConfirm(false);
    }
    const handleChangeKLDauKy = (value) => {
        const rs = form.getFieldValue('volumeTotal') ? form.getFieldValue('volumeTotal') - value : -value;
        form.setFieldsValue({
            remainingWeight: rs
        })
    }
    const handleOnChangeDienTichTrong = (value) => {
        const result = value * form.getFieldValue('ratio');
        form.setFieldsValue({
            volumeTotal: result,
        });

    }
    const handleOnChangeRatio = (value) => {
        const result = value * form.getFieldValue('woodAcreage');
        form.setFieldsValue({
            volumeTotal: result,
        });
    }

    useEffect(() => {
        handleCallMultiAPIs();
    }, [])

    useEffect(() => {
        if (state && form && lstDepartment && isSetValue) {
            form.setFieldsValue({
                fullnameOwner: state?.book_owner_name ?? '',
                cmndOwner: state?.cmnd_book_owner ?? '',
                cmndDateOwner: state?.cmnd_date_owner ? dayjs(state?.cmnd_date_owner) : null,
                cmndPlaceOwner: state?.cmnd_place_owner ?? '',
                addressOwner: state?.book_owner_address ?? '',
                fullnameOwner2: state?.book_owner_name2 ?? '',
                cmndOwner2: state?.cmnd_book_owner2 ?? '',
                cmndDateOwner2: state?.cmnd_date_owner2 ? dayjs(state?.cmnd_date_owner2) : null,
                cmndPlaceOwner2: state?.cmnd_place_owner2 ?? '',
                addressOwner2: state?.book_owner_address2 ?? '',
                bookTypeId: state?.book_typeId ? parseInt(state?.book_typeId) : '',
                useLand: state?.use_land ?? '',
                ratio: state?.ratio ? parseFloat(state?.ratio) : null,
                bookDepartmentId: state?.departmentId ?? null,
                bookLicensePlates: state?.book_license_plates ?? '',
                bookAddress: state?.book_address ?? '',
                note: state?.note ?? '',
                fullnameCoOwner: state?.co_owner_name ?? '',
                cmndCoOwner: state?.cmnd_co_owner ?? '',
                cmndDateCoOwner: state?.cmnd_date_co_owner ? dayjs(state?.cmnd_date_co_owner) : null,
                cmndPlaceCoOwner: state?.cmnd_place_co_owner ?? '',
            });
        } else if (form) {
            form.setFieldValue("bookCode", moment().format("YYMMDDHHmmss"));
        }
    }, [state, lstDepartment, lstStatus, lstBookType]);
    return (
        <div className='container-content'>
            <LoadingBase loading={loading} />
            <Form
                form={form}
                name='add-book'
                onFinish={submit}
                layout={'vertical'}
                initialValues={initialValues}
                onReset={onResetFeilds}
            >
                <Divider orientation="left" ><span className='title-divider'>Thông tin chủ rừng</span></Divider>
                <Row gutter={16}>
                    <Col span={5}>
                        <Form.Item label="Họ tên thứ nhất" name="fullnameOwner" rules={[{ required: true, message: 'Vui lòng nhập tên!' }]}>
                            <Input placeholder='Họ tên thứ nhất' />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="CMND thứ nhất" name="cmndOwner">
                            <Input placeholder='CMND thứ nhất' />
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item label="Ngày cấp 1" name="cmndDateOwner">
                            <DatePicker placeholder='DD/MM/YYYY' className='date-picker' format={DATE_FORMAT.DDMMYYYY_FORMAT} />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="Nơi cấp 1" name="cmndPlaceOwner">
                            <Input placeholder='Nơi cấp 1' />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Địa chỉ 1" name="addressOwner" rules={[{ required: true, message: 'Vui lòng nhập địa chỉ!' }]}>
                            <Input placeholder='Địa chỉ 1' />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={5}>
                        <Form.Item label="Họ tên thứ 2" name="fullnameOwner2">
                            <Input placeholder='Họ tên thứ 2' />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="CMND thứ 2" name="cmndOwner2">
                            <Input placeholder='CMND thứ 2' />
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item label="Ngày cấp 2" name="cmndDateOwner2">
                            <DatePicker placeholder='DD/MM/YYYY' className='date-picker' format={DATE_FORMAT.DDMMYYYY_FORMAT} />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="Nơi cấp 2" name="cmndPlaceOwner2">
                            <Input placeholder='Nơi cấp 2' />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Địa chỉ 2" name="addressOwner2">
                            <Input placeholder='Địa chỉ 2' />
                        </Form.Item>
                    </Col>
                </Row>
                <Divider orientation="left" ><span className='title-divider'>Thông tin sổ đỏ</span></Divider>
                <Row gutter={16}>
                    <Col span={4}>
                        <Form.Item label="Mã sổ" name="bookCode" rules={[{ required: true, message: 'Vui lòng nhập mã sổ' }]}>
                            <Input placeholder='Nhập mã sổ' />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item label="Loại sổ" name="bookTypeId">
                            <Select
                                showSearch
                                className='custom-select'
                                placeholder="Chọn loại sổ"
                                optionFilterProp="children"
                                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                options={lstBookType.map(type => ({
                                    ...type,
                                    value: parseInt(type?.id),
                                    label: type.type_name
                                }))}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item label="Ngày cấp" name="bookDate">
                            <DatePicker placeholder='DD/MM/YYYY' className='date-picker' format={DATE_FORMAT.DDMMYYYY_FORMAT} />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item label="Thời hạn sử dụng đất" name="landExpireDate">
                            <DatePicker placeholder='DD/MM/YYYY' className='date-picker' format={DATE_FORMAT.DDMMYYYY_FORMAT} />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item label="Mục đích sử dụng" name="useLand">
                            <Input placeholder='Mục đích sử dụng đất' />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={4}>
                        <Form.Item label="Diện tích sổ (m2)" name="bookAcreage" rules={[{ required: true, message: 'Vui lòng nhập diện tích sổ' }]}>
                            <InputNumber
                                placeholder={'0'}
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                className="input-number-base"
                                min={0}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="Diện tích trồng (m2)" name="woodAcreage" rules={[{ required: true, message: 'Vui lòng nhập diện tích' }]}>
                            <InputNumber
                                placeholder={'0'}
                                onChange={(value) => handleOnChangeDienTichTrong(value)}
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                className="input-number-base"
                                min={0}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="Quy đổi(m2->Kg)" name="ratio" rules={[{ required: true, message: 'Vui lòng nhập quy đổi' }]}>
                            <InputNumber
                                placeholder={'0'}
                                onChange={(value) => handleOnChangeRatio(value)}
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                className="input-number-base"
                                min={0}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="KL được khai thác(Kg)" name="volumeTotal" rules={[{ required: true, message: 'Vui lòng nhập tổng khai thác' }]}>
                            <InputNumber
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                className="input-number-base"
                                disabled
                                min={0}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="KL đầu kỳ (Kg)" name="volumeDauky" rules={[{ required: true, message: 'Vui lòng số sử dụng đầu kỳ' }]}>
                            <InputNumber
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                className="input-number-base"
                                onChange={handleChangeKLDauKy}
                                min={0}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="KL còn lại (Kg)" name="remainingWeight">
                            <InputNumber
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                className="input-number-base"
                                disabled
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={7}>
                        <Form.Item label="Đơn vị quản lý" name="bookDepartmentId">
                            <Select
                                showSearch
                                className='custom-select'
                                placeholder="Chọn đơn vị"
                                optionFilterProp="children"
                                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                options={lstDepartment.map(item => ({
                                    ...item,
                                    value: item?.id,
                                    label: item.name
                                }))}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item label="Biển số xe" name="bookLicensePlates">
                            <Input placeholder='...' />
                        </Form.Item>
                    </Col>
                    <Col span={7}>
                        <Form.Item label="Địa chỉ" name="bookAddress">
                            <Input placeholder='Địa chỉ sổ' />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item label="Ghi chú" name="note">
                            <Input placeholder='Ghi chú' />
                        </Form.Item>
                    </Col>
                </Row>
                <Divider orientation="left" ><span className='title-divider'>Thông tin đồng sở hữu</span></Divider>
                <Row gutter={16}>
                    <Col span={6}>
                        <Form.Item label="Họ tên" name="fullnameCoOwner">
                            <Input placeholder='Nhập họ tên đồng sở hữu' />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Số CMND" name="cmndCoOwner">
                            <Input placeholder='CMND chủ rừng' />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Ngày cấp" name="cmndDateCoOwner">
                            <DatePicker placeholder='DD/MM/YYYY' className='date-picker' format={DATE_FORMAT.DDMMYYYY_FORMAT} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Nơi cấp" name="cmndPlaceCoOwner">
                            <Input placeholder='Nơi cấp' />
                        </Form.Item>
                    </Col>
                </Row>
                <Divider orientation="left" ><span className='title-divider'>Trạng thái hồ sơ</span></Divider>
                <Row gutter={16}>
                    <Col span={6}>
                        <Form.Item initialValue={1} label="Trạng thái sổ" name="bookStatusCode" rules={[{ required: true, message: 'Vui lòng chọn trạng thái' }]}>
                            <Select
                                className='custom-select'
                                disabled
                                options={lstStatus.map(item => ({
                                    ...item,
                                    value: parseInt(item?.id),
                                    label: item.status_name
                                }))}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item initialValue={parseInt(userReducer?.data?.id)} label="Người tạo" name="createById">
                            <Select
                                disabled
                                className='custom-select'
                                optionFilterProp="children"
                                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                options={[
                                    {
                                        value: parseInt(userReducer?.data?.id),
                                        label: userReducer?.data?.fullname,
                                    },
                                ]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name="isCheck" valuePropName="checked" label=" ">
                            <Checkbox >Đã kiểm tra thực tế</Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={6}>
                        <Upload {...props}
                            listType='picture'
                            maxCount={10}
                            fileList={listFile}
                            onPreview={handlePreview}
                            multiple
                            beforeUpload={beforeUpload}
                            accept='image/png,image/jpg,image/jpeg,.pdf'
                            onRemove={(file) => {
                                const index = listFile.indexOf(file);
                                const newFileList = listFile.slice();
                                deleteFileAttach(file?.response?.image_path);
                                newFileList.splice(index, 1);
                                setListFile(newFileList);
                                
                            }}
                        >
                            <Button icon={<UploadOutlined />}>{`Đính kèm file (10 files, 1 file <= 5Mb)`}</Button>
                        </Upload>
                    </Col>
                </Row>
                <Row justify={'center'}>
                    <Col>
                        <Form.Item>
                            <Space>
                                <Button htmlType="reset">Nhập lại</Button>
                                <Button type="primary" htmlType="submit">
                                    Gửi duyệt hồ sơ
                                </Button>

                            </Space>
                        </Form.Item>
                    </Col>

                </Row>
            </Form>
            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img
                    alt="example"
                    style={{
                        width: '100%',
                    }}
                    src={previewImage}
                />
            </Modal>
            <Modal
                open={showModalConfirm}
                title={"Thông báo trùng thông tin"}
                footer={null} 
                onCancel={dissmissModal}
                centered
            >
                <Row>
                    <span>Thông tin bị trùng với sổ:</span>
                </Row>
                {
                    warningInfo ? 
                    <Row>
                        <span>{warningInfo?.book_owner_name} - {warningInfo?.book_owner_address} - {warningInfo?.book_acreage} - {warningInfo?.created_time}</span>
                    </Row> : null
                }
                <Row justify="end">
                    <Space>
                        <Button onClick={dissmissModal}>
                            Đóng
                        </Button>
                        <Button danger onClick={handleContinueSaveAddBook}>
                            Tiếp tục
                        </Button>
                    </Space>
                </Row>
            </Modal>
        </div>
    );
};
export default AddBookPage;