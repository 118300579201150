import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row, Space, theme, Table, message } from 'antd';
import LoadingBase from '../../components/LoadingBase';
import { addNewDepartment, getListDepartment } from '../../services/apis';
import '../../css/listbook-page.css';
import AddDataModal from '../../components/AddDataModal';

const DepartmentCategoryPage = () => {
    const { token } = theme.useToken();
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const columns = [
        {
            title: 'Mã',
            dataIndex: 'id',
            key: 'id',
            width: 150,
            align: 'center',
        },
        {
            title: 'Tên phòng ban',
            dataIndex: 'name',
            key: 'name',
            render: (text) => <div style={{paddingTop: 8, paddingBottom: 8}}>{text}</div>
        },
        
    ];
    const formStyle = {
        maxWidth: 'none',
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
    };

    const handleAddData = async (name) => {
        console.log("name", name);
        if (!name || name.length === 0) {
            return;
        }
        setShowModal(false);
        const params = {
            name: name
        };
        setLoading(true);
        const res = await addNewDepartment(params);
        setLoading(false);
        if (res && res.status === 1) {
            message.success("Thêm mới thành công", 5);
            getDataDepartment();
        } else {
            message.error(res?.msg ?? 'Có lỗi xảy ra', 5);
        }
    }
    const handleCancel = () => {
        setShowModal(false);
    }
    const getDataDepartment = async (keyword) => {
        const params = {
            name: keyword
        }
        setLoading(true);
        const res = await getListDepartment(params);
        setLoading(false);
        if (res && res?.status === 'success') {
            setData(res?.data ? [...res.data] : []);
        } else {
            setData([]);
        }
    }
    
    const onFinish = async (data) => {
        getDataDepartment(data.name);
    };
    useEffect(() => {
        getDataDepartment();
    }, [])
    return (
        <div className='container-content'>
            <Form form={form} name="advanced_search" style={formStyle} onFinish={onFinish}>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            name={'name'}
                        >
                            <Input placeholder={'Tên phòng ban'} />
                        </Form.Item>
                    </Col>
                    
                    
                    <Col span={4}>
                        <Space size="small">
                            <Button type="primary" htmlType="submit">
                                Tìm kiếm
                            </Button>
                            <Button
                                onClick={() => {
                                    setShowModal(true);
                                }}
                            >
                                Thêm mới
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Table
                className='table-book'
                columns={columns}
                dataSource={data}
                pagination={{
                    pageSize: 50
                }}
                rowKey={(record) => record?.id}
            />
            <AddDataModal visible={showModal} onCancel={handleCancel} onSave={handleAddData} title={"Thêm mới phòng ban"}/>
            <LoadingBase loading={loading} />
        </div>

    );
};
export default DepartmentCategoryPage;