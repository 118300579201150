import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Input, Row, Space, theme, Table, message, Select, DatePicker, Tooltip, Popconfirm } from 'antd';
import LoadingBase from '../../components/LoadingBase';
import { exportWeighingToExcel, getListWeighingSlip, getListWoodType, searchBookInfo, updateWeighingSlipById } from '../../services/apis';
import '../../css/listbook-page.css';
import { formatNumber } from '../../utils/funcHelper';
import WeighingModal from '../../components/WeighingModal';
import { useSelector } from 'react-redux';
import { SearchOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import FooterTable from '../../components/FooterTable';
import { useLocation } from 'react-router-dom';

const WeighingSlipPage = () => {
    const userReducer = useSelector(state => state?.userReducer);
    const { token } = theme.useToken();
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalPage, setTotalPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [itemSelected, setItemSelected] = useState();
    const [listPrice, setListPrice] = useState([]);
    const [valueBookSearch, setValueBookSearch] = useState();
    const [lstBook, setLstBook] = useState([]);
    const [linkDownload, setLinkDownload] = useState('');
    const downloadLinkRef = useRef();
    const { state } = useLocation();
    const { RangePicker } = DatePicker;
    const columns = [
        {
            title: 'Mã phiếu',
            dataIndex: 'SoHD',
            key: 'SoHD',
            align: 'center',
            render: (text, record, index) => (<a onClick={() => handleOnClickItem(record)}>{text}</a>)
        },
        {
            title: 'Người bán',
            dataIndex: 'Seller',
            width: 200,
            render: (text, record, index) => (<div style={{ textAlign: 'left', paddingLeft: 16 }}>{text}</div>)
        },
        {
            title: 'Mã sổ',
            dataIndex: 'book_code',
            align: 'center',
            render: (text, record, index) => (<div style={{ textAlign: 'left', paddingLeft: 16 }}>{text}</div>)
        },
        {
            title: 'ID sổ',
            dataIndex: 'book_id',
            align: 'center',
            render: (text, record, index) => (<div style={{ textAlign: 'left', paddingLeft: 16 }}>{text}</div>)
        },
        {
            title: 'Biển số xe',
            dataIndex: 'BSX',
            render: (text, record, index) => (<div style={{ textAlign: 'left', paddingLeft: 16 }}>{text}</div>)
        },
        {
            title: 'KL tổng(kg)',
            dataIndex: 'Mass',
            align: 'center',
            render: (text, record, index) => (<div style={{ textAlign: 'right', paddingRight: 8 }}>{formatNumber(parseInt(text), 0)}</div>)
        },
        {
            title: 'KL hàng(kg)',
            dataIndex: 'Net',
            align: 'center',
            render: (text, record, index) => (<div style={{ textAlign: 'right', paddingRight: 8 }}>{formatNumber(parseInt(text), 0)}</div>)
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            align: 'center',
            render: (text, record, index) => (text === '1' ? <a>Đã có sổ</a> : <div style={{ color: 'red' }}>Chưa có sổ</div>)
        },
        {
            title: 'Ngày cân',
            dataIndex: 'SumDate',
            align: 'center',

        },
        {
            title: 'Hành động',
            width: 100,
            render: (text, record, index) => (
                <Space>
                    <Button type='link' onClick={() => handleOnClickItem(record)}>{record?.status !== '1' ? 'Sửa' : 'Xem'}</Button>
                    {
                        record?.status === '1' ? 
                            <Popconfirm
                                title="Bỏ link phiếu cân"
                                description="Bạn có chắc chắn bỏ link phiếu cân với sổ này?"
                                okText="Yes"
                                cancelText="No"
                                onConfirm={() => handleUnLink(record)}
                            >
                                <Button danger type='link'>Bỏ link</Button>
                            </Popconfirm>
                            : null
                    }
                </Space>
            )
        }

    ];
    const statusArr = [
        {
            value: 1,
            label: 'Đã có sổ'
        },
        {
            value: 0,
            label: 'Chưa có sổ'
        }
    ];
    const typeArr = [
        {
            value: 'Cân nhập',
            label: 'Cân nhập'
        },
        {
            value: 'Cân xuất',
            label: 'Cân xuất'
        }
        ,
        {
            value: 'Dịch vụ',
            label: 'Dịch vụ'
        }
    ];
    const formStyle = {
        maxWidth: 'none',
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 10
    };
    const getListPrice = async () => {
        const res = await getListWoodType();
        if (res && res.status === 'success') {
            setListPrice([...res?.data]);
        }
    }

    const handleOnClickItem = (item) => {
        const obj = listPrice.find(price => price.name === item.Goods);
        const temp = {
            ...item,
            Price: obj?.price ?? 0
        };
        setItemSelected(temp);
        setShowModal(true);
    }
    const handleUnLink = async (data) => {
        console.log("dataaaa", data);
        const params = {
            id: data?.id,
            soHD: data?.SoHD ?? '',
            bookCode: '',
            bookId: null,
            reason: `Bỏ link phiếu cân khỏi sổ có id ${data?.book_id}`,
            userId: userReducer?.data?.id ?? '',
            status: 0,
            price: data?.Price,
            total: data?.Total
        };
        setLoading(true);
        const res = await updateWeighingSlipById(params);
        setLoading(false);
        if (res && res?.status === "success") {
            message.success("Cập nhật thành công", 3);
            getDataWeighingSlip(currentPage);
        } else {
            message.error("Cập nhật thành công", 3);
        }
    }
    const handleNext = () => {
        if (loading) {
            return;
        }
        const page = currentPage;
        setCurrentPage(page + 1);
        getDataWeighingSlip(page + 1);
    }

    const handleSaveWeighingSlip = async (data) => {
        setShowModal(false);
        const params = {
            id: data?.id,
            soHD: data?.SoHD ?? '',
            bookCode: data?.book_code ?? '',
            bookId: data?.book_id ?? null,
            reason: 'Thêm mã sổ cho phiếu cân',
            userId: userReducer?.data?.id ?? '',
            status: data?.book_id ? 1 : 0,
            price: data?.Price,
            total: data?.Total
        };
        setLoading(true);
        const res = await updateWeighingSlipById(params);
        setLoading(false);
        if (res && res?.status === "success") {
            message.success("Cập nhật thành công", 3);
            getDataWeighingSlip(1);
        } else {
            message.error("Cập nhật thành công", 3);
        }

    }
    const handlePrev = () => {
        if (loading) {
            return;
        }
        const page = currentPage;
        setCurrentPage(page - 1);
        getDataWeighingSlip(page - 1);
    }

    const handleOnSelect = (value) => {
        setCurrentPage(value);
        getDataWeighingSlip(value)
    }

    const handleCancel = () => {
        setShowModal(false);
    }

    const exportReportToExcel = async () => {
        try {
            const params = {
                keyword: form.getFieldValue("keyword") ?? null,
                status: form.getFieldValue("status") ?? null,
                fromDate: form.getFieldValue("date") ? dayjs(form.getFieldValue("date")?.[0]).format("DD/MM/YYYY HH:mm:ss") : null,
                toDate: form.getFieldValue("date") ? dayjs(form.getFieldValue("date")?.[1]).format("DD/MM/YYYY HH:mm:ss") : null,
                bookId: form.getFieldValue("bookId") ?? null,
                type: form.getFieldValue("type") ?? null,
            };
            setLoading(true);
            const res = await exportWeighingToExcel(params);
            setLoading(false);
            if (res && res.status === true) {
                setLinkDownload(res?.download_link);
                window.open(res?.download_link, '_blank');
            } else {
                message.error(res?.msg ?? 'Xuất excel thất bại. Vui lòng thử lại sau');
            }
        } catch (error) {
            setLoading(false);
        }
    }

    const getDataWeighingSlip = async (currentPage) => {
        const params = {
            limit: 50,
            keyword: form.getFieldValue("keyword") ?? null,
            status: form.getFieldValue("status") ?? null,
            fromDate: form.getFieldValue("date") ? dayjs(form.getFieldValue("date")?.[0]).format("DD/MM/YYYY HH:mm:ss") : null,
            toDate: form.getFieldValue("date") ? dayjs(form.getFieldValue("date")?.[1]).format("DD/MM/YYYY HH:mm:ss") : null,
            bookId: form.getFieldValue("bookId") ?? null,
            type: form.getFieldValue("type") ?? null,
            currentPage: currentPage
        };
        setLoading(true);
        const res = await getListWeighingSlip(params);
        setLoading(false);
        if (res && res?.data) {
            setData([...res.data]);
            setTotalPage(res?.totalPages);
        } else {
            setData([]);
            setTotalPage(0);
        }
    }

    const handleChangeSearch = (val) => {
        setValueBookSearch(val);
    }
    const renderLabel = (item) => {
        return (
            <Row>
                <Col span={6}>
                    <p>{item.book_code}</p>
                </Col>
                <Col span={9}>
                    <p>{item.book_owner_name}</p>
                </Col>
                <Col span={5}>
                    <p>{item.cmnd_book_owner}</p>
                </Col>
                <Col span={4}>
                    <p>{item.book_license_plates}</p>
                </Col>
            </Row>
        )
    }
    const onSearch = async (keyword) => {
        let params = {
            limit: 10,
            currentPage: 1,
            keyword: keyword ?? ''
        };
        const res = await searchBookInfo(params);
        if (res && res?.data) {
            setLstBook([...res.data?.map(item => ({
                ...item,
                value: item.book_id,
                label: renderLabel(item),
                child: `${item.book_code} - ${item.book_owner_name}`
            }))]);
        } else {
            setLstBook([]);
        }
    }
    const renderDropdown = (menu) => {

        return (
            <>
                <Row>
                    <Col span={6}>
                        <p className="text-title-col pl-10">Mã sổ</p>
                    </Col>
                    <Col span={9}>
                        <p className="text-title-col pl-10">Chủ sổ</p>
                    </Col>
                    <Col span={5}>
                        <p className="text-title-col">CMND</p>
                    </Col>
                    <Col span={4}>
                        <p className="text-title-col">BSX</p>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <div className="line-container"></div>
                    </Col>
                </Row>
                {menu}
            </>

        )
    }

    const onFinish = async (data) => {
        setCurrentPage(1);
        getDataWeighingSlip(1);
    };
    useEffect(() => {
        if (state) {
            if (form) {
                form.setFieldValue("type", state?.type);
                form.setFieldValue("date", [dayjs(state?.from), dayjs(state?.to)])
            }
        } else {
            if (form) {
                form.setFieldValue("type", "Cân nhập")
            }
        }
        getListPrice();
        getDataWeighingSlip(1);
    }, [state, form])
    return (
        <div className='container-content'>

            <Form form={form} name="advanced_search" style={formStyle} onFinish={onFinish}>
                <Row gutter={24}>
                    <Col span={4}>
                        <Form.Item
                            name={'keyword'}
                        >
                            <Input placeholder={'Mã phiếu/ Tên/ BSX'} />
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item
                            name={'status'}
                        >
                            <Select
                                placeholder="Trạng thái"
                                style={{ height: 40, width: '100%' }}
                                options={statusArr}
                                allowClear
                            />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item
                            name={'type'}
                        >
                            <Select
                                placeholder="Loại"
                                style={{ height: 40, width: '100%' }}
                                options={typeArr}
                                allowClear
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name={'date'}
                        >
                            <RangePicker
                                style={{ height: 40, width: '100%' }}
                                placeholder={['Bắt đầu', 'Kết thúc']}
                                showTime
                                format={"DD/MM/YYYY HH:mm:ss"}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item name={"bookId"}>
                            <Select
                                showSearch={true}
                                value={valueBookSearch}
                                placeholder={'Mã sổ/Tên /CMND chủ'}
                                style={{ width: '100%', height: 40 }}
                                popupMatchSelectWidth={600}
                                optionLabelProp="child"
                                defaultActiveFirstOption={false}
                                dropdownRender={renderDropdown}
                                suffixIcon={<SearchOutlined />}
                                filterOption={false}
                                onSearch={onSearch}
                                onChange={handleChangeSearch}
                                notFoundContent={'Không có dữ liệu'}
                                options={lstBook}
                                allowClear
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Space size="small">
                            <Button type="primary" htmlType="submit">
                                Tìm kiếm
                            </Button>
                            <Button type="primary" danger onClick={exportReportToExcel}>
                                Xuất Excel
                            </Button>
                            <a href={linkDownload} ref={downloadLinkRef} download="Bao_cao_so_do.xlsx" style={{ display: 'none' }} />
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Table
                id='table-weighing'
                className='table-book'
                columns={columns}
                dataSource={data}
                pagination={false}
                rowKey={(record) => record?.id}
                rowClassName={'row-table-book'}
            />
            <FooterTable
                totalRecord={data?.length}
                totalPage={totalPage}
                currentPage={currentPage}
                handleNext={handleNext}
                handlePrev={handlePrev}
                onSelect={handleOnSelect}
            >
                <Col span={5}>
                    <span className="txt-title-footer">Tổng KL cân: {formatNumber(data?.reduce((totalWeight, currentValue) => totalWeight + parseInt(currentValue.Mass), 0), 0)}Kg</span>
                </Col>
                <Col span={5}>
                    <span className="txt-title-footer">Tổng KL hàng: {formatNumber(data?.reduce((totalWeight, currentValue) => totalWeight + parseInt(currentValue.Net), 0), 0)}Kg</span>
                </Col>
            </FooterTable>

            <WeighingModal
                visible={showModal}
                data={itemSelected}
                title={itemSelected?.In_out === 'Cân nhập' ? 'Thông tin phiếu cân nhập' : 'Thông tin phiếu cân xuất'}
                onCancel={handleCancel}
                onSave={handleSaveWeighingSlip}
            />
            <LoadingBase loading={loading} />
        </div>

    );
};
export default WeighingSlipPage;