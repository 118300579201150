import React, { useState } from 'react';
import { getDataBillByCode, saveBillInfo } from '../../services/apis';
import { Button, Col, Form, Input, Modal, Row, Space, Table, message, theme } from 'antd';
import dayjs from 'dayjs';
import LoadingBase from '../../components/LoadingBase';
import { formatNumber } from '../../utils/funcHelper';
import "../../css/returnmoney.css";
import { useDispatch, useSelector } from 'react-redux';
import { saveDataPrint } from '../../redux/action';

const ReturnMoneyPage = () => {
    const userReducer = useSelector(state => state?.userReducer);
    const { token } = theme.useToken();
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isShowModal, setIsShowModal] = useState(false);
    const [item, setItem] = useState();
    const [note, setNote] = useState();
    const dispatch = useDispatch();
    const columns = [
        {
            title: 'Số phiếu',
            dataIndex: 'sophieucan',
            align: 'center',
        },
        {
            title: 'Lái xe',
            dataIndex: 'hoten',
            key: 'name',
            render: (text, record, index) => (<a>{text}</a>)
        },
        {
            title: 'CMND',
            dataIndex: 'cmnd',
            align: 'center',
        },
        {
            title: 'BSX',
            dataIndex: 'biensoxe',
            align: 'center',
        },
        {
            title: 'Người chi',
            dataIndex: 'create_fullname',
            align: 'center',
        },
        {
            title: 'Tiền cọc',
            dataIndex: 'tiencoc',
            align: 'center',
            render: (text, record, index) => (<div className='format-number-table'>{formatNumber(parseInt(text), 0)}</div>)
        },
        {
            title: 'Trả cọc',
            dataIndex: 'tientracoc',
            align: 'center',
            render: (text, record, index) => (<div className='format-number-table'>{formatNumber(parseInt(text), 0)}</div>)
        },
        {
            title: 'Hỗ trợ',
            dataIndex: 'tienhotro',
            align: 'center',
            render: (text, record, index) => (<div className='format-number-table'>{formatNumber(parseInt(text), 0)}</div>)
        },
        {
            title: 'Tổng chi',
            dataIndex: 'thucnhan',
            align: 'center',
            render: (text, record, index) => (<div className='format-number-table'>{formatNumber(parseInt(text), 0)}</div>)
        },
        {
            title: 'Ngày',
            dataIndex: 'createtime',
            align: 'center',
            render: (text, record, index) => (<div>{dayjs(text).format("DD/MM/YYYY")}</div>)
        },
        {
            title: 'Hành động',
            dataIndex: 'action',
            align: 'center',
            render: (text, record, index) => <Button type="primary" onClick={() => handlePrintBill(record)}>In phiếu</Button>
        }

    ];
    const expandedRowRender = (record) => {
        const columnDetails = [
            {
                title: 'Loại',
                dataIndex: 'status',
                key: 'status',
                render: (text) => <div>{text === "1" ? "Phiếu cọc" : "Phiếu trả cọc"}</div>,
            },
            {
                title: 'Tên LX',
                dataIndex: 'tenlaixe',
                key: 'tenlaixe',
            },
            {
                title: 'CMND',
                dataIndex: 'cmnd',
                key: 'cmnd'
            },
            {
                title: 'Số tiền',
                dataIndex: 'sotien',
                key: 'sotien',
                align: 'center',
                render: (text, record, index) => (<p className='value-column'>{formatNumber(parseInt(text))}</p>)
            },
            {
                title: 'Mã phiếu cân',
                dataIndex: 'maphieucan',
                key: 'maphieucan'
            },
            {
                title: 'Trạng thái',
                dataIndex: 'status',
                key: 'status',
                render: (text) => <div style={text === "1" ? { color: 'red' } : { color: 'blue' }}>{text === "1" ? "Đã thu cọc" : (text === "2" ? "Đã trả cọc" : "Đã huỷ")}</div>,
            },
            {
                title: 'Hành động',
                dataIndex: 'status',
                key: 'action',
                render: (text, rc) => text === "1" ? <Button type="primary" danger onClick={() => handleClickReturn(rc)}>Trả cọc</Button> : null,
            },
        ];

        return <Table columns={columnDetails} dataSource={record?.lstDetails ?? []} pagination={false} rowKey={(e) => e?.id}/>;
    };
    const formStyle = {
        maxWidth: 'none',
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 10
    };
    const getDataReport = async (keyword) => {
        const params = {
            code: keyword
        }
        setLoading(true);
        const res = await getDataBillByCode(params);
        setLoading(false);
        console.log("res bill", res);

        if (res && res?.status === "success" && res?.data) {
            setData([...res?.data]);
        } else {
            setData([]);
        }
    }

    const onFinish = (data) => {
        getDataReport(data?.keyword);
    }
    const handleChangeNote = (e) => {
        setNote(e?.target?.value);
    }
    const onCancel = () => {
        setIsShowModal(false);
    }
    const handleClickReturn = (data) => {
        setItem(data);
        setIsShowModal(true);
    }
    
    const handlePrintBill = (data) => {
        dispatch(saveDataPrint({...data}));
        window.open("/print-phieu-chi", "_blank");
    }

    const confirmReturnMoney = async () => {
        setIsShowModal(false);
        const params = {
            hoten: item?.tenlaixe,
            cmnd: item?.cmnd,
            ngaycap: item?.ngaycap,
            noicap: item?.noicap,
            diachi: item?.diachi,
            biensoxe: item?.bsx,
            loaihang: "",
            sophieucan: item?.maphieucan,
            khoiluong: 0,
            dongia: 0,
            giamgia: 0,
            thanhtien: item?.sotien,
            tiencoc: 0,
            tienhotro: 0,
            thucnhan: item?.sotien,
            ghichu: note,
            userid: userReducer?.data?.id,
            tientracoc: item?.sotien,
            listMaPhieuTraCoc: [item?.id]
        };
        setLoading(true);
        const res = await saveBillInfo(params);
        setLoading(false);
        if (res && res?.status === 1) {
            message.success("Thêm phiếu chi thành công", 2);
            getDataReport(item?.maphieucan);
        } else {
            message.error(res?.msg ?? "Thêm phiếu chi thất bại");
        }

    };

    return (
        <div className='container-content'>
            <Form form={form} name="advanced_search" style={formStyle} onFinish={onFinish}>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            name={'keyword'}
                            rules={[{ required: true, message: 'Vui lòng nhập số phiếu' }]}
                        >
                            <Input placeholder={'Nhập số phiếu'} />
                        </Form.Item>
                    </Col>

                    <Col span={4}>
                        <Space size="small">
                            <Button type="primary" htmlType="submit">
                                Tìm kiếm
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Table
                dataSource={data}
                columns={columns}
                expandable={{
                    expandedRowRender: expandedRowRender,
                    rowExpandable: (record) => record.name !== 'Not Expandable',
                }}
                pagination={false}
                rowKey={(record) => record?.id}
                className='table-return'
            />
            <Modal
                open={isShowModal}
                title={"Xác nhận trả cọc"}
                onCancel={onCancel}
                width={'30%'}
                footer={null}
                centered
            >
                <div>
                    <span>Bạn đồng ý trả cọc không?</span>
                    <Input placeholder='Nhập ghi chú' onChange={handleChangeNote} style={{marginTop: 10, marginBottom: 10}}/>
                    <div style={{justifyContent: 'end', display: 'flex', marginTop: 20}}>
                        <Space>
                            <Button onClick={onCancel}>Thoát</Button>
                            <Button type="primary" onClick={confirmReturnMoney}>Xác nhận</Button>
                        </Space>
                    </div>
                </div>


            </Modal>
            <LoadingBase loading={loading} />
        </div>
    );
};
export default ReturnMoneyPage;