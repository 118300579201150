import { Button, Checkbox, Col, Form, Input, Modal, Row } from "antd"
import React, { useEffect, useState } from "react";

const PermissionModal = ({ visible, onCancel, onSave, title, data, dataRole, roleDefault }) => {
    const defaultPass = "12346579@@@___"
    const [newPassword, setNewPassword] = useState();
    const [rePassword, setRePassword] = useState();
    const [form] = Form.useForm();
    const [options, setOptions] = useState([]);
    const [checkDefault, setCheckDefault] = useState([]);
    const [isPassCorrect, setIsPassCorrect] = useState(true);
    const handleSave = () => {
        if (!isPassCorrect) {
            return;
        }
        let newData;
        if (newPassword === defaultPass && checkDefault === roleDefault) {
            newData = null;
        }
        newData = {
            password: newPassword !== defaultPass ? newPassword : null,
            roles: checkDefault.join() !== roleDefault?.join() ? checkDefault : null,
            userId: data?.id
        }
        onSave(newData);
    };
    const initialValues = () => {
        form.setFieldsValue({
            username: data?.username ?? '',
            fullname: data?.fullname ?? '',
            phone: data?.phone ?? ''
        })
    }

    const handleOnChangeCheck = (value) => {
        setCheckDefault([...value]);
    }

    const handleOnchangePassword = (value) => {
        setNewPassword(value.target.value);
        if (value.target.value === rePassword) {
            setIsPassCorrect(true);
        } else {
            setIsPassCorrect(false);
        }
    }
    const handleOnchangeRePassword = (value) => {
        setRePassword(value.target.value);
        if (value.target.value === newPassword) {
            setIsPassCorrect(true);
        } else {
            setIsPassCorrect(false);
        }
    }
    useEffect(() => {
        if (dataRole && roleDefault) {
            setOptions([...dataRole]);
            setCheckDefault([...roleDefault]);
        }
    }, [dataRole, roleDefault])

    useEffect(() => {
        if (visible) {
            setNewPassword(defaultPass);
            setRePassword(defaultPass);
        }
    }, [visible])
    useEffect(() => {
        initialValues();
    }, [data])
    return (
        <Modal
            title={title}
            open={visible}
            onCancel={onCancel}
            width={'50%'}
            footer={[
                <Button key="cancel" onClick={onCancel}>
                    Hủy
                </Button>,
                <Button key="save" type="primary" onClick={handleSave}>
                    Lưu
                </Button>,
            ]}
        >
            <Form
                form={form}
                name='edit-user'
                onFinish={handleSave}
                layout={'vertical'}
            >
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item label="Tài khoản" name="username">
                            <Input  disabled/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Họ và tên" name="fullname">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Số điện thoại" name="phone">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24} style={{marginBottom: 20}}>
                        <Checkbox.Group options={options} value={checkDefault} onChange={(value) => handleOnChangeCheck(value)} />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                            <Input.Password value={newPassword}  iconRender={(visible) => {}} onChange={(value) => handleOnchangePassword(value)}/>
                            {
                                !isPassCorrect ? <p style={{color: 'red'}}>Mật khẩu không khớp</p> : null
                            }
                    </Col>
                    <Col span={12}>
                            <Input.Password value={rePassword} iconRender={(visible) => {}} onChange={(value) => handleOnchangeRePassword(value)}/>
                            {
                                !isPassCorrect ? <p style={{color: 'red'}}>Mật khẩu không khớp</p> : null
                            }
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}
export default PermissionModal;