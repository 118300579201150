import { Button, Input, Modal } from "antd"
import React, { useState } from "react";

const AddDataModal = ({ visible, onCancel, onSave, title }) => {
    const [name, setName] = useState('');
    const handleSave = () => {
        onSave(name);
        setName('');
    };

    return (
        <Modal
            title={title}
            open={visible}
            onCancel={onCancel}
            footer={[
                <Button key="cancel" onClick={onCancel}>
                    Hủy
                </Button>,
                <Button key="save" type="primary" onClick={handleSave}>
                    Lưu
                </Button>,
            ]}
        >
            <Input
                placeholder="Nhập tên"
                value={name}
                onChange={(e) => setName(e.target.value)}
            />
        </Modal>
    )
}
export default React.memo(AddDataModal);